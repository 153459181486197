import { Routes, Route } from "react-router-dom";
import SideNav from "./components/shared/sideNav";
import SubNav from "./components/shared/subNav";
import LoginPage from "./pages/loginPage";
import NewAccountPage from "./pages/newAccountPage";
import JobsPage from "./pages/jobsPage";
import RoleListingPage from "./pages/roleListingPage";
import RouteGuard from "./utils/routingUtils/routeGuard";
import { useEffect, useState } from "react";
import PerformancePage from "./pages/performancePage";
import SiteVerifiersUserListing from "./pages/siteVerifiersUsersPage";
import UserManagement from "./pages/userManagementPage";
import HomePage from "./pages/homePage";
import ForgotPasswordPage from "./pages/forgotPasswordPage";
import Layout from "./components/shared/pageLayout";
import { useHomepage } from "./hooks/useHomePage";
import { RoleAccessProvider } from "./context/RoleAccessContext";

function App() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route element={<RouteGuard />}>
        <Route path="/*" element={<DefaultContainer />} />
      </Route>
    </Routes>
  );
}

const DefaultContainer = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { getStatusCount, state } = useHomepage();

  const toggleNav = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    
    getStatusCount();
  }, []);

  const refreshSideNav = () => {
    getStatusCount();
  };

  return (
    <>
      <RoleAccessProvider>
        <SideNav
          isCollapsed={isCollapsed}
          toggleNav={toggleNav}
          state={state}
        />
        <SubNav />

        <Layout isCollapsed={isCollapsed}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            {/* Site Verifiers Module */}
            <Route path="/users-sv" element={<SiteVerifiersUserListing />} />
            <Route
              path="/users-sv/:user-type"
              element={<SiteVerifiersUserListing />}
            />
            <Route path="/users-new-sv" element={<NewAccountPage />} />

            {/* Jobs Module */}
            <Route
              path="/jobs"
              element={<JobsPage refreshSideNav={refreshSideNav} />}
            />
            <Route
              path="/jobs/:job-type"
              element={<JobsPage refreshSideNav={refreshSideNav} />}
            />

            {/* Performance Module */}
            <Route path="/performance" element={<PerformancePage />} />

            {/* Administration Module */}
            <Route path="/administration-user" element={<UserManagement />} />
            <Route path="/administration-role" element={<RoleListingPage />} />

            {/* this route is used as a fallback in the event where the page does not exist */}
            <Route path="/*" element={<HomePage />} />
          </Routes>
        </Layout>
      </RoleAccessProvider>
    </>
  );
};

export default App;
