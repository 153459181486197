import { Modal } from "antd";
import { IModalPopupProps as ModalPopupProps } from "../../types/ModalPopupPropType";

/**
 * A custom modal component that takes in props and renders a modal with the provided title, content, and callback functions for ok and close actions.
 *
 * @param {boolean} open - Determines whether the modal is open or not.
 * @param {() => void} onOk - A callback function that is triggered when the ok button is clicked.
 * @param {() => void} onClose - A callback function that is triggered when the close button is clicked or the modal is closed.
 * @param {string} title - The title of the modal.
 * @param {ReactNode} content - The content to be displayed inside the modal.
 *
 * @returns {JSX.Element} - A JSX element representing the modal component.
 */
const CustomModal = ({
  width,
  open,
  onOk,
  onCancel,
  title,
  children,
}: ModalPopupProps) => {
  return (
    <Modal
      width={width}
      title={title}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
      className="rounded-none"
      maskClosable={true}
      mask={true}
      style={{ resize: "none" }}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
