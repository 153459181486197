import { useEffect, useState } from "react";
import CustomModal from "../modal";
import Button from "../button";
import Textarea from "../textarea";
import {
  GetJobDetailsResponseBody,
  IItem,
  IJobCandidate,
} from "../../types/JobType";
import useJobs from "../../hooks/useJobs";
import { useForm, Controller } from "react-hook-form";
import ModalPopup from "../modalPopUp";

type data = {
  reason: string;
};

const ResubmitJobModal = ({
  item,
  showResubmitJobModal,
  setShowResubmitJobModal,
  setShowSubmitJobModal,
  refreshGrid,
}: {
  item: IItem | undefined;
  showResubmitJobModal: boolean;
  setShowResubmitJobModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSubmitJobModal: React.Dispatch<React.SetStateAction<boolean>>;
  refreshGrid: () => void;
}) => {
  const { getJobsDataById, createResubmittedJobsDataById, state } = useJobs();
  const { control, handleSubmit, formState } = useForm<data>();
  const [jobDetailsData, setJobDetailsData] =
    useState<GetJobDetailsResponseBody>();
  const [showErrorModal, setShowErrorModal] = useState({
    title: "",
    message: "",
  });

  const handleResubmittedJobs = (data: any) => {
    createResubmittedJobsDataById({ id: item?.id!, reason: data.reason })
      .then((res: any) => {})
      .catch((err: any) => {})
      .finally(() => refreshGrid());

    setShowResubmitJobModal(false);
    setShowSubmitJobModal(false);
  };

  useEffect(() => {
    const itemId = item?.id ?? 0;
    if (itemId !== 0 && showResubmitJobModal) {
      getJobsDataById(itemId).then((res: any) => {
        setJobDetailsData(res);
      });
    }
  }, []);

  return (
    <>
      <ModalPopup
        visible={!!showErrorModal.title || !!showErrorModal.message}
        onClose={() => setShowErrorModal({ title: "", message: "" })}
        title={showErrorModal?.title}
        content={<p>{showErrorModal.message}</p>}
      />
      <CustomModal
        width={"60%"}
        title={""}
        open={showResubmitJobModal}
        onOk={() => {}}
        onCancel={() => {
          setShowResubmitJobModal(false);
          setShowSubmitJobModal(true);
        }}
        footer={null}
      >
        <form>
          <div className="border-gray p-4">
            <div>
              <div className="grid grid-cols-2 gap-4 bg-white">
                <div className="col-span-1 border-r-4 border-gray">
                  <div className="block">
                    <label className="text-sm text-slate-300 font-medium">
                      JOB ID: {jobDetailsData?.jobIdStr ?? "NA"}
                    </label>
                  </div>
                  <div className="block">
                    <label className="text-sm text-orange font-medium mr-2">
                      Status:
                    </label>
                    <label className="text-sm text-orange">
                      {jobDetailsData?.status.name}
                    </label>
                  </div>
                  <div className="block">
                    <label className="text-sm text-orange font-medium mr-2">
                      Target Completion Date:
                    </label>
                    <label className="text-sm text-orange">
                      {item?.targetCompletionDate}
                    </label>
                  </div>
                  <div className="block">
                    <label className="text-sm text-orange font-medium mr-2">
                      Estimated Payment:
                    </label>
                    <label className="text-sm text-orange">
                      {jobDetailsData?.estimatedPayment}
                    </label>
                  </div>
                  <div className="block">
                    <label className="text-sm text-orange font-medium mr-2">
                      Other Details:
                    </label>
                    <label className="text-sm text-black">
                      {jobDetailsData?.otherDetails}
                    </label>
                  </div>
                </div>

                <div className="col-span-1 ml-4">
                  <div className="block">
                    <label className="text-sm text-slate-300 font-medium">
                      Details
                    </label>
                  </div>
                  <div className="block">
                    <label className="text-sm text-slate-300 font-medium">
                      {jobDetailsData?.companyName ?? "NA"}
                    </label>
                    <label className="text-sm text-slate-300">
                      {jobDetailsData?.jobAddressInformation?.address1.concat(
                        jobDetailsData?.jobAddressInformation?.address2
                      ) ?? "NA"}
                    </label>
                    <br />
                    <label className="text-sm text-slate-300">
                      {jobDetailsData?.jobAddressInformation?.country}
                    </label>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 row-span-3 gap-x-4 bg-white">
                <div className="col-span-1 row-span-1 border-r-4 border-gray">
                  <div className="block pb-16"></div>
                  <div className="block pb-16"></div>
                </div>

                <div className="col-span-1 row-span-1 ml-4">
                  <div className="block"></div>
                </div>

                <div className="col-span-1 row-span-1 border-r-4 border-gray">
                  <div className="block">
                    <label className="text-sm text-orange font-medium mr-2">
                      Site Verifier
                    </label>
                  </div>
                  <div className="block pb-4">
                    <label className="text-sm text-slate-300 font-medium">
                      {jobDetailsData?.jobCandidates?.find(
                        (val: IJobCandidate) => val.isApprovedByAdmin
                      )?.user?.name ?? "NA"}
                    </label>
                    <div>
                      <label className="text-sm text-slate-300">
                        {jobDetailsData?.jobCandidates?.find(
                          (val: IJobCandidate) => val.isApprovedByAdmin
                        )?.user?.userAddressInformation?.address1 ?? "NA"}
                      </label>
                    </div>
                    <div>
                      <label className="text-sm text-slate-300">
                        {jobDetailsData?.jobAddressInformation?.country ?? "NA"}
                      </label>
                    </div>
                  </div>
                  <div className="block pb-4">
                    <label className="text-sm text-slate-300 font-medium mr-2">
                      Registered:
                    </label>
                    <div>
                      <label className="text-sm text-slate-300">
                        {jobDetailsData?.jobCandidates
                          ?.find((val: IJobCandidate) => val.isApprovedByAdmin)
                          ?.user?.registeredAt?.toString() ?? "NA"}
                      </label>
                    </div>
                  </div>
                  <div className="block pb-4">
                    <label className="text-sm text-slate-300 font-medium mr-2">
                      Expected Visit Date:
                    </label>
                    <div>
                      <label className="text-sm text-slate-300">
                        {jobDetailsData?.proposedAppointmentDate?.toString() ??
                          "NA"}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-span-1 row-span-1 ml-4">
                  <div className="block">
                    <label className="text-sm text-slate-300 font-medium">
                      Reason for Resubmission
                    </label>
                  </div>
                  <div className="block h-40">
                    <Controller
                      name="reason"
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <Textarea
                          onChange={field.onChange}
                          value={field.value ?? ""}
                          required
                          validationMessages={{
                            required: formState.errors?.reason?.message || " ",
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="col-span-1 row-span-1 ml-4"></div>

                <div className="flex justify-end">
                  <div className="basis-3/6 ml-4">
                    <div className="block">
                      <Button
                        children={"Submit"}
                        variant={"primaryorange"}
                        type="button"
                        onClick={handleSubmit(handleResubmittedJobs)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </CustomModal>
    </>
  );
};

export default ResubmitJobModal;
