import { useContext, useEffect, useState } from "react";
import DropdownInput from "../../components/dropdown";
import CustomButton from "../../components/button";
import Pagination from "../../components/pagination";
import DropdownKebab from "../../components/dropdownKebab";
import Textbox from "../../components/textbox";
import useAdministration from "../../hooks/useAdministration";
import { Controller, useForm } from "react-hook-form";
import AdministrationCreateUserModal from "../../components/administrationCreateUserModal";
import Loading from "../../components/loading";
import ModalPopup from "../../components/modalPopUp";
import AdministrationUserModal from "../../components/administrationUserModal";
import { RoleAccessContext } from "../../context/RoleAccessContext";

const UserManagement = () => {
  const { roleAccessObj } = useContext(RoleAccessContext);

  const roleAccess = roleAccessObj.find(
    (x) => x.module.id === 4 && x.page.id === 5
  );

  const [readAccess, setReadAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [updateAccess, setUpdateAccess] = useState(false);

  const [dropdownKebabItem, setDropdownKebabItem] = useState<
    { value: string; label: string }[]
  >([]);

  useEffect(() => {
    if (roleAccess) {
      setReadAccess(roleAccess?.read);
      setDeleteAccess(roleAccess?.delete);
      setUpdateAccess(roleAccess?.update);
    }

    if (roleAccess?.read) {
      setDropdownKebabItem((prev) => [
        ...prev,
        { value: "view", label: "View" },
      ]);
    }
    if (roleAccess?.delete) {
      setDropdownKebabItem((prev) => [
        ...prev,
        { value: "remove", label: "Remove" },
      ]);
    }
  }, [roleAccessObj]);

  const {
    getUserList,
    getSortByDropDownList,
    getRolesDropDownList,
    deleteUserById,
    state,
  } = useAdministration();

  useEffect(() => {
    getSortByDropDownList();
    getRolesDropDownList();
    getUserList({
      pageSize: 10,
      sort: "Descending",
      filterParams: currentGridFilters,
    });
  }, []);

  const { handleSubmit: handleSubmitSearch, control: searchControl } =
    useForm();

  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const handleOpenCreateUserModal = () => {
    setIsCreateUserModalOpen(true);
  };

  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const handleOpenUserModal = () => {
    setIsUserModalOpen(true);
  };

  const [currentGridFilters, setCurrentGridFilters] = useState<any>({});

  const refreshGrid = () => {
    getUserList({ pageSize: 10, pageIndex: 1, sort: "Descending" });
  };

  const onSubmit = (data: any) => {
    if (readAccess) {
      setCurrentGridFilters({
        Email: data?.Email,
        Name: data?.Name,
        RoleId: data?.RoleId,
      });

      getUserList({
        pageSize: 10,
        pageIndex: 1,
        sort: "Descending",
        filterParams: {
          Email: data?.Email,
          Name: data?.Name,
          RoleId: data?.RoleId,
        },
      });
    } else {
      setShowAlertModal({
        title: "Access Denied",
        message: "You do not have read access to this.",
      });
    }
  };

  const handlePageClick = (pageIndex: number) => {
    if (readAccess) {
      getUserList({
        pageSize: 10,
        pageIndex,
        sort: "Descending",
        filterParams: currentGridFilters,
      });
    } else {
      setShowAlertModal({
        title: "Access Denied",
        message: "You do not have read access to this.",
      });
    }
  };

  const [userId, setUserId] = useState<number>(0);

  const handleDropdownChange = async (value: string, userId: any) => {
    if (value === "view" && readAccess) {
      handleOpenUserModal();
      setUserId(userId);
    } else if (value === "remove" && deleteAccess) {
      deleteUserById(userId).then(() => {
        refreshGrid();
        setShowAlertModal({
          title: "Notification",
          message: "User has been removed",
        });
      });
    } else {
      setShowAlertModal({
        title: "Access Denied",
        message: "You do not have read access to this.",
      });
    }
  };

  const [showAlertModal, setShowAlertModal] = useState<{
    title: string;
    message: string;
  }>({
    title: "",
    message: "",
  });

  return (
    <>
      {state.isLoading && <Loading />}

      <ModalPopup
        visible={!!showAlertModal.title || !!showAlertModal.message}
        onClose={() => setShowAlertModal({ title: "", message: "" })}
        title={showAlertModal?.title}
        content={<p>{showAlertModal?.message}</p>}
      />

      {isCreateUserModalOpen && (
        <AdministrationCreateUserModal
          refreshGrid={refreshGrid}
          showModal={isCreateUserModalOpen}
          setShowModal={setIsCreateUserModalOpen}
          setShowAlertModal={setShowAlertModal}
        />
      )}

      {isUserModalOpen && (
        <AdministrationUserModal
          refreshGrid={refreshGrid}
          showModal={isUserModalOpen}
          setShowModal={setIsUserModalOpen}
          setShowAlertModal={setShowAlertModal}
          userId={userId}
          updateAccess={updateAccess}
        />
      )}

      <>
        <div className="p-5 border border-gray rounded-lg bg-white">
          <div className="block px-5 mb-5">
            <label className="mb-0 block font-bold">Administration</label>
          </div>

          {readAccess && (
            <form>
              <div className="grid grid-cols-7 grid-rows-1 gap-5 p-5">
                <div className="block mb-4">
                  <label
                    htmlFor="Area"
                    className="block text-sm font-medium leading-6 text-dark-gray mb-1"
                  >
                    EMAIL
                  </label>
                  <div className="block">
                    <Controller
                      name="Email"
                      control={searchControl}
                      render={({ field }) => (
                        <Textbox
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="block mb-4">
                  <label
                    htmlFor="Area"
                    className="block text-sm font-medium leading-6 text-dark-gray mb-1"
                  >
                    NAME
                  </label>
                  <div className="block">
                    <Controller
                      name="Name"
                      control={searchControl}
                      render={({ field }) => (
                        <Textbox
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="block mb-4">
                  <label
                    htmlFor="Area"
                    className="block text-sm font-medium leading-6 text-dark-gray mb-1"
                  >
                    ROLES
                  </label>
                  <div className="block">
                    <Controller
                      name="RoleId"
                      control={searchControl}
                      render={({ field }) => (
                        <DropdownInput
                          id={"RoleId_User_Management_Dropdown"}
                          value={"name"}
                          selectedOption={field.value}
                          label={"name"}
                          onChange={(selectedValue) => {
                            field.onChange(selectedValue);
                          }}
                          items={state.roles}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="block mt-6">
                  <div className="block">
                    <CustomButton
                      children={"Search"}
                      variant={"primarygreen"}
                      type="button"
                      onClick={handleSubmitSearch(onSubmit)}
                    />
                  </div>
                </div>

                {deleteAccess && (
                  <div className="block mt-6">
                    <div className="block">
                      <CustomButton
                        type="button"
                        children={"Create User"}
                        variant={"primaryorange"}
                        onClick={handleOpenCreateUserModal}
                      />
                    </div>
                  </div>
                )}
              </div>
            </form>
          )}

          <div className="block px-5 mb-10">
            <div className="relative mb-10 min-h-96">
              <table className="w-full text-sm text-left text-black">
                <thead className="text-xs border-b border-b-light-gray-100">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      NAME
                    </th>
                    <th scope="col" className="px-6 py-3">
                      EMAIL
                    </th>
                    <th scope="col" className="px-6 py-3">
                      ROLE
                    </th>
                    <th scope="col" className="px-6 py-3 justify-start"></th>
                  </tr>
                </thead>
                <tbody>
                  {state?.userList?.items?.length > 0 &&
                    state.userList.items.map((item: any) => {
                      return (
                        <tr
                          className="bg-white border-b border-b-light-gray-100"
                          key={item.id}
                        >
                          <td className="px-6 py-41">{item.name}</td>
                          <td scope="row" className="px-6 py-4">
                            {item.email}
                          </td>
                          <td className="px-6 py-4">{item.role}</td>
                          <td className="px-6 py-4">
                            <DropdownKebab
                              onChange={(value) =>
                                handleDropdownChange(value, item.id)
                              }
                              items={dropdownKebabItem}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="flex justify-start">
              <div>
                <Pagination
                  currentPage={state.userList?.pageIndex}
                  totalPages={state.userList?.totalPages}
                  onClickPage={handlePageClick}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default UserManagement;
