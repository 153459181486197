import { Select } from "antd";

interface ICustomDropdownSingleSelect {
  onChange: (value: any) => void;
  options: { label: string; value: any; disabled: boolean }[];
  value: any;
}

const CustomDropdownSingleSelect = ({
  onChange,
  options,
  value,
}: ICustomDropdownSingleSelect) => (
  <>
    <Select
      style={{ width: "100%" }}
      placeholder="Please select"
      onChange={onChange}
      options={options}
      value={value}
      optionFilterProp="label"
    />
  </>
);

export default CustomDropdownSingleSelect;
