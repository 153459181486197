import React, { useState } from "react";

interface DropdownInputProps {
  selectedOption?: any;
  onChange: (value: any) => void;
  items: { value: any; label: any }[];
  value?: any;
  label?: any;
  validationMessage?: any;
  required?: boolean;
  disabled?: boolean;
  validationFields?: string[];
  name?: string;
  id: string;
}

const DropdownInput: React.FC<DropdownInputProps> = ({
  selectedOption,
  onChange,
  items,
  value,
  label,
  validationMessage,
  required,
  disabled,
  name,
  validationFields,
  id,
}) => {
  const [intracted, setIntracted] = useState(false);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    onChange(selectedValue);
  };

  return (
    <>
      <select
        disabled={disabled}
        id={id}
        className="block w-full border border-light-gray-100 p-2 text-dark-gray shadow-sm ring-1 ring-inset ring-gray placeholder:text-gray-400  focus:outline-none sm:text-sm sm:leading-6 disabled:bg-light-gray-100 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
        value={
          selectedOption === false
            ? JSON.stringify(selectedOption)
            : selectedOption
            ? selectedOption
            : ""
        }
        onChange={handleSelectChange}
        onFocus={() => setIntracted(true)}
      >
        <option value="">Please Select</option>

        {Array.isArray(items) &&
          items.length > 0 &&
          items.map((item: any, index) => (
            <option key={index} value={item[value]?.toString()}>
              {item[label]}
            </option>
          ))}
      </select>

      {required &&
        (selectedOption === "" ||
          selectedOption === null ||
          selectedOption === undefined) && (
          <p className="mt-2 text-red text-xs">
            {validationMessage ? validationMessage : "This field is required"}
          </p>
        )}
    </>
  );
};

export default DropdownInput;
