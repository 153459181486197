import clsx from "clsx";
import React, { ReactNode } from "react";

interface IStatusProps {
  children: ReactNode;
  variant: "green" | "yellow" | "red" | "";
}

const CSS_VARIANT_CONSTANTS = {
  GREEN:
    "w-full text-center rounded-md bg-light-green py-1 text-xs font-medium text-light-green-100 ring-1 ring-inset ring-light-green-100",
  YELLOW:
    "w-full text-center rounded-md bg-light-yellow py-1 text-xs font-medium text-light-yellow-200 ring-1 ring-inset ring-light-yellow-100",
  RED: "w-full text-center rounded-md bg-light-red py-1 text-xs font-medium text-light-red-100 ring-1 ring-inset ring-light-red-100",
};

const StatusIndicator = ({ children, variant = "green" }: IStatusProps) => {
  return (
    <div data-testid="status-indicator-div" className="flex justify-stretch">
      <span
        data-testid="status-indicator-span"
        className={clsx(
          variant === "green" ? CSS_VARIANT_CONSTANTS.GREEN : "",
          variant === "yellow" ? CSS_VARIANT_CONSTANTS.YELLOW : "",
          variant === "red" ? CSS_VARIANT_CONSTANTS.RED : "",
          variant === "" ? CSS_VARIANT_CONSTANTS.GREEN : "",
          variant === null ? CSS_VARIANT_CONSTANTS.GREEN : ""
        )}
      >
        {children}
      </span>
    </div>
  );
};

export default StatusIndicator;
