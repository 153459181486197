export const totalSVRegisteredOptionsData = {
  chart: {
    type: "donut",
  },
  dataLabels: {
    enabled: true,
  },
};

export const donutChartColours = [
  "#F76C26", //orange
  "#F6C41F", //yellow
  "#DB6666", //red
  "#0DB886", //green
];

export const totalSVRegisteredDummyData = {
  options: {
    legend: {
      position: "bottom",
    },
    colors: donutChartColours,
  },
  series: [250, 100],
  labels: ["Active Site Verifiers", "Inactive Site Verifiers"],
  responsive: [
    {
      breakpoint: 1000,
      options: {
        plotOptions: {
          donut: {
            horizontal: false,
          },
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
};
