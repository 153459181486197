import React, { useEffect, useState } from "react";
import CustomModal from "../modal";
import Button from "../button";
import {
  GetJobDetailsResponseBody,
  IItem,
  IJobCandidate,
} from "../../types/JobType";
import { useForm } from "react-hook-form";
import useJobs from "../../hooks/useJobs";
import ResubmitJobModal from "../resubmitJobModal";
import "./styles.css";
import StarComponent from "../star";
import ModalPopup from "../modalPopUp";
import {
  MultiFileDownloadWithBuildingNotFoundAttachments,
  MultiFileDownloadWithExcel,
} from "../../utils/fileDownloadUtils/MultiFileDownloadUtil";
import { generateExcelFileBasedOnQuestionaireData } from "../viewJobModal/viewJobModalFunctions";
import Loading from "../loading";
import { removeWhiteSpaceAndReplaceWithDash } from "../../utils/stringUtils/StringUtil";

type DownloadErrorMessage = {
  title: string;
  message: string;
};

const SubmitJobModal = ({
  item,
  showSubmitJobModal,
  setShowSubmitJobModal,
  refreshGrid,
  writeAccess,
}: {
  item: IItem | undefined;
  showSubmitJobModal: boolean;
  setShowSubmitJobModal: React.Dispatch<React.SetStateAction<boolean>>;
  refreshGrid: () => void;
  writeAccess: boolean;
}) => {
  const [jobDetailsData, setJobDetailsData] =
    useState<GetJobDetailsResponseBody>();
  const { control: searchControl, handleSubmit, formState } = useForm();
  const { getJobsDataById, createCompletedJobsDataById, state } = useJobs();
  const [showResubmitJobModal, setShowResubmitJobModal] = useState(false);
  const [starRatingForResponsiveness, setStarRatingForResponsiveness] =
    useState(0);
  const [starRatingForJobQuality, setStarRatingForJobQuality] = useState(0);
  const [starRatingForDelivery, setStarRatingForDelivery] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState({
    title: "",
    message: "",
  });
  const [downloadError, setDownloadError] = useState<DownloadErrorMessage>();
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCompletedJobs = () => {
    if (
      starRatingForResponsiveness === 0 ||
      starRatingForDelivery === 0 ||
      starRatingForJobQuality === 0
    ) {
      setShowValidationModal(true);
      return;
    }
    const completedJobRating = {
      responsiveness: starRatingForResponsiveness,
      quality: starRatingForJobQuality,
      delivery: starRatingForDelivery,
      svId: state?.job?.jobCandidates?.find(
        (val: IJobCandidate) => val.isApprovedByAdmin === true
      ).user.id,
    };
    const itemId = item?.id ?? 0;
    createCompletedJobsDataById({ id: itemId, payload: completedJobRating })
      .then((res: any) => {})
      .catch((err: any) => {
        setShowErrorModal({ title: "Error", message: err });
      });
    refreshGrid();
    setShowSubmitJobModal(false);
  };

  const handleJobResubmission = () => {
    setShowResubmitJobModal(true);
    setShowSubmitJobModal(false);
  };

  useEffect(() => {
    const itemId = item?.id ?? 0;
    if (itemId !== 0 && showSubmitJobModal) {
      getJobsDataById(itemId).then((res: any) => {
        setJobDetailsData(res);
      });
    }
  }, [showSubmitJobModal]);

  return (
    <>
      {isLoading && <Loading />}

      {downloadError && (
        <ModalPopup
          visible={!!downloadError.title || !!downloadError.message}
          onClose={() => setDownloadError({ title: "", message: "" })}
          title={downloadError?.title}
          content={<p>{downloadError.message}</p>}
        />
      )}
      {showResubmitJobModal && (
        <ResubmitJobModal
          item={item}
          showResubmitJobModal={showResubmitJobModal}
          setShowResubmitJobModal={setShowResubmitJobModal}
          setShowSubmitJobModal={setShowSubmitJobModal}
          refreshGrid={refreshGrid}
        />
      )}
      <ModalPopup
        visible={!!showErrorModal.title || !!showErrorModal.message}
        onClose={() => setShowErrorModal({ title: "", message: "" })}
        title={showErrorModal?.title}
        content={<p>{showErrorModal.message}</p>}
      />
      <CustomModal
        width={"60%"}
        title={""}
        open={showSubmitJobModal}
        onOk={() => {}}
        onCancel={() => {
          setShowSubmitJobModal(false);
        }}
        footer={null}
      >
        <form>
          <div className="border-gray p-4">
            <div>
              <div className="grid grid-cols-2 gap-4 bg-white">
                <div className="col-span-1 border-r-4 border-gray">
                  <div className="block">
                    <label className="text-sm text-slate-300 font-medium">
                      JOB ID: {jobDetailsData?.jobIdStr ?? "NA"}
                    </label>
                  </div>
                  <div className="block">
                    <label className="text-sm text-orange font-medium mr-2">
                      Status:
                    </label>
                    <label className="text-sm text-orange">
                      {jobDetailsData?.status.name}
                    </label>
                  </div>
                  {
                    <div className="block">
                      <label className="text-sm text-orange font-medium mr-2">
                        Submitted Date:
                      </label>
                      <label className="text-sm text-orange">
                        {state?.job?.submittedDate}
                      </label>
                    </div>
                  }
                  <div className="block">
                    <label className="text-sm text-orange font-medium mr-2">
                      Estimated Payment:
                    </label>
                    <label className="text-sm text-orange">
                      {state?.job?.estimatedPayment}
                    </label>
                  </div>
                  <div className="block">
                    <label className="text-sm text-orange font-medium mr-2">
                      Other Details:
                    </label>
                    <label className="text-sm text-black">
                      {jobDetailsData?.otherDetails}
                    </label>
                  </div>
                  <div className="block">
                    <label className="text-sm text-orange font-medium mr-2">
                      On-Site Instructions:
                    </label>
                    <label className="text-sm text-orange">
                      {jobDetailsData?.onSiteInstructions}
                    </label>
                  </div>

                  <div>
                    <label>Rate SV's</label>
                    <div className="w-100">
                      <StarComponent
                        labelText="Responsiveness"
                        setStarRating={setStarRatingForResponsiveness}
                      />
                      {showValidationModal && (
                        /* {if not set, then will trigger } */
                        <div className="text-xs text-red">
                          this field is required
                        </div>
                      )}
                    </div>
                    <div className="w-100">
                      <StarComponent
                        labelText="Job Quality"
                        setStarRating={setStarRatingForJobQuality}
                      />
                      {showValidationModal && (
                        /* {if not set, then will trigger } */
                        <div className="text-xs text-red">
                          this field is required
                        </div>
                      )}
                    </div>
                    <div>
                      <StarComponent
                        labelText="Delivery"
                        setStarRating={setStarRatingForDelivery}
                      />
                      {showValidationModal && (
                        /* {if not set, then will trigger } */
                        <div className="text-xs text-red">
                          this field is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-span-1 ml-4">
                  <div className="block">
                    <label className="text-sm text-slate-300 font-medium">
                      Details
                    </label>
                  </div>
                  <div className="block">
                    <label className="text-sm text-slate-300 font-medium">
                      {jobDetailsData?.companyName ?? "NA"}
                    </label>
                    <div className="block">
                      <label className="text-sm text-slate-300">
                        {jobDetailsData?.jobAddressInformation?.address1.concat(
                          jobDetailsData?.jobAddressInformation?.address2
                        ) ?? "NA"}
                      </label>
                      <br />
                      <label className="text-sm text-slate-300">
                        {jobDetailsData?.jobAddressInformation?.country}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4 bg-white">
                <div className="col-span-1 border-r-4 border-gray">
                  <div className="block"></div>
                </div>
                <div className="col-span-1 border-gray ml-4">
                  <div className="block mb-3">
                    <label className="text-sm text-slate-300 font-medium">
                      Downloads
                    </label>
                  </div>
                  <div className="mb-1">
                    <Button
                      children={"Questionaire.xlsx"}
                      variant={"download"}
                      type="button"
                      onClick={() => {
                        generateExcelFileBasedOnQuestionaireData({
                          excelFileName: `questionnaire-${removeWhiteSpaceAndReplaceWithDash(
                            jobDetailsData?.jobIdStr!,
                            jobDetailsData?.id.toString()!
                          )}.xlsx`,
                          questionaireData: jobDetailsData?.questionaire!,
                          observationData: jobDetailsData?.observation!,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-1">
                    <Button
                      children={"Attachments.zip"}
                      variant={"download"}
                      type="button"
                      onClick={() => {
                        MultiFileDownloadWithExcel({
                          zipFileName: `${removeWhiteSpaceAndReplaceWithDash(
                            jobDetailsData?.jobIdStr!,
                            jobDetailsData?.id.toString()!
                          )}-${removeWhiteSpaceAndReplaceWithDash(
                            jobDetailsData?.companyName!,
                            "unknown-company"
                          )}`,
                          setIsLoading: setIsLoading,
                          attachmentNames: jobDetailsData?.buildingAttachment!,
                          setDownloadError: setDownloadError,
                        });
                      }}
                    />
                  </div>
                  {jobDetailsData?.buildingNotFoundQuestionaire !== null && (
                    <div className="mb-1">
                      <Button
                        children={"BuildingNotFound.zip"}
                        variant={"download"}
                        type="button"
                        onClick={() => {
                          MultiFileDownloadWithBuildingNotFoundAttachments({
                            zipFileName: `${removeWhiteSpaceAndReplaceWithDash(
                              jobDetailsData?.jobIdStr!,
                              jobDetailsData?.id.toString()!
                            )}-${removeWhiteSpaceAndReplaceWithDash(
                              jobDetailsData?.companyName!,
                              "unknown-company"
                            )}-buildingNotFound`,
                            setIsLoading: setIsLoading,
                            buildingNotFoundAttachment:
                              jobDetailsData?.buildingNotFoundQuestionaire!,
                            setDownloadError: setDownloadError,
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-2 row-span-3 gap-x-4 bg-white">
                <div className="col-span-1 row-span-1 border-r-4 border-gray"></div>

                <div className="col-span-1 row-span-1 ml-4">
                  <div className="block"></div>
                </div>

                <div className="col-span-1 row-span-1 border-r-4 border-gray">
                  <div className="block">
                    <label className="text-sm text-orange font-medium mr-2">
                      Site Verifier
                    </label>
                  </div>
                  <div className="block pb-4">
                    <label className="text-sm text-slate-300 font-medium">
                      {jobDetailsData?.jobCandidates?.find(
                        (val: IJobCandidate) => val.isApprovedByAdmin
                      )?.user?.name ?? "NA"}
                    </label>
                    <div>
                      <label className="text-sm text-slate-300">
                        {jobDetailsData?.jobCandidates?.find(
                          (val: IJobCandidate) => val.isApprovedByAdmin
                        )?.user?.userAddressInformation?.fullAddress ?? "NA"}
                      </label>
                    </div>
                    <div>
                      <label className="text-sm text-slate-300">
                        {jobDetailsData?.jobAddressInformation?.country ?? "NA"}
                      </label>
                    </div>
                  </div>
                  <div className="block pb-4">
                    <label className="text-sm text-slate-300 font-medium mr-2">
                      Registered:
                    </label>
                    <div>
                      <label className="text-sm text-slate-300">
                        {jobDetailsData?.jobCandidates
                          ?.find((val: IJobCandidate) => val.isApprovedByAdmin)
                          ?.user?.registeredAt?.toString() ?? "NA"}
                      </label>
                    </div>
                  </div>
                  <div className="block pb-4">
                    <label className="text-sm text-slate-300 font-medium mr-2">
                      Expected Visit Date:
                    </label>
                    <div>
                      <label className="text-sm text-slate-300">
                        {jobDetailsData?.proposedAppointmentDate?.toString() ??
                          "NA"}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-span-1 row-span-1 ml-4 bg-white"></div>

                <div className="col-span-1 row-span-1 ml-4"></div>
                <div className="flex justify-end">
                  {writeAccess && (
                    <div className="basis-3/6 ml-4">
                      <div className="block">
                        <Button
                          children={"Job Completed"}
                          variant={"primaryorange"}
                          type="button"
                          onClick={handleSubmit(handleCompletedJobs)}
                        />
                      </div>
                    </div>
                  )}

                  {writeAccess && (
                    <div className="basis-3/6 ml-4">
                      <div className="block">
                        <Button
                          children={"Resubmit"}
                          variant={"outlineborderless"}
                          type="button"
                          onClick={handleSubmit(handleJobResubmission)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </CustomModal>
    </>
  );
};

export default SubmitJobModal;
