import { useEffect } from "react";
import Button from "../../components/button";
import confirmis_logo from "../../assets/confirmis_logo-design-final_ol.svg";
import "./styles.css";
import { Link, useLocation } from "react-router-dom";
import Textbox from "../../components/textbox";
import { useAuth } from "../../hooks/useAuth";
import ModalPopup from "../../components/modalPopUp";
import Swal from "sweetalert2";
import TextboxWithViewButton from "../../components/textboxWithViewButton";
import TextboxWithStyle from "../../components/textboxWithStyle";

const LoginPage = () => {
  const location = useLocation();
  const queryParameters = new URLSearchParams(window.location.search);
  const {
    state,
    dispatch,
    handleLogin,
    showErrorModal,
    setShowErrorModal,
    checkIsLogin,
  } = useAuth();

  useEffect(() => {
    if (
      queryParameters.get("logout") === "true" &&
      location.pathname.includes("login")
    ) {
      Swal.fire({
        icon: "success",
        title: "You have been logged out",
        showConfirmButton: false,
        timer: 2000,
        background: "#ECECEC",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonColor: "#F15757",
        cancelButtonText: "<p>Close</p>",
      });
    }
    checkIsLogin();
  }, []);

  return (
    <>
      <ModalPopup
        visible={!!showErrorModal.title || !!showErrorModal.message}
        onClose={() => setShowErrorModal({ title: "", message: "" })}
        title={showErrorModal?.title}
        content={<p>{showErrorModal.message}</p>}
      />

      <form onSubmit={handleLogin}>
        <div className="login-wrapper flex items-center justify-center h-screen">
          <div className="flex flex-col bg-white w-1/5 text-black">
            <div className="w-full flex justify-center py-4">
              <div className="">
                <div className="image-wrapper">
                  <img src={confirmis_logo} alt="Confirmis Logo" />
                </div>
              </div>
            </div>
            <div className="basis-2/5 py-8 text-black border-t-2 border-grey-900 text-left">
              <div className="login-details-wrapper">
                <div className="flex flex-col align-start pb-3 ">
                  <span className="text-3xl">ADMIN LOGIN</span>
                </div>
                <div className="flex flex-start pb-4">
                  <div className="flex flex-col gap-3 w-full">
                    <div>
                      <div className="text-black pb-1">
                        <label htmlFor="email">
                          <span>Email Address</span>
                        </label>
                      </div>
                      <div>
                        <TextboxWithStyle
                          name={"email"}
                          placeholder={"Input Email"}
                          value={state.email}
                          onChange={(email) => {
                            dispatch({ type: "SET_EMAIL", payload: email });
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="text-black pb-1">
                        <label htmlFor="password">
                          <span>Password</span>
                        </label>
                      </div>
                      <div>
                        <TextboxWithViewButton
                          name={"password"}
                          placeholder={"Input Password"}
                          value={state.password}
                          onChange={(password) => {
                            dispatch({
                              type: "SET_PASSWORD",
                              payload: password,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button-container flex justify-center">
                  <Button variant="login" type="submit">
                    <span className="text-slate-50">Submit</span>
                  </Button>
                </div>
                <div className="pt-5">
                  <Link to="/forgot-password">
                    <Button variant="underlineborderless">
                      Forgot password?
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default LoginPage;
