import DropdownInput from "../../components/dropdown";
import CustomButton from "../../components/button";
import Pagination from "../../components/pagination";
import Textbox from "../../components/textbox";
import { Controller, useForm } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import useSiteVerifiers from "../../hooks/useSiteVerifiers";
import Loading from "../../components/loading";
import account_circle from "../../assets/account_circle.svg";
import DropdownKebab from "../../components/dropdownKebab";
import SiteVerifiersModal from "../../components/siteVerifiersUsersModal";
import ModalPopup from "../../components/modalPopUp";
import { useLocation } from "react-router-dom";
import {
  changeUIDisplayByURL,
  createPropsForRetrievingSVs,
} from "./siteVerifiersUsersFeatures/siteVerifiersUsersService";
import dayjs from "dayjs";
import ConfirmationPopupModal from "../../components/confirmationPopupModal";
import { RoleAccessContext } from "../../context/RoleAccessContext";

const SiteVerifiersUserListing = () => {
  const { roleAccessObj } = useContext(RoleAccessContext);

  const roleAccess = roleAccessObj.find(
    (x) => x.module.id === 1 && x.page.id === 1
  );

  const [readAccess, setReadAccess] = useState(false);
  const [writeAccess, setWriteAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [updateAccess, setUpdateAccess] = useState(false);

  const [dropdownKebabItem, setDropdownKebabItem] = useState<
    { value: string; label: string }[]
  >([]);

  useEffect(() => {
    if (roleAccess) {
      setReadAccess(roleAccess?.read);
      setWriteAccess(roleAccess?.write);
      setDeleteAccess(roleAccess?.delete);
      setUpdateAccess(roleAccess?.update);
    }

    if (roleAccess?.read) {
      setDropdownKebabItem((prev) => [
        ...prev,
        { value: "view", label: "View" },
      ]);
    }
    if (roleAccess?.delete) {
      setDropdownKebabItem((prev) => [
        ...prev,
        { value: "remove", label: "Remove" },
      ]);
    }
  }, [roleAccessObj]);

  const location = useLocation();

  const {
    getSiteVerifiersList,
    getCountriesDropDownList,
    getSVSortByDropDownList,
    getSVFilterDropDownList,
    removeUser,
    state,
  } = useSiteVerifiers();

  const [showAlertModal, setShowAlertModal] = useState<{
    title: string;
    message: string;
  }>({
    title: "",
    message: "",
  });

  const {
    handleSubmit: handleSubmitSearch,
    control: searchControl,
    setValue,
  } = useForm();

  const [currentGridFilters, setCurrentGridFilters] = useState<any>({});
  const [accountId, setAccountId] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [locationUrl, setLocationUrl] = useState("");
  const [showRemoveUserWarning, setShowRemoveUserWarning] = useState(false);
  const [userToBeRemoved, setUserToBeRemoved] = useState(0);
  const [SVFilterDropdownList, setSVFilterDropdownList] = useState<any>();
  const [paginationDetails, setPaginationDetails] = useState<any>();
  const [SVListingData, setSVListingData] = useState<any>([]);

  const refreshGrid = () => {
    getSiteVerifiersList({
      pageSize: 10,
      pageIndex: 1,
      sort: "Descending",
      ...currentGridFilters,
    }).then((res: any) => {
      setPaginationDetails(res);
      const items = res?.items;
      setSVListingData(items);
    });
  };

  const handlePageClick = (pageIndex: number) => {
    if (readAccess) {
      getSiteVerifiersList({
        ...currentGridFilters,
        pageIndex: pageIndex,
      }).then((res: any) => {
        setPaginationDetails(res);
        const items = res?.items;
        setSVListingData(items);
        setCurrentGridFilters({
          ...currentGridFilters,
          pageIndex: pageIndex,
        });
      });
    } else {
      setShowAlertModal({
        title: "Access Denied",
        message: "You do not have read access to this.",
      });
    }
  };

  const onSubmit = (data: any) => {
    if (readAccess) {
      setCurrentGridFilters({
        ...currentGridFilters,
        Filter: data?.Filter,
        Country: data?.Country,
        Province: data?.Province,
        SortBy: data?.SortBy,
      });

      getSiteVerifiersList({
        pageSize: 7,
        pageIndex: 1,
        sort: "Descending",
        Filter: data?.Filter,
        Country: data?.Country,
        Province: data?.Province,
        SortBy: data?.SortBy,
      }).then((res: any) => {
        setPaginationDetails(res);
        const items = res?.items;
        setSVListingData(items);
      });
    }
  };

  const handleDropdownChange = async (value: string, itemId: number) => {
    if (value === "view" && readAccess) {
      setIsModalOpen(true);
      setAccountId(itemId);
    } else if (value === "remove" && deleteAccess) {
      setShowRemoveUserWarning(true);
      setUserToBeRemoved(itemId);
    } else {
      setShowAlertModal({
        title: "Access Denied",
        message: "You do not have read access to this.",
      });
    }
  };

  const handleRemoveUser = () => {
    removeUser(userToBeRemoved).then((res: any) => {
      if (res?.statusCode === 200) {
        refreshGrid();
        setShowAlertModal({
          title: "Notification",
          message: "User has been removed",
        });
        setShowRemoveUserWarning(false);
      } else {
        setShowAlertModal({
          title: "Error",
          message:
            "Somewthing went wrong and the user cannot be removed. Please try again later.",
        });
        setShowRemoveUserWarning(false);
      }
    });
  };

  const getData = async (svStatus: any) => {
    let svType = createPropsForRetrievingSVs({
      svPagePathname: location.pathname,
      svPageStatusList: svStatus,
    })
      .retrieveSVStatusForURL()
      .returnSVStatus();

    getSiteVerifiersList({
      pageSize: 7,
      filter: svType,
    }).then((res: any) => {
      setPaginationDetails(res);
      const items = res?.items;
      setSVListingData(items);
    });

    setCurrentGridFilters({
      pageSize: 7,
      pageIndex: 1,
      sort: "Descending",
      Filter: svType,
    });
  };

  useEffect(() => {
    setLocationUrl(location.pathname);
    getSVFilterDropDownList().then((svFilter: any) => {
      setSVFilterDropdownList(svFilter);
      getData(svFilter);
    });

    changeUIDisplayByURL({
      svPagePathname: location.pathname,
      setValueOfFilter: setValue,
    })
      .findSVPageType()
      .setValueOfSVStatus();

    getSVSortByDropDownList();
    getCountriesDropDownList();
  }, [location]);

  return (
    <>
      {state.isLoading && <Loading />}

      <ModalPopup
        visible={!!showAlertModal.title || !!showAlertModal.message}
        onClose={() => setShowAlertModal({ title: "", message: "" })}
        title={showAlertModal?.title}
        content={<p>{showAlertModal.message}</p>}
      />

      <ConfirmationPopupModal
        showModal={showRemoveUserWarning}
        setShowModal={setShowRemoveUserWarning}
        displayHeader={"Remove the User?"}
        displayText1={
          "This action cannot be undone and may result in loss of data."
        }
        displayText2={"Confirm removal?"}
        handleAction={handleRemoveUser}
      />

      <SiteVerifiersModal
        refreshGrid={refreshGrid}
        showModal={isModalOpen}
        setShowModal={setIsModalOpen}
        userId={accountId}
        setShowAlertModal={setShowAlertModal}
        suspendAccess={writeAccess}
      />

      <>
        <div className="p-5 border border-gray rounded-lg bg-white h-auto">
          <div className="block px-5 mb-5">
            <span className="text-xl font-bold">Site Verifiers</span>
          </div>
          {readAccess && (
            <form onSubmit={handleSubmitSearch(onSubmit)}>
              <div className="grid grid-cols-7 grid-rows-1 gap-5 p-5">
                <div className="block mb-4">
                  <label
                    className="block text-sm font-medium leading-6 text-dark-gray mb-1"
                    htmlFor=""
                  >
                    FILTER
                  </label>
                  <Controller
                    name="Filter"
                    control={searchControl}
                    render={({ field }) => {
                      return (
                        <DropdownInput
                          id={"Filter_SV_Users_Dropdown"}
                          value={"name"}
                          selectedOption={field.value}
                          label={"name"}
                          onChange={(selectedValue) => {
                            field.onChange(selectedValue);
                          }}
                          items={SVFilterDropdownList}
                        />
                      );
                    }}
                  />
                </div>
                <div className="block mb-4">
                  <label
                    className="block text-sm font-medium leading-6 text-dark-gray mb-1"
                    htmlFor=""
                  >
                    COUNTRY
                  </label>
                  <Controller
                    name="Country"
                    control={searchControl}
                    render={({ field }) => {
                      return (
                        <DropdownInput
                          id={"Country_SV_Users_Dropdown"}
                          value={"name"}
                          selectedOption={field.value}
                          label={"name"}
                          onChange={(selectedValue) => {
                            field.onChange(selectedValue);
                          }}
                          items={state.countries}
                        />
                      );
                    }}
                  />
                </div>
                <div className="block mb-4">
                  <label
                    className="block text-sm font-medium leading-6 text-dark-gray mb-1"
                    htmlFor=""
                  >
                    STATE
                  </label>
                  <Controller
                    name="Province"
                    control={searchControl}
                    defaultValue=""
                    render={({ field }) => (
                      <Textbox value={field.value} onChange={field.onChange} />
                    )}
                  />
                </div>
                <div className="block mb-4">
                  <label
                    className="block text-sm font-medium leading-6 text-dark-gray mb-1"
                    htmlFor=""
                  >
                    SORT BY
                  </label>
                  <Controller
                    name="SortBy"
                    control={searchControl}
                    defaultValue=""
                    render={({ field }) => (
                      <DropdownInput
                        id={"SortBy_SV_Users_Dropdown"}
                        value={"name"}
                        selectedOption={field.value}
                        label={"name"}
                        onChange={(selectedValue) => {
                          field.onChange(selectedValue);
                        }}
                        items={state.svSortBy}
                      />
                    )}
                  />
                </div>

                <div className="block mt-6">
                  <div className="block">
                    <CustomButton
                      children={"Search"}
                      variant={"primarygreen"}
                      type="submit"
                    />
                  </div>
                </div>
              </div>
            </form>
          )}

          <div className="block px-5 mb-3 h-4/6">
            <div className="relative mb-10">
              <table className="w-full text-sm text-left text-black h-5/6">
                <thead className="text-xs border-b border-b-light-gray-100">
                  <tr>
                    <th scope="col" className="px-6 py-3 w-32"></th>
                    <th scope="col" className="px-6 py-3">
                      NAME
                    </th>
                    <th scope="col" className="px-6 py-3">
                      RATING
                    </th>
                    <th scope="col" className="px-6 py-3">
                      COUNTRY
                    </th>
                    <th scope="col" className="px-6 py-3">
                      LAST LOGIN
                    </th>

                    <th scope="col" className="px-6 py-3 justify-start"></th>
                  </tr>
                </thead>
                <tbody>
                  {state?.svList?.items?.length > 0 &&
                    state.svList.items.map((item: any) => {
                      return (
                        <tr
                          key={item.id}
                          className="bg-white border-b border-b-light-gray-100"
                        >
                          <td
                            key={item?.profilePictureUrl + "0"}
                            className="px-6 py-4"
                          >
                            <img
                              src={item?.profilePictureUrl ?? account_circle}
                              alt="profile picture of the user"
                              className="size-8 rounded-full bg-light-gray-100"
                            />
                          </td>
                          <td key={item?.name + "1"} className="px-6 py-4">
                            {item?.name || "N/A"}
                          </td>
                          <td key={item?.rating + "2"} className="px-6 py-4">
                            {item?.rating || "N/A"}
                          </td>
                          <td key={item?.country + "3"} className="px-6 py-4">
                            {item?.country || "N/A"}
                          </td>
                          <td key={item?.lastLogin + "4"} className="px-6 py-4">
                            {dayjs(item?.lastLogin)?.toString() || "N/A"}
                          </td>
                          <td key={item?.name + "5"} className="px-6 py-4">
                            <DropdownKebab
                              onChange={(value) =>
                                handleDropdownChange(value, item.id)
                              }
                              items={dropdownKebabItem}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex justify-start h-8">
            <div>
              <Pagination
                currentPage={paginationDetails?.pageIndex}
                totalPages={paginationDetails?.totalPages}
                onClickPage={handlePageClick}
              />
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default SiteVerifiersUserListing;
