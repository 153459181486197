import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { SubMenuData } from "../../../data/SubNavMenuData";
import "./styles.css";
import { IMenuItem } from "../../../types/MenuItemTypes";
import { useGlobalStates } from "../../../hooks/useGlobalStates";
import Cookies from "js-cookie";
import { AxiosClientUtil } from "../../../utils/httpUtils/AxiosClientUtil";

// TODO: routing for modules

/**
 * A universal subnavigation component for the app
 * @param pageString: used to indicate which page the menu is on
 * @returns a universal subnav component with the following elements:
 * 1. home
 * 2. Notifications
 * 3. Logout
 * 4. Profile
 * 5. Settings
 */

const SubNav = () => {
  const router = useNavigate();

  const { setCurrentUserValues } = useGlobalStates();

  const handleLogout = async () => {
    try {
      await AxiosClientUtil.post("/Auth/logout");

      Cookies.remove("jwtToken");
      setCurrentUserValues({
        expiration: "",
        jwtToken: "",
        username: "",
        userId: "",
      });
      router("/login?logout=true");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <>
      <div className="py-5 pl-3 shadow border-b-gray bg-white flex text-black justify-end items-center top-0 sticky z-30">
        <ul className="flex space-x-5 justify-end mx-10">
          {SubMenuData.map((subMenu: IMenuItem, index: number) => {
            return (
              <li key={index} className="flex items-center">
                <Link to={subMenu.link}>
                  <button onClick={() => null}>
                    {subMenu.icon !== "" ? (
                      <img
                        src={subMenu.icon}
                        className="size-10"
                        alt={subMenu.name}
                      />
                    ) : (
                      <span
                        className="sub-nav-element-text w-36 text-lg font-semibold"
                        onClick={handleLogout}
                      >
                        {subMenu.text}
                      </span>
                    )}
                  </button>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default SubNav;
