import { create } from "zustand";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

interface currentUserValue {
  expiration: string;
  jwtToken: string;
  username: string;
  userId: string;
}

interface globalStates {
  currentUserValues: currentUserValue | null;
  setCurrentUserValues: (user: currentUserValue) => void;
}

const useInitialize = () => {
  const jwtToken = Cookies.get("jwtToken") as string;

  if (!jwtToken) return null;

  const decodeCookie: any = jwtDecode(jwtToken);
  const expiration: any = decodeCookie?.exp * 1000;
  const username =
    decodeCookie[
      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
    ] || "Guest";
  const userId =
    decodeCookie[
      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
    ];
  return {
    expiration,
    jwtToken,
    username,
    userId,
  };
};

export const useGlobalStates = create<globalStates>((set) => ({
  currentUserValues: useInitialize(),
  setCurrentUserValues: (user: currentUserValue) => {
    set({ currentUserValues: user });
  },
}));
