import JSZip from "jszip";
import {
  replaceWordsWithRegularExpression,
  splitStringByDelimiter,
} from "../stringUtils/StringUtil";
import {
  createPromiseForZipItem,
  generateZipFileAsync,
  saveZipFile,
} from "./ZipDownloadUtil";
import { ExcelFileGeneratorUtilForZip } from "../excelUtils/ExcelFileGeneratorUtil";
import justificationDataGenerator from "../excelUtils/dataGenerator/justificationDataGenerator";
import {
  IBuildingAttachment,
  IBuildingNotFoundQuestionnaire,
} from "../../types/JobType";
import { isStringAValidFileType } from "./FileNameDownloadUtils";
import buildingNotFoundDataGenerator from "../excelUtils/dataGenerator/buildingNotFoundDataGenerator";

/**
 * Error message type for download errors.
 */
type DownloadErrorMessage = {
  title: string;
  message: string;
};

/**
 * A callback function that is passed from the parent page to the Multi Download Function which sets the Download Error Message state
 * @callback downloadErrorCallback
 * @param {DownloadErrorMessage | Undefined}
 */

/**
 * A utility function to download multiple files as a zip archive.
 *
 * @param {string} zipFileName: Nullable - The name of the zip file to be created. Default is "attachments.zip".
 * @param {any} attachmentNames: generic data representing the files to be downloaded.
 * @param {downloadErrorCallback} setDownloadError: A callback function to set the download error state for the parent page to consume
 * @returns {void} - the function generates and saves the zip file automatically
 */
const MultiFileDownloadUtil = async ({
  zip,
  zipFileName = "attachments.zip",
  attachmentNames,
  setDownloadError,
  setIsLoading,
}: {
  zip: JSZip;
  zipFileName?: string;
  attachmentNames: Object;
  setDownloadError: React.Dispatch<
    React.SetStateAction<DownloadErrorMessage | undefined>
  >;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  setIsLoading(true);
  const filteredAttachmentObject = Object.entries(attachmentNames).filter(
    ([, value]) => value !== null && typeof value !== "object"
  );

  try {
    const promises: any = [];
    filteredAttachmentObject?.forEach(([key, value]) => {
      // one attachment value might have multiple urls appended with a comma
      const urlArray: string[] = splitStringByDelimiter(value, ",");
      const filteredUrls = splitNameOfFileFromURL({
        attachmentNames: urlArray,
      });

      for (let x = 0; x < urlArray.length; x++) {
        if (isStringAValidFileType(filteredUrls[x])) {
          promises.push(
            createPromiseForZipItem(
              zip,
              urlArray[x],
              `${replaceWordsWithRegularExpression(key, "url", "")}_${
                filteredUrls[x]
              }`
            )
          );
        }
      }
    });
    await Promise.all(promises);
    const content = await generateZipFileAsync(zip);
    saveZipFile(content, zipFileName);
  } catch (err) {
    setDownloadError({
      title: "Error",
      message: "Error while downloading the files",
    });
  } finally {
    setIsLoading(false);
  }
};
/**
 * Takes an array of strings and splits the file name from a URL.
 * Obtains the final part of the string through the ("/")
 *
 * @param attachmentNames - An array of strings containing the URLs.
 * @returns An array of string file names extracted from the URLs.
 */
const splitNameOfFileFromURL = ({
  attachmentNames,
}: {
  attachmentNames: string[];
}) => {
  let nameFromURL: string[] = [];
  for (let i = 0; i < attachmentNames.length; i++) {
    const parts = attachmentNames[i]?.split("/");
    nameFromURL.push(parts.pop()!);
  }
  return nameFromURL;
};

export const MultiFileDownloadWithBuildingNotFoundAttachments = async ({
  zipFileName = "attachments",
  buildingNotFoundAttachment,
  setDownloadError,
  setIsLoading,
}: {
  zipFileName?: string;
  buildingNotFoundAttachment: IBuildingNotFoundQuestionnaire;
  setDownloadError: React.Dispatch<
    React.SetStateAction<DownloadErrorMessage | undefined>
  >;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const zip = new JSZip();

  //should ideally refactor this in the future as a strategy
  const formattedBuildingNotFoundData = buildingNotFoundDataGenerator({
    buildingNotFoundData: buildingNotFoundAttachment,
  });

  ExcelFileGeneratorUtilForZip({
    zip: zip,
    filename: `${zipFileName}.xlsx`,
    formattedDataForExcelFile: formattedBuildingNotFoundData,
  });

  MultiFileDownloadUtil({
    zip: zip,
    zipFileName: `${zipFileName}.zip`,
    setIsLoading: setIsLoading,
    attachmentNames: {
      horizontal360VideoOfTheBuildingUrl:
        buildingNotFoundAttachment?.horizontal360VideoOfTheBuildingUrl,
      surroundingEnvironmentUrl:
        buildingNotFoundAttachment?.surroundingEnvironmentUrl,
    },
    setDownloadError: setDownloadError,
  });
};

export const MultiFileDownloadWithExcel = async ({
  zipFileName = "attachments",
  attachmentNames,
  setDownloadError,
  setIsLoading,
}: {
  zipFileName?: string;
  attachmentNames: IBuildingAttachment;
  setDownloadError: React.Dispatch<
    React.SetStateAction<DownloadErrorMessage | undefined>
  >;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const zip = new JSZip();

  //should ideally refactor this in the future as a strategy
  const formattedJustificationData = justificationDataGenerator({
    justificationData: attachmentNames?.justification,
  });

  ExcelFileGeneratorUtilForZip({
    zip: zip,
    filename: `${zipFileName}.xlsx`,
    formattedDataForExcelFile: formattedJustificationData,
  });

  MultiFileDownloadUtil({
    zip: zip,
    zipFileName: `${zipFileName}.zip`,
    setIsLoading: setIsLoading,
    attachmentNames: attachmentNames,
    setDownloadError: setDownloadError,
  });
};

export const MultiFileDownloadUtilAttachments = ({
  zipFileName = "attachments",
  attachmentNames,
  setDownloadError,
  setIsLoading,
}: {
  zipFileName?: string;
  attachmentNames: string;
  setDownloadError: React.Dispatch<
    React.SetStateAction<DownloadErrorMessage | undefined>
  >;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const zip = new JSZip();
  MultiFileDownloadUtilBase({
    zip: zip,
    zipFileName: `${zipFileName}`,
    setIsLoading: setIsLoading,
    attachmentNames: attachmentNames,
    setDownloadError: setDownloadError,
  });
};

const MultiFileDownloadUtilBase = async ({
  zip,
  zipFileName = "attachments",
  attachmentNames,
  setDownloadError,
  setIsLoading,
}: {
  zip: JSZip;
  zipFileName?: string;
  attachmentNames: string;
  setDownloadError: React.Dispatch<
    React.SetStateAction<DownloadErrorMessage | undefined>
  >;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  setIsLoading(true);
  const attachmentArray = [attachmentNames?.toString()];
  const filteredUrls = splitNameOfFileFromURL({
    attachmentNames: attachmentArray,
  });
  try {
    const promises: any = [];
    if (isStringAValidFileType(filteredUrls[0])) {
      promises.push(
        createPromiseForZipItem(
          zip,
          attachmentNames,
          `${zipFileName}-${filteredUrls[0]}`
        )
      );
    }

    await Promise.all(promises);
    const content = await generateZipFileAsync(zip);
    saveZipFile(content, `${zipFileName}.zip`);
  } catch (err) {
    setDownloadError({
      title: "Error",
      message: "Error while downloading the files",
    });
  } finally {
    setIsLoading(false);
  }
};
