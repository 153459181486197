import { DatePicker, DatePickerProps, Space } from "antd";
import React from "react";

interface Props {
  value?: DatePickerProps["value"] | any;
  onChange?: DatePickerProps["onChange"] | any;
}

const { RangePicker } = DatePicker;

const DateRange: React.FC<Props> = ({ value, onChange }) => {
  const handleDateRangeChange: any = (dates: any, dateStrings: any) => {
    if (dates !== null && onChange) {
      onChange(dates);
    }
  };

  return (
    <Space direction="vertical" className="w-full">
      <RangePicker
        onChange={handleDateRangeChange}
        value={value}
        className="w-full rounded border-0 p-2 text-dark-gray shadow-sm ring-1 ring-inset ring-gray placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-5"
      />
    </Space>
  );
};

export default DateRange;
