import { useCallback, useReducer } from "react";
import { AxiosClientUtil } from "../utils/httpUtils/AxiosClientUtil";

const initialState = {
  isLoading: false,
  svList: [],
  newSvList: [],
  countries: [],
  svSortBy: [],
  svFilter: [],
  svListByCountry: [],
  svId: "",
};

const reducer = (state: any, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, isLoading: action.payload };
    case "SET_SV_ID":
      return { ...state, svId: action.payload };
    case "GET_SV_LIST":
      return { ...state, svList: action.payload };
    case "GET_SV_LIST_BY_COUNTRY":
      return { ...state, svListByCountry: action.payload };
    case "GET_NEW_SV_LIST":
      return { ...state, newSvList: action.payload };
    case "SET_COUNTRIES":
      return { ...state, countries: action.payload };
    case "SET_SV_SORT_BY":
      return { ...state, svSortBy: action.payload };
    case "SET_SV_FILTER":
      return { ...state, svFilter: action.payload };
  }
};

const useSiteVerifiers = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const memoizedDispatch = useCallback(dispatch, [dispatch]);

  const memoizedJobActions = {
    getSiteVerifiersList: useCallback(
      async (payload: any) => {
        memoizedDispatch({ type: "SET_LOADING", payload: true });
        try {
          const response = await AxiosClientUtil.get("/User/sv-list", {
            params: payload,
          });
          memoizedDispatch({ type: "GET_SV_LIST", payload: response?.data });
          return response?.data;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    getSiteVerifiersListByCountry: useCallback(
      async (payload: any) => {
        memoizedDispatch({ type: "SET_LOADING", payload: true });
        try {
          const response = await AxiosClientUtil.get(
            "/User/sv-list-by-country",
            {
              params: payload,
            }
          );
          memoizedDispatch({
            type: "GET_SV_LIST_BY_COUNTRY",
            payload: response?.data,
          });
          return response;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    getNewSiteVerifiersList: useCallback(
      async ({ pageSize, pageIndex, sort, filterParams }: any) => {
        const filters = {
          Country: filterParams?.Country,
          Province: filterParams?.Province,
          SortBy: filterParams?.SortBy,
        };
        const populateFilters = Object.fromEntries(
          Object.entries(filters).filter(([_, value]) => value !== "")
        );
        memoizedDispatch({ type: "SET_LOADING", payload: true });
        try {
          const response = await AxiosClientUtil.get("/User/sv-list-new", {
            params: {
              PageSize: pageSize,
              PageIndex: pageIndex,
              Sort: sort,
              ...populateFilters,
            },
          });
          memoizedDispatch({
            type: "GET_NEW_SV_LIST",
            payload: response?.data,
          });
          return response?.data;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    getCountriesDropDownList: useCallback(async () => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await AxiosClientUtil.get(`/DropdownList/countries`);
          memoizedDispatch({
            type: "SET_COUNTRIES",
            payload: response?.data,
          });
          resolve(response?.data);
        } catch (err) {
          reject(err);
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      });
    }, [memoizedDispatch]),

    getSVSortByDropDownList: useCallback(async () => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await AxiosClientUtil.get(`/DropdownList/sv-sortby`);
          memoizedDispatch({
            type: "SET_SV_SORT_BY",
            payload: response?.data,
          });
          resolve(response?.data);
        } catch (err) {
          reject(err);
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      });
    }, [memoizedDispatch]),

    getSVFilterDropDownList: useCallback(async () => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await AxiosClientUtil.get(`/DropdownList/sv-filter`);
          memoizedDispatch({
            type: "SET_SV_FILTER",
            payload: response?.data,
          });
          resolve(response?.data);
        } catch (err) {
          reject(err);
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      });
    }, [memoizedDispatch]),

    removeUser: useCallback(
      async (id: any) => {
        memoizedDispatch({ type: "SET_LOADING", payload: true });
        try {
          const response = await AxiosClientUtil.delete(`/User/${id}`);
          return { data: response?.data, status: response?.status };
        } catch (error) {
          throw error;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    getSiteVerifierUserById: useCallback(
      async (id: number) => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await AxiosClientUtil.get(`/User/${id}`);
            resolve(response?.data);
          } catch (err) {
            reject(err);
          } finally {
            memoizedDispatch({ type: "SET_LOADING", payload: false });
          }
        });
      },
      [memoizedDispatch]
    ),

    suspendUser: useCallback(
      async (id: number, reasonForSuspension: any) => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await AxiosClientUtil.post(`/User/${id}/suspend`, {
              reasonForSuspension: reasonForSuspension,
            });
            resolve(response);
          } catch (err) {
            reject(err);
          } finally {
            memoizedDispatch({ type: "SET_LOADING", payload: false });
          }
        });
      },
      [memoizedDispatch]
    ),

    unSuspendUser: useCallback(
      async (id: number) => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await AxiosClientUtil.post(
              `/User/${id}/unsuspend`
            );
            resolve(response?.data);
          } catch (err) {
            reject(err);
          } finally {
            memoizedDispatch({ type: "SET_LOADING", payload: false });
          }
        });
      },
      [memoizedDispatch]
    ),

    approveUser: useCallback(
      async (id: number) => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await AxiosClientUtil.post(`/User/${id}/approve`);
            resolve(response?.data);
          } catch (err) {
            reject(err);
          } finally {
            memoizedDispatch({ type: "SET_LOADING", payload: false });
          }
        });
      },
      [memoizedDispatch]
    ),
  };

  return { state, dispatch, ...memoizedJobActions };
};

export default useSiteVerifiers;
