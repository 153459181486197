import React, { Dispatch, useEffect, useState } from "react";
import CustomModal from "../modal";
import Button from "../button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Textbox from "../textbox";
import Checkbox from "../checkbox";
import useSiteVerifiers from "../../hooks/useSiteVerifiers";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "antd";
import { MultiFileDownloadUtilAttachments } from "../../utils/fileDownloadUtils/MultiFileDownloadUtil";
import ModalPopup from "../modalPopUp";
import { IUserDataType } from "../../types/SVUserTypes";
import dayjs from "dayjs";
import Loading from "../loading";
import SiteVerifiersUsersModalFunctions from "./siteVerifiersUsersModalFunctions";
import { removeWhiteSpaceAndReplaceWithDash } from "../../utils/stringUtils/StringUtil";

interface SiteVerifiersModalProps {
  suspendAccess: any;
  showModal: boolean;
  setShowModal: Dispatch<React.SetStateAction<boolean>>;
  userId: number;
  refreshGrid: () => void;
  setShowAlertModal: ({
    title,
    message,
  }: {
    title: string;
    message: string;
  }) => void;
}

type DownloadErrorMessage = {
  title: string;
  message: string;
};

const SiteVerifiersModal: React.FC<SiteVerifiersModalProps> = ({
  showModal,
  setShowModal,
  userId,
  refreshGrid,
  setShowAlertModal,
  suspendAccess,
}) => {
  const { getSiteVerifierUserById, suspendUser, unSuspendUser } =
    useSiteVerifiers();

  const [userData, setUserData] = useState<IUserDataType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isSuspend, setIsSuspend] = useState(false);
  const [downloadError, setDownloadError] = useState<DownloadErrorMessage>();
  const { control, handleSubmit, setValue, reset } = useForm<IUserDataType>();

  useEffect(() => {
    if (userId !== 0 && showModal) {
      getSiteVerifierUserById(userId).then((user: any) => {
        const parsedUserData = user as IUserDataType;
        reset({
          ...parsedUserData,
          userAddressInformation: {
            ...parsedUserData?.userAddressInformation,
            liveSince:
              dayjs(parsedUserData?.userAddressInformation?.liveSince) ?? "NA",
          },
          siteVerifierInformation: {
            ...parsedUserData?.siteVerifierInformation,
          },
          lastLogin: dayjs(parsedUserData?.lastLogin) ?? "NA",
          registeredAt: dayjs(parsedUserData?.registeredAt) ?? "NA",
          paymentInformation: {
            recipientEmail: parsedUserData?.paymentInformation?.recipientEmail,
            recipientAddress:
              parsedUserData?.paymentInformation?.recipientAddress,
            paymentMethod:
              parsedUserData?.paymentInformation?.paymentMethod ?? "NA",
            qrAttachment: parsedUserData?.paymentInformation?.qrAttachment,
            paymentDetail: parsedUserData?.paymentInformation?.paymentDetail,
          },
        });
        setUserData(parsedUserData);
        setIsSuspend(parsedUserData?.siteVerifierInformation?.isSuspend);
      });
    }
  }, [userId, showModal, setValue, getSiteVerifierUserById]);

  const onSubmit = async (data: any) => {
    const reasonForSuspension =
      data.siteVerifierInformation.reasonForSuspension;
    const res: any = await suspendUser(userId, reasonForSuspension);
    if (res.status === 200) {
      setShowModal(false);
      refreshGrid();
      setShowAlertModal({
        title: "Notification",
        message: "User has been suspended",
      });
    } else {
      setShowAlertModal({
        title: "Notification",
        message: res.data.message,
      });
    }
  };

  return (
    <>
      {downloadError && (
        <ModalPopup
          visible={!!downloadError.title || !!downloadError.message}
          onClose={() => setDownloadError({ title: "", message: "" })}
          title={downloadError?.title}
          content={<p>{downloadError.message}</p>}
        />
      )}

      {isLoading && <Loading />}
      <CustomModal
        width={"85%"}
        title={""}
        open={showModal}
        onOk={() => {}}
        onCancel={() => {
          setShowModal(false);
        }}
        footer={null}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="new-account-modal-wrapper border-gray p-4">
            <div className="new-account-content-wrapper">
              <div className="grid grid-cols-3 gap-4 bg-white">
                <div className="">
                  <label className="text-sm text-slate-300" htmlFor="name">
                    Name
                  </label>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Textbox
                          disabled
                          value={field.value}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                </div>
                <div>
                  <label
                    className="text-sm text-slate-300"
                    htmlFor="registeredAt"
                  >
                    Registered On
                  </label>
                  <Controller
                    name="registeredAt"
                    control={control}
                    render={({ field }) => {
                      return (
                        <DatePicker
                          className="block w-full border border-light-gray-100 p-2 text-dark-gray shadow-sm ring-1 ring-inset ring-gray placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-5"
                          disabled
                          value={field.value}
                        />
                      );
                    }}
                  />
                </div>
                <div>
                  <label
                    className="text-sm text-slate-300"
                    htmlFor="phoneModel"
                  >
                    Phone Model
                  </label>
                  <Controller
                    name="siteVerifierInformation.phoneModel"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Textbox
                          disabled
                          value={field.value}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                </div>
                <div>
                  <label className="text-sm text-slate-300" htmlFor="Email">
                    Email
                  </label>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Textbox
                          disabled
                          value={field.value}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                </div>
                <div>
                  <label className="text-sm text-slate-300" htmlFor="mobileNo">
                    Mobile No.
                  </label>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Textbox
                          disabled
                          value={field.value}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                </div>
                <div>
                  <label className="text-sm text-slate-300" htmlFor="status">
                    Status
                  </label>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Textbox
                          disabled
                          value={field.value}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                </div>

                <div>
                  <label className="text-sm text-slate-300" htmlFor="country">
                    Country
                  </label>
                  <Controller
                    disabled
                    name="userAddressInformation.country"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Textbox
                          disabled
                          value={field.value}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                </div>

                <div>
                  <label
                    className="text-sm text-slate-300"
                    htmlFor="postalCode"
                  >
                    Postal Code
                  </label>
                  <Controller
                    disabled
                    name="userAddressInformation.postalCode"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Textbox
                          disabled
                          value={field.value}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                </div>

                <div>
                  <div className="mt-2">
                    <Controller
                      name="siteVerifierInformation.isSuspend"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          disabled
                          labelPosition={"right"}
                          label="Suspended Account"
                          value={field.value}
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e);
                            setIsSuspend(e.target.checked);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="mt-2">
                    <Controller
                      name="siteVerifierInformation.isVerified"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          disabled
                          labelPosition={"right"}
                          label="Account Verified"
                          value={field.value}
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                  <div className="mt-2">
                    <Controller
                      name="siteVerifierInformation.isTrainingCompleted"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          disabled
                          labelPosition={"right"}
                          label="Training Completed"
                          value={field.value}
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                </div>

                <div>
                  <label className="text-sm text-slate-300" htmlFor="street1">
                    Street 1
                  </label>
                  <Controller
                    name="userAddressInformation.address1"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Textbox
                          disabled
                          value={field.value}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                </div>

                <div>
                  <label className="text-sm text-slate-300" htmlFor="street2">
                    Street 2
                  </label>
                  <Controller
                    disabled
                    name="userAddressInformation.address2"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Textbox
                          disabled
                          value={field.value}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                </div>

                <div className="row-span-3 pt-3">
                  {userData?.siteVerifierInformation?.videoUrl && (
                    <div>
                      <div className="pb-2">
                        <span className="font-medium text-orange">
                          Downloads
                        </span>
                      </div>

                      <div className="pb-8 text-orange d-flex align-items-center">
                        <FontAwesomeIcon
                          icon={faDownload}
                          size="2x"
                          className="mr-2"
                        />
                        <Button
                          children="SelfIntroductionVideo.mp4"
                          variant="underlineborderless"
                          className="w-auto"
                          type="button"
                          onClick={async () => {
                            MultiFileDownloadUtilAttachments({
                              setIsLoading: setIsLoading,
                              setDownloadError: setDownloadError,
                              attachmentNames:
                                userData?.siteVerifierInformation?.videoUrl!,
                              zipFileName: `SelfIntroductionVideo-${removeWhiteSpaceAndReplaceWithDash(
                                userData?.name!,
                                "unnamed-user"
                              )}`,
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}

                  <div className="flex justify-between gap-5 row-start-6 row-end-8 w-11/12">
                    {!isSuspend && suspendAccess && (
                      <div className="basis-5/12">
                        <Button type="submit" variant="outlineborderless">
                          <span className="text-white">Suspend</span>
                        </Button>
                      </div>
                    )}

                    {isSuspend && suspendAccess && (
                      <div className="basis-5/12">
                        <Button
                          type="button"
                          variant="primarygreen"
                          onClick={() => {
                            unSuspendUser(userId).then(() => {
                              refreshGrid();
                              setShowModal(false);
                              setShowAlertModal({
                                title: "Notification",
                                message: "User has been unsuspended",
                              });
                            });
                          }}
                        >
                          <span>Unsuspend</span>
                        </Button>
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  <label className="text-sm text-slate-300" htmlFor="liveSince">
                    Have Lived Here Since
                  </label>
                  <Controller
                    name="userAddressInformation.liveSince"
                    control={control}
                    render={({ field }) => {
                      return (
                        <DatePicker
                          className="block w-full border border-light-gray-100 p-2 text-dark-gray shadow-sm ring-1 ring-inset ring-gray placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-5"
                          disabled
                          value={field.value}
                        />
                      );
                    }}
                  />
                </div>

                <div>
                  <label
                    className="text-sm text-slate-300"
                    htmlFor="stateProvince"
                  >
                    State/Province
                  </label>
                  <Controller
                    name="userAddressInformation.province"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Textbox
                          disabled
                          value={field.value}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                </div>

                <div>
                  <label
                    className="text-sm text-slate-300"
                    htmlFor="reasonForSuspension"
                  >
                    Reason For Suspension
                  </label>
                  <Controller
                    name="siteVerifierInformation.reasonForSuspension"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Textbox
                          disabled={isSuspend}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                </div>

                <div>
                  <label
                    className="text-sm text-slate-300"
                    htmlFor="paymentInformation.paymentMethod"
                  >
                    Payment Method
                  </label>
                  <Controller
                    name="paymentInformation.paymentMethod"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Textbox
                          disabled
                          value={field.value}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                </div>

                {SiteVerifiersUsersModalFunctions(userData?.paymentInformation)}
              </div>
            </div>
          </div>
        </form>
      </CustomModal>
    </>
  );
};

export default SiteVerifiersModal;
