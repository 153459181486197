import {
  IJobObservation,
  IJustificationData,
  IQuestionaire,
} from "../../types/JobType";
import justificationDataGenerator from "../../utils/excelUtils/dataGenerator/justificationDataGenerator";
import { questionaireDataGenerator } from "../../utils/excelUtils/dataGenerator/questionaireDataGenerator";
import ExcelFileGeneratorUtil from "../../utils/excelUtils/ExcelFileGeneratorUtil";

/**
 * An array of URL paths that indicate the job status allows editing.
 */
const EDITABLE_STATUS_URL = ["/jobs/open", "/jobs/draft"];

/**
 * Checks if the given pathname is in the editable status URL array.
 *
 * @param pathname The URL pathname to check.
 * @returns True if the pathname is in the editable status URL array, otherwise false.
 */
export const doesStatusAllowEditing = (pathname: string) => {
  const isEditable = EDITABLE_STATUS_URL.indexOf(pathname);
  return isEditable !== -1;
};

/**
 * Generates an Excel file based on the given questionnaire data.
 *
 * @param questionaireData The questionnaire data to be used for generating the Excel file.
 * @returns A promise that resolves when the Excel file is generated successfully.
 */
export const generateExcelFileBasedOnQuestionaireData = ({
  excelFileName = "questionnaire.zip",
  questionaireData,
  observationData,
}: {
  excelFileName?: string;
  questionaireData: IQuestionaire;
  observationData: IJobObservation;
}) => {
  const formattedQuestionaireData = questionaireDataGenerator({
    questionaireData: questionaireData,
    observationData: observationData,
  });

  ExcelFileGeneratorUtil({
    filename: excelFileName,
    formattedDataForExcelFile: formattedQuestionaireData,
  });
};

export const generateExcelFileBasedOnJustificationData = ({
  excelFileName = "justification.zip",
  justificationData,
}: {
  excelFileName?: string;
  justificationData: IJustificationData;
}) => {
  const formattedJustificationData = justificationDataGenerator({
    justificationData: justificationData,
  });

  ExcelFileGeneratorUtil({
    filename: excelFileName,
    formattedDataForExcelFile: formattedJustificationData,
  });
};
