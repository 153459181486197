import { useContext, useEffect, useState } from "react";
import DropdownInput from "../../components/dropdown";
import CustomButton from "../../components/button";
import Pagination from "../../components/pagination";
import CreateJobModal from "../../components/createJobModal";
import useJobs from "../../hooks/useJobs";
import { useLocation } from "react-router-dom";
import JobListingRowFeature from "./jobsPageFeatures/jobListingRowFeature";
import { GetJobResponseBody, IItem } from "../../types/JobType";
import ViewJobModal from "../../components/viewJobModal";
import Textbox from "../../components/textbox";
import { Controller, useForm } from "react-hook-form";
import useDropDown from "../../hooks/useDropDown";
import {
  changeUIDisplayByURL,
  createPropsForRetrievingJobs,
} from "./jobsPageFeatures/jobsFeatureService";
import Loading from "../../components/loading";
import SubmitJobModal from "../../components/submitJobModal";
import ApprovedJobModal from "../../components/approvedJobModal";
import UpdateJobModal from "../../components/updateJobModal";
import InviteSVModal from "../../components/inviteSVModal";
import { RoleAccessContext } from "../../context/RoleAccessContext";
import ModalPopup from "../../components/modalPopUp";

const JobsPage = ({ refreshSideNav }: { refreshSideNav: () => void }) => {
  const { roleAccessObj } = useContext(RoleAccessContext);

  const roleAccess = roleAccessObj.find(
    (x) => x.module.id === 2 && x.page.id === 3
  );

  const [readAccess, setReadAccess] = useState(false);
  const [writeAccess, setWriteAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [updateAccess, setUpdateAccess] = useState(false);

  let dropdownKebabItem: { value: string; label: string }[] = [];

  useEffect(() => {
    if (roleAccess) {
      setReadAccess(roleAccess?.read);
      setDeleteAccess(roleAccess?.delete);
      setWriteAccess(roleAccess?.write);
      setUpdateAccess(roleAccess?.update);
    }

    if (roleAccess?.read) {
      dropdownKebabItem.push({ value: "view", label: "View" });
    }
    if (roleAccess?.delete) {
      dropdownKebabItem.push({ value: "remove", label: "Remove" });
    }
  }, [roleAccessObj]);

  const location = useLocation();
  const { getAllJobsData, state } = useJobs();
  const { handleSubmit, control, setValue } = useForm();
  const [locationUrl, setLocationUrl] = useState("");
  const [showCreateJobModal, setShowCreateJobModal] = useState(false);
  const [showViewJobModal, setShowViewJobModal] = useState(false);
  const [showSubmitJobModal, setShowSubmitJobModal] = useState(false);
  const [showApprovedJobModal, setShowApprovedJobModal] = useState(false);
  const [showUpdateJobModal, setShowUpdateJobModal] = useState(false);
  const [showInviteSVModal, setShowInviteSVModal] = useState(false);
  const [jobListingData, setJobListingData] = useState<any>([]);

  const [modalJobMode, setModalJobMode] = useState<"Create" | "Edit" | "Draft">(
    "Create"
  );

  const [itemData, setItemData] = useState<IItem>();

  const [paginationDetails, setPaginationDetails] =
    useState<GetJobResponseBody>();

  const [currentGridFilters, setCurrentGridFilters] = useState<any>({});
  const [jobStatusDropdownList, setJobStatusDropdownList] = useState<any>();

  const {
    getJobStatusDropDownList,
    getCountriesAndSortByList,
    state: dropdownState,
  } = useDropDown();

  const [showAlertModal, setShowAlertModal] = useState<{
    title: string;
    message: string;
  }>({
    title: "",
    message: "",
  });

  const onFiltersSubmit = (data: any) => {
    if (readAccess) {
      const jobStatusValue = jobStatusDropdownList?.find(
        (js: any) => js.name === data?.jobStatus
      );

      setCurrentGridFilters({
        pageSize: 7,
        statusId: jobStatusValue?.id,
        country: data?.country,
        province: data?.province,
        sortBy: data.sortBy,
      });

      getAllJobsData({
        pageSize: 7,
        statusId: jobStatusValue?.id,
        country: data?.country,
        province: data?.province,
        sortBy: data.sortBy,
      }).then((res: any) => {
        setPaginationDetails(res);
        const items = res?.items;
        setJobListingData(items);
      });
    } else {
      setShowAlertModal({
        title: "Access Denied",
        message: "You do not have read access to this.",
      });
    }
  };

  const refreshGrid = () => {
    getAllJobsData(currentGridFilters).then((res: any) => {
      setPaginationDetails(res);
      const items = res?.items;
      setJobListingData(items);
      setCurrentGridFilters({
        statusId: currentGridFilters?.statusId,
        pageSize: 7,
        pageIndex: 1,
        sortBy: currentGridFilters?.sortBy,
        country: currentGridFilters?.country,
        province: currentGridFilters?.province,
      });
    });
    refreshSideNav();
  };

  const handlePagePaginationClick = (pageIndex: number) => {
    if (readAccess) {
      getAllJobsData({ ...currentGridFilters, pageIndex: pageIndex }).then(
        (res: any) => {
          setPaginationDetails(res);
          const items = res?.items;
          setJobListingData(items);
          setCurrentGridFilters({
            statusId: currentGridFilters?.statusId,
            pageSize: 7,
            pageIndex: pageIndex,
            sortBy: currentGridFilters?.sortBy,
            country: currentGridFilters?.country,
            province: currentGridFilters?.province,
          });
        }
      );
    } else {
      setShowAlertModal({
        title: "Access Denied",
        message: "You do not have read access to this.",
      });
    }
  };

  const getData = async (jobStatus: any) => {
    const jobId = await createPropsForRetrievingJobs({
      jobPagePathname: location.pathname,
      jobPageStatusList: jobStatus,
    })
      .retrieveJobStatusForURL()
      .returnJobStatusId();

    getAllJobsData({
      statusId: jobId,
      pageSize: 7,
    }).then((res: any) => {
      setPaginationDetails(res);
      const items = res?.items;
      setJobListingData(items);
    });

    setCurrentGridFilters({
      statusId: jobId,
      pageSize: 7,
      pageIndex: 1,
      sort: "Descending",
    });
  };

  useEffect(() => {
    setLocationUrl(location.pathname);
    getJobStatusDropDownList().then((jobStatus: any) => {
      setJobStatusDropdownList(jobStatus);
      getData(jobStatus);
    });

    changeUIDisplayByURL({
      jobPagePathname: location.pathname,
      setValueOfFilter: setValue,
    })
      .findJobPageType()
      .setValueOfJobStatus();

    getCountriesAndSortByList();
  }, [location]);

  return (
    <>
      {state.isLoading && <Loading />}
      {showCreateJobModal && (
        <CreateJobModal
          modalJobMode={modalJobMode}
          item={itemData}
          showModal={showCreateJobModal}
          setShowModal={setShowCreateJobModal}
          refreshGrid={refreshGrid}
        />
      )}
      {showUpdateJobModal && (
        <UpdateJobModal
          item={itemData}
          showModal={showUpdateJobModal}
          setShowModal={setShowUpdateJobModal}
          refreshGrid={refreshGrid}
        />
      )}
      {showViewJobModal && (
        <ViewJobModal
          item={itemData}
          showViewJobModal={showViewJobModal}
          setModalJobMode={setModalJobMode}
          setShowViewJobModal={setShowViewJobModal}
          setShowCreateJobModal={setShowCreateJobModal}
          updateAccess={updateAccess}
        />
      )}

      <SubmitJobModal
        refreshGrid={refreshGrid}
        item={itemData}
        showSubmitJobModal={showSubmitJobModal}
        setShowSubmitJobModal={setShowSubmitJobModal}
        writeAccess={writeAccess}
      />

      <ApprovedJobModal
        refreshGrid={refreshGrid}
        item={itemData}
        showApprovedJobModal={showApprovedJobModal}
        setShowApprovedJobModal={setShowApprovedJobModal}
        writeAccess={writeAccess}
      />

      <InviteSVModal
        refreshGrid={refreshGrid}
        item={itemData}
        showInviteSVModal={showInviteSVModal}
        setShowInviteSVModal={setShowInviteSVModal}
      />

      <ModalPopup
        visible={!!showAlertModal.title || !!showAlertModal.message}
        onClose={() => setShowAlertModal({ title: "", message: "" })}
        title={showAlertModal?.title}
        content={<p>{showAlertModal.message}</p>}
      />

      <>
        <div className="p-5 border border-gray rounded-lg bg-white">
          <div className="block px-5 mb-5">
            <label className="text-xl mb-0 block font-bold">Jobs</label>
          </div>

          {readAccess && (
            <form>
              <div className="grid grid-cols-7 grid-rows-1 gap-5 p-5">
                <div className="block mb-4">
                  <label
                    htmlFor="Area"
                    className="block text-sm font-medium leading-6 text-dark-gray mb-1"
                  >
                    FILTER
                  </label>
                  <div className="block">
                    <Controller
                      control={control}
                      name="jobStatus"
                      render={({ field }) => (
                        <DropdownInput
                          id={"JobStatus_Jobs_Dropdown"}
                          value={"name"}
                          selectedOption={field.value}
                          label={"name"}
                          onChange={(selectedValue) => {
                            field.onChange(selectedValue);
                          }}
                          items={jobStatusDropdownList}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="block mb-4">
                  <label
                    htmlFor="Area"
                    className="block text-sm font-medium leading-6 text-dark-gray mb-1"
                  >
                    COUNTRY
                  </label>
                  <div className="block">
                    <Controller
                      control={control}
                      name="country"
                      render={({ field }) => (
                        <DropdownInput
                          id={"Country_Jobs_Dropdown"}
                          value={"name"}
                          selectedOption={field.value}
                          label={"name"}
                          onChange={(selectedValue) => {
                            field.onChange(selectedValue);
                          }}
                          items={dropdownState.countries}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="block mb-4">
                  <label
                    htmlFor="Area"
                    className="block text-sm font-medium leading-6 text-dark-gray mb-1"
                  >
                    STATE
                  </label>
                  <div className="block">
                    <Controller
                      control={control}
                      name="province"
                      render={({ field }) => (
                        <Textbox
                          onChange={field.onChange}
                          value={field.value}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="block mb-4">
                  <label
                    htmlFor="Area"
                    className="block text-sm font-medium leading-6 text-dark-gray mb-1"
                  >
                    SORT BY
                  </label>
                  <div className="block">
                    <Controller
                      control={control}
                      name="sortBy"
                      render={({ field }) => (
                        <DropdownInput
                          id={"SortBy_Jobs_Dropdown"}
                          value={"name"}
                          selectedOption={field.value}
                          label={"name"}
                          onChange={(selectedValue) => {
                            field.onChange(selectedValue);
                          }}
                          items={dropdownState.sortBy}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="block mt-6">
                  <div className="block">
                    <CustomButton
                      type="button"
                      children={"Search"}
                      variant={"primarygreen"}
                      // type="submit"
                      onClick={handleSubmit(onFiltersSubmit)}
                    />
                  </div>
                </div>

                {writeAccess && (
                  <div className="block mt-6">
                    <div className="block">
                      <CustomButton
                        type="button"
                        children={"Create New"}
                        variant={"primaryorange"}
                        onClick={() => {
                          setShowCreateJobModal(true);
                          setModalJobMode("Create");
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </form>
          )}

          <div className="block px-5 mb-3 h-4/6">
            <div className="relative mb-10">
              <table className="w-full text-sm text-left text-black h-5/6">
                <thead
                  className="text-xs border-b border-b-light-gray-100"
                  key={11}
                >
                  <tr key={10}>
                    <th scope="col" className="px-6 py-3" key={1}>
                      STATUS
                    </th>
                    <th scope="col" className="px-6 py-3" key={2}>
                      TARGET COMPLETION DATE
                    </th>
                    <th scope="col" className="px-6 py-3" key={3}>
                      COMPANY NAME
                    </th>
                    <th scope="col" className="px-6 py-3" key={4}>
                      LOCATION
                    </th>
                    {!(
                      locationUrl.includes("/completed") ||
                      locationUrl.includes("/cancelled")
                    ) && (
                      <th scope="col" className="px-6 py-3" key={5}>
                        STATUS INDICATOR
                      </th>
                    )}
                    <th
                      scope="col"
                      className="px-6 py-3 justify-start"
                      key={6}
                    ></th>
                  </tr>
                </thead>
                <tbody className="">
                  {state?.jobList?.items?.map((item: IItem, index: number) => (
                    <JobListingRowFeature
                      item={item}
                      setShowViewJobModel={setShowViewJobModal}
                      setItemData={setItemData}
                      key={index * 20}
                      setModalJobMode={setModalJobMode}
                      setShowCreateJobModal={setShowCreateJobModal}
                      setShowSubmitJobModal={setShowSubmitJobModal}
                      setShowApprovedJobModal={setShowApprovedJobModal}
                      setShowUpdateJobModal={setShowUpdateJobModal}
                      setShowInviteSVModal={setShowInviteSVModal}
                      jobPagePathname={locationUrl}
                      refreshGrid={refreshGrid}
                      readAccess={readAccess}
                      writeAccess={writeAccess}
                      updateAccess={updateAccess}
                      deleteAccess={deleteAccess}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex justify-start h-8">
            <div>
              <Pagination
                currentPage={paginationDetails?.pageIndex}
                totalPages={paginationDetails?.totalPages}
                onClickPage={handlePagePaginationClick}
              />
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default JobsPage;
