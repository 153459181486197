import React from "react";
import "./styles.css";

const Layout = ({ isCollapsed, children }: any) => {
  return (
    <div
      className={`page-layout-container p-5 bg-light-gray-100 transition-all duration-500 min-h-screen ${
        isCollapsed ? "collapsed" : ""
      }`}
    >
      {children}
    </div>
  );
};

export default Layout;
