import { useState, useEffect, useRef, useContext } from "react";
import DropdownInput from "../../components/dropdown";
import CustomButton from "../../components/button";
import usePerformance from "../../hooks/usePerformance";
import { useForm, Controller } from "react-hook-form";
import dayjs from "dayjs";
import useDropDown from "../../hooks/useDropDown";
import Loading from "../../components/loading";
import ModalPopup from "../../components/modalPopUp";
import { generateUserErrorMessage } from "../../utils/constants/ErrorConstants";
import PerformanceDetailsMonthlyTable from "../../components/performanceDetailsMonthlyTable";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { RoleAccessContext } from "../../context/RoleAccessContext";

interface MonthData {
  createdCount?: any;
  assignedWithin24HoursCount?: any;
  assignedBetween24And48HoursCount?: any;
  assignedAbove48HoursCount?: any;
  outstandingUnassignedAbove48HoursCount?: any;
  submittedWithin24HoursCount?: any;
  submittedBetween24And48HoursCount?: any;
  pendingDeliveryBySVAbove48HoursCount?: any;
  rating?: {
    delivery?: any;
    quality?: any;
    responsiveness?: any;
  };
}

interface PerformanceData {
  year: number;
  months: MonthData[];
  performanceMonthlyResult: any;
}

const PerformancePage = () => {
  const { roleAccessObj } = useContext(RoleAccessContext);

  const roleAccess = roleAccessObj.find(
    (x) => x.module.id === 2 && x.page.id === 3
  );

  const [readAccess, setReadAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);

  let dropdownKebabItem: { value: string; label: string }[] = [];

  useEffect(() => {
    if (roleAccess) {
      setReadAccess(roleAccess?.read);
      setDeleteAccess(roleAccess?.delete);
    }

    if (roleAccess?.read) {
      dropdownKebabItem.push({ value: "view", label: "View" });
    }
    if (roleAccess?.delete) {
      dropdownKebabItem.push({ value: "remove", label: "Remove" });
    }
  }, [roleAccessObj]);

  const { handleSubmit, control } = useForm();

  const { getJobYearRangeDropDownList, state: dropdownState } = useDropDown();

  const { getPerformanceResults, state } = usePerformance();

  const [showErrorModal, setShowErrorModal] = useState({
    title: "",
    message: "",
  });

  useEffect(() => {
    getJobYearRangeDropDownList();
    getPerformanceResults({ year: dayjs().year() }).catch((err) => {
      setShowErrorModal({
        title: "Error",
        message: generateUserErrorMessage(err.message),
      });
    });
  }, []);

  const searchForData = (data: any) => {
    const yearData =
      data.year !== "" && data.year !== null && data.year !== undefined
        ? data.year
        : dayjs().year();
    getPerformanceResults({ year: yearData }).catch((err) => {
      setShowErrorModal({
        title: "Error",
        message: generateUserErrorMessage(err.message),
      });
    });
  };

  const generateReport = () => {
    const input = document.getElementById("pdf-content");
    // Specify the id of the element you want to convert to PDF
    // there will always be an input assuming no changes were done
    html2canvas(input!).then((canvas) => {
      let imgWidth = 208;
      let imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL("img/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      // Specify the name of the downloaded PDF file
      pdf.save("performance-data-metrics.pdf");
    });
  };

  const generateExcelReport = () => {
    const { year, months, performanceMonthlyResult }: PerformanceData =
      state.performanceData;

    const headers = [
      year.toString(), // Column header for metrics
      "Jan",
      "Feb",
      "Mar",
      "April",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Define the metrics and map them to the corresponding data fields
    const metrics = [
      { title: "No. of jobs posted/created", key: "createdCount" },
      {
        title: "No. of jobs assigned within 24 hours",
        key: "assignedWithin24HoursCount",
      },
      {
        title: "No. of jobs assigned 24-48 hours",
        key: "assignedBetween24And48HoursCount",
      },
      {
        title: "No. of jobs assigned > 48 Hours",
        key: "assignedAbove48HoursCount",
      },
      {
        title: "No. of jobs outstanding (unassigned) > 48 hours",
        key: "outstandingUnassignedAbove48HoursCount",
      },
      {
        title: "No. of jobs completed within 24 hours",
        key: "submittedWithin24HoursCount",
      },
      {
        title: "No. of jobs completed within 24-48 hours",
        key: "submittedBetween24And48HoursCount",
      },
      {
        title: "No. of jobs pending delivery by SV > 48 hours",
        key: "pendingDeliveryBySVAbove48HoursCount",
      },
      {
        title: "Average Job Quality - Delivery",
        key: "rating",
        subKey: "delivery",
      },
      {
        title: "Average Job Quality - Quality",
        key: "rating",
        subKey: "quality",
      },
      {
        title: "Average Job Quality - Responsiveness",
        key: "rating",
        subKey: "responsiveness",
      },
    ];

    // Initialize the Excel data array with headers
    const excelData = [headers];

    // Populate the rows using the performanceMonthlyResult data
    metrics.forEach((metric) => {
      const rowData = [metric.title];

      // Iterate through each month and add the data to the row
      months.forEach((month: MonthData) => {
        if (metric.key === "rating") {
          // Special handling for ratings as it's nested
          rowData.push(
            month.rating?.[metric.subKey as keyof typeof month.rating] ?? 0
          );
        } else {
          // For other metrics
          rowData.push(month[metric.key as keyof MonthData] ?? 0);
        }
      });

      // Add the row to the Excel data array
      excelData.push(rowData);
    });

    // Creating and exporting the Excel file
    const worksheet = XLSX.utils.aoa_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Performance Report");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, `Performance_Report_${year}.xlsx`);
  };

  return (
    <>
      <ModalPopup
        visible={!!showErrorModal.title || !!showErrorModal.message}
        onClose={() => setShowErrorModal({ title: "", message: "" })}
        title={showErrorModal?.title}
        content={<p>{showErrorModal.message}</p>}
      />

      {state.isLoading && <Loading />}
      <>
        <div className="p-5 border border-gray rounded-lg bg-white">
          <div className="block px-5 mb-5">
            <label className="mb-0 block font-bold">Performance</label>
          </div>

          {readAccess && (
            <form>
              <div className="grid grid-cols-7 grid-rows-1 gap-5 p-5">
                <div className="block mb-4">
                  <label
                    htmlFor="Area"
                    className="block text-sm font-medium leading-6 text-dark-gray mb-1"
                  >
                    YEAR
                  </label>
                  <div className="block">
                    <Controller
                      control={control}
                      name="year"
                      render={({ field }) => (
                        <DropdownInput
                          id={"Year_Performance_Dropdown"}
                          onChange={(selectedValue) => {
                            field.onChange(selectedValue);
                          }}
                          value={"name"}
                          selectedOption={field.value}
                          label={"name"}
                          items={dropdownState.yearRange}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="block content-center mt-2">
                  <CustomButton
                    children={"Search"}
                    variant={"primarygreen"}
                    type="button"
                    onClick={handleSubmit(searchForData)}
                  />
                </div>

                <div className="block mt-2 col-span-1 content-center">
                  <div>
                    <CustomButton
                      children={"Generate PDF Report"}
                      variant={"primaryorange"}
                      onClick={generateReport}
                      type="button"
                    />
                  </div>
                </div>

                <div className="block mt-2 col-span-1 content-center">
                  <div>
                    <CustomButton
                      children={"Generate Excel Report"}
                      variant={"primaryorange"}
                      onClick={generateExcelReport}
                      type="button"
                    />
                  </div>
                </div>
              </div>
            </form>
          )}

          <div className="block px-5 mb-5 overflow-auto">
            <div className="relative  mb-5">
              {readAccess && (
                <PerformanceDetailsMonthlyTable
                  retrievedPerformanceData={state.performanceData}
                  id="pdf-content"
                />
              )}
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default PerformancePage;
