import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import "./styles.css";

interface DropdownKebabProps {
  onChange: (value: any) => void;
  items: { value: string; label: string }[];
  disabled?: boolean;
}

const DropdownKebab: React.FC<DropdownKebabProps> = ({ onChange, items }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleItemClick = (value: any) => {
    onChange(value);
    setIsDropdownOpen(false); // Close the dropdown after selection
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <button
        type="button"
        className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        data-testid="dropdown-button"
      >
        <FontAwesomeIcon icon={faEllipsis} />
      </button>
      {isDropdownOpen && (
        <div
          className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
        >
          <div className="py-1 divide-y divide-light-gray-100" role="none">
            {items.map((item, index) => (
              <button
                key={index}
                onClick={() => handleItemClick(item.value)}
                className="block px-4 py-2 text-gray-700 text-sm w-full text-left"
                role="menuitem"
                tabIndex={-1}
                data-testid="dropdown-items"
              >
                {item.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownKebab;
