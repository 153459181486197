import { DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";

const DatePickerComponent: React.FC<{
  disabled?: boolean;
  required?: boolean;
  value?: Dayjs | any;
  onChange: (...event: any[]) => void;
  validationMessages?: { [key in any]: any };
}> = ({
  disabled,
  required,
  value,
  onChange,
  validationMessages = { required: "This field is required" },
}) => {
  const [inputValue, setInputValue] = useState(value || "");
  const [currentValidationMessage, setCurrentValidationMessage] = useState(
    required
      ? validationMessages.required
        ? validationMessages.required
        : required
        ? required
        : ""
      : ""
  );

  const validateInput = (newValue: string) => {
    let isValid = true;
    let message = "";
    if (required && newValue.includes("Invalid Date")) {
      isValid = false;
      message = validationMessages?.required;
    }

    setCurrentValidationMessage(message);

    return isValid;
  };

  useEffect(() => {
    setInputValue(value || null);
  }, [value]);

  const handleChange = (value: any) => {
    if (validateInput(dayjs(value).toString())) {
      onChange(dayjs(value).add(dayjs(value).utcOffset(), "minute"));
    }
    if (dayjs(value).toString().includes("Invalid Date")) {
      onChange(null);
    }
  };

  return (
    <>
      <DatePicker
        disabled={disabled}
        onChange={handleChange}
        value={value || null}
        className="block w-full border border-light-gray-100 p-2 text-dark-gray shadow-sm ring-1 ring-inset ring-gray placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-5"
      />
      {currentValidationMessage
        ? required &&
          inputValue === null && (
            <p className="mt-2 text-red text-xs">
              {validationMessages?.required || "Field is required"}
            </p>
          )
        : //will go into the statement below

          inputValue === null && (
            <p className="mt-2 text-red text-xs">
              {currentValidationMessage || "Field is Required"}
            </p>
          )}
    </>
  );
};

export default DatePickerComponent;
