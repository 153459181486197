import React from "react";
import { Modal } from "antd";

interface ModalPopupProps {
  width?: any;
  visible: boolean;
  onClose: () => void;
  title?: string;
  content: React.ReactNode;
}

const ModalPopup: React.FC<ModalPopupProps> = ({
  width,
  visible,
  onClose,
  title,
  content,
}) => {
  return (
    <Modal
      width={width}
      title={title}
      open={visible}
      onOk={onClose}
      onCancel={onClose}
      footer={null}
    >
      {content}
    </Modal>
  );
};

export default ModalPopup;
