import React, { Dispatch, Suspense, lazy, useEffect, useState } from "react";
import CustomModal from "../modal";
import Button from "../button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import Textarea from "../textarea";
import Textbox from "../textbox";
import DropdownInput from "../dropdown";
import {
  CreateOrUpdateJobBodyDataType2,
  IItem,
  JobAddressInformation,
} from "../../types/JobType";
import useJobs from "../../hooks/useJobs";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import useDropDown from "../../hooks/useDropDown";
import { IMapLocationMarkerType, IMapType } from "../../types/MapDataType";

import Loading from "../loading";
import DatePickerComponent from "../datePicker";
import { generateUserErrorMessage } from "../../utils/constants/ErrorConstants";
import ModalPopup from "../modalPopUp";
import RadioButtonComponent from "../radio";
import StyledTextArea from "../styledTextArea";
import useSiteVerifiers from "../../hooks/useSiteVerifiers";
const MapModal = lazy(() => import("../mapModal"));

/**
 * This job modal is used for the initial stages of the job (Draft, Open and Editing)
 * It can submit drafts, create new jobs, and also edit jobs
 * To only update a job, use UpdateJobModal instead.
 *
 */
const CreateJobModal = ({
  modalJobMode,
  item,
  showModal,
  setShowModal,
  refreshGrid,
}: {
  modalJobMode: "Create" | "Edit" | "Draft";
  item: IItem | undefined;
  showModal: boolean;
  setShowModal: Dispatch<React.SetStateAction<boolean>>;
  refreshGrid: () => void;
}) => {
  const { handleSubmit, control, reset, watch, formState } =
    useForm<CreateOrUpdateJobBodyDataType2>();
  const [showErrorPopupModal, setShowErrorPopupModal] = useState(false);
  const [showErrorModalMessage, setShowErrorModalMessage] = useState("");
  const [showMapOverlayModal, setShowMapOverlayModal] = useState(false);
  const [locationData, setLocationData] = useState<IMapType>();
  const [coordinateData, setCoordinateData] = useState({
    latitude: 0,
    longitude: 0,
  });
  const {
    getCountriesDropDownList,
    getTimezonesDropDownList,
    state: dropdownState,
  } = useDropDown();
  const {
    state,
    getJobsDataById,
    updateJobById,
    createDraftJobsDataById,
    createNewJob,
    createReopenJobsDataById,
    createMapPointData,
  } = useJobs();

  useEffect(() => {
    getTimezonesDropDownList();
    getCountriesDropDownList();

    if (modalJobMode !== "Create") {
      getJobsDataById(item?.id ?? 0).then((res: any) => {
        reset({
          companyName: res?.companyName,
          companyEmail: res?.companyEmail,
          jobIdStr: res?.jobIdStr,
          officeNo: res?.officeNo,
          onSiteInstructions: res?.onSiteInstructions,
          otherDetails: res?.otherDetails,
          targetCompletionDate: dayjs(res?.targetCompletionDate).add(
            dayjs(res?.targetCompletionDate).utcOffset(),
            "minute"
          ),
          proposedAppointmentDate: dayjs(res?.proposedAppointmentDate),
          estimatedPayment: res?.estimatedPayment,
          isDiscreet: res?.isDiscreet,
          jobAddressInformation: {
            buildingName: res?.jobAddressInformation?.buildingName,
            formattedAddress:
              res?.jobAddressInformation?.formattedAddress ??
              res?.jobAddressInformation?.address1.trim() +
                " " +
                res?.jobAddressInformation?.address2,
            address1: res?.jobAddressInformation?.address1.trim(),
            address2: res?.jobAddressInformation?.address2,
            province: res?.jobAddressInformation?.province,
            country: res?.jobAddressInformation?.country,
            postalCode: res?.jobAddressInformation?.postalCode,
            timezone: res?.jobAddressInformation?.timeZone,
          },
        });
      });
    }
  }, [showModal]);

  const handleSubmitDraft = async (data: CreateOrUpdateJobBodyDataType2) => {
    const formattedJobAddress: JobAddressInformation = await getCoordinateData(
      data
    );
    if (modalJobMode !== "Draft") {
      onSubmit(
        {
          ...data,
          jobAddressInformation: formattedJobAddress,
          isDiscreet: data.isDiscreet ?? true,
        },
        createDraftJobsDataById
      );
    } else {
      createDraftJobsDataById({
        id: item?.id!,
        payload: {
          ...data,
          jobAddressInformation: formattedJobAddress,
          isDiscreet: data.isDiscreet ?? true,
        },
      });
      refreshGrid();
      setShowModal(false);
    }
  };

  const handleViewLocation = (data: any) => {
    setLocationData({
      address1: data?.jobAddressInformation?.address1.trim(),
      address2: data?.jobAddressInformation?.address2.trim(),
      province: data?.jobAddressInformation?.province,
      country: data?.jobAddressInformation?.country,
      postalCode: data?.jobAddressInformation?.postalCode,
      buildingName: data?.jobAddressInformation?.buildingName,
    });
    setShowMapOverlayModal(true);
  };

  const getCoordinateData = async (data: CreateOrUpdateJobBodyDataType2) => {
    const formattedData = (await createMapPointData({
      address1: data?.jobAddressInformation?.address1.trim(),
      address2: data?.jobAddressInformation?.address2.trim(),
      province: data?.jobAddressInformation?.province,
      country: data?.jobAddressInformation?.country,
      postalCode: data?.jobAddressInformation?.postalCode,
      buildingName: data?.jobAddressInformation?.buildingName,
    })) as IMapLocationMarkerType;

    const formattedJobAddress = {
      ...data.jobAddressInformation,
      formattedAddress:
        data?.jobAddressInformation?.address1.trim() +
        " " +
        data?.jobAddressInformation?.address2,
      latitude: formattedData?.geometry?.location?.lat,
      longitude: formattedData?.geometry?.location?.lng,
    };

    return formattedJobAddress;
  };

  const handleSubmitCreateOrUpdate = async (
    data: CreateOrUpdateJobBodyDataType2
  ) => {
    let formattedJobAddress: JobAddressInformation;
    try {
      if (coordinateData.latitude === 0 && coordinateData.longitude === 0) {
        formattedJobAddress = await getCoordinateData(data);
      } else {
        formattedJobAddress = {
          ...data.jobAddressInformation,
          formattedAddress:
            data?.jobAddressInformation?.address1.trim() +
            " " +
            data?.jobAddressInformation?.address2,
          latitude: coordinateData.latitude,
          longitude: coordinateData.longitude,
        };
      }
      if (modalJobMode === "Create") {
        onSubmit(
          {
            ...data,
            jobAddressInformation: formattedJobAddress,
            isDiscreet: data.isDiscreet ?? true,
          },
          createNewJob
        );
      } else if (modalJobMode === "Edit") {
        onSubmit(
          {
            ...data,
            jobAddressInformation: formattedJobAddress,
            isDiscreet: data.isDiscreet ?? true,
          },
          updateJobById
        );
      } else if (modalJobMode === "Draft") {
        onSubmit(
          {
            ...data,
            jobAddressInformation: formattedJobAddress,
            isDiscreet: data.isDiscreet ?? true,
          },
          [updateJobById, createReopenJobsDataById]
        );
      }
    } catch (e) {
      setShowErrorModalMessage(
        generateUserErrorMessage("An Error occured. Please try again later.")
      );
      setShowErrorPopupModal(true);
    }
  };

  const onSubmit = (
    data: CreateOrUpdateJobBodyDataType2 | number,
    func:
      | (({
          id,
          payload,
        }: {
          id: number;
          payload: CreateOrUpdateJobBodyDataType2;
        }) => Promise<unknown>)
      | any
  ) => {
    //gather data here and send
    const payload = data as CreateOrUpdateJobBodyDataType2;
    const id = item?.id as number;
    if (modalJobMode === "Edit") {
      func({ id, payload }).then((response: any) => {
        if (response?.statusCode === 200) {
          refreshGrid();
          setShowModal(false);
        } else {
          setShowErrorModalMessage(response?.data?.message);
          setShowErrorPopupModal(true);
        }
      });
    } else if (modalJobMode === "Create") {
      func({ payload }).then((response: any) => {
        if (response?.statusCode === 200) {
          refreshGrid();
          setShowModal(false);
        } else {
          setShowErrorModalMessage(response?.data?.message);
          setShowErrorPopupModal(true);
        }
      });
    } else if (modalJobMode === "Draft") {
      func[0]({ id, payload }).then((response: any) => {
        if (response?.statusCode === 200) {
          func[1](id).then((response: any) => {
            if (response?.statusCode === 200) {
              refreshGrid();
              setShowModal(false);
            } else {
              setShowErrorModalMessage(response?.data?.message);
              setShowErrorPopupModal(true);
            }
          });
        } else {
          setShowErrorModalMessage(response?.data?.message);
          setShowErrorPopupModal(true);
        }
      });
    }
  };

  return (
    <>
      {state.isLoading && <Loading />}
      <ModalPopup
        visible={showErrorPopupModal}
        onClose={() => setShowErrorPopupModal(false)}
        content={showErrorModalMessage}
      />
      {showMapOverlayModal && (
        <Suspense fallback={<Loading />}>
          <MapModal
            setShowModal={setShowMapOverlayModal}
            showModal={showMapOverlayModal}
            locationData={locationData}
            setCoordinateData={setCoordinateData}
          />
        </Suspense>
      )}
      {!showMapOverlayModal && (
        <form>
          <CustomModal
            width={"85%"}
            title={""}
            open={showModal}
            onOk={() => {}}
            onCancel={() => {
              setShowModal(false);
            }}
            footer={null}
          >
            <div className="border-gray p-4">
              <div>
                <div className="text-2xl">Job Details</div>
                <div className="row">
                  <div className="grid grid-cols-3 gap-4 bg-white">
                    <div>
                      <label className="text-sm text-slate-300" htmlFor="">
                        Id*
                      </label>
                      <Controller
                        control={control}
                        name="jobIdStr"
                        rules={{ required: "This field is required" }}
                        render={({ field }) => (
                          <Textbox
                            required
                            type="alphanumeric"
                            onChange={field.onChange}
                            value={field.value}
                            validationMessages={{
                              required:
                                formState.errors?.jobIdStr?.message || " ",
                            }}
                          />
                        )}
                      />
                    </div>

                    <div>
                      <label className="text-sm text-slate-300" htmlFor="">
                        Company Name*
                      </label>
                      <Controller
                        control={control}
                        name="companyName"
                        rules={{ required: "This field is required" }}
                        render={({ field }) => (
                          <Textbox
                            required
                            onChange={field.onChange}
                            value={field.value}
                            validationMessages={{
                              required:
                                formState.errors?.companyName?.message || " ",
                            }}
                          />
                        )}
                      />
                    </div>

                    <div>
                      <label className="text-sm text-slate-300" htmlFor="">
                        Company Email*
                      </label>
                      <Controller
                        control={control}
                        name="companyEmail"
                        rules={{ required: "This field is required" }}
                        render={({ field }) => (
                          <Textbox
                            required
                            onChange={field.onChange}
                            value={field.value}
                            validationMessages={{
                              required:
                                formState.errors?.companyEmail?.message || " ",
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="grid grid-cols-3 gap-4 bg-white">
                    <div>
                      <label className="text-sm text-slate-300" htmlFor="">
                        Company Phone No.*
                      </label>
                      <Controller
                        control={control}
                        name="officeNo"
                        rules={{ required: "This field is required" }}
                        render={({ field }) => (
                          <Textbox
                            required
                            type="phone"
                            onChange={field.onChange}
                            value={field.value}
                            validationMessages={{
                              required:
                                formState.errors?.officeNo?.message || " ",
                            }}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <label className="text-sm text-slate-300" htmlFor="">
                        Target Completion Date*
                      </label>
                      <Controller
                        control={control}
                        name="targetCompletionDate"
                        rules={{ required: "This field is required" }}
                        render={({ field }) => (
                          <DatePickerComponent
                            required
                            value={field.value}
                            onChange={field.onChange}
                            validationMessages={{
                              required:
                                formState.errors?.targetCompletionDate
                                  ?.message || " ",
                            }}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <label className="text-sm text-slate-300" htmlFor="">
                        Estimated Payment*
                      </label>
                      <Controller
                        control={control}
                        name="estimatedPayment"
                        rules={{ required: "This field is required" }}
                        render={({ field }) => (
                          <Textbox
                            required
                            validationMessages={{
                              required:
                                formState.errors?.estimatedPayment?.message ||
                                " ",
                            }}
                            type="number"
                            onChange={field.onChange}
                            value={field?.value?.toString() ?? 0}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="grid grid-cols-3 gap-4 bg-white mb-">
                    <div className="">
                      <label className="text-sm text-slate-300" htmlFor="">
                        Other Details{watch("isDiscreet", true) && "*"}
                      </label>
                      <Controller
                        control={control}
                        name="otherDetails"
                        rules={{
                          required: watch("isDiscreet", true)
                            ? "This field is required"
                            : false,
                        }}
                        render={({ field }) => (
                          <StyledTextArea
                            required={watch("isDiscreet", true)}
                            onChange={field.onChange}
                            value={field.value}
                            watch={watch("isDiscreet", true)}
                            validationMessages={{
                              required:
                                formState.errors?.otherDetails?.message || " ",
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="">
                      <label className="text-sm text-slate-300" htmlFor="">
                        On-Site Instructions
                      </label>
                      <Controller
                        control={control}
                        name="onSiteInstructions"
                        rules={{ required: "This field is required" }}
                        render={({ field }) => (
                          <>
                            <Textarea
                              onChange={field.onChange}
                              value={field.value}
                              required
                              validationMessages={{
                                required:
                                  formState.errors?.onSiteInstructions
                                    ?.message || " ",
                              }}
                            />
                          </>
                        )}
                      />
                    </div>
                    <div>
                      <label className="text-sm text-slate-300" htmlFor="">
                        Timezone*
                      </label>
                      <Controller
                        control={control}
                        name="jobAddressInformation.timezone"
                        rules={{ required: "This field is required" }}
                        render={({ field }) => (
                          <DropdownInput
                            id={"Timezone_Create_Job_Dropdown"}
                            required
                            validationMessage={
                              formState.errors?.jobAddressInformation?.timezone
                                ?.message || " "
                            }
                            value={"name"}
                            selectedOption={field.value}
                            label={"name"}
                            onChange={(selectedValue) => {
                              field.onChange(selectedValue);
                            }}
                            items={dropdownState.timezones}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="grid grid-cols-3 gap-4 bg-white">
                    <div className="flex flex-col gap-2">
                      <label className="text-sm text-slate-300" htmlFor="">
                        Discreet/Non-Discreet
                      </label>
                      <div className="">
                        <Controller
                          control={control}
                          name="isDiscreet"
                          defaultValue={true}
                          render={({ field }) => (
                            <RadioButtonComponent
                              value={field.value}
                              radioButtonValues={[
                                {
                                  displayText: "Discreet",
                                  fieldValue: true,
                                },
                                {
                                  displayText: "Non-Discreet",
                                  fieldValue: false,
                                },
                              ]}
                              disabled={false}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="text-2xl">Job Address Details</div>
                  <div className="grid grid-cols-3 gap-4 bg-white">
                    <div className="col-span-2">
                      <label className="text-sm text-slate-300" htmlFor="">
                        Building Name*
                      </label>
                      <Controller
                        control={control}
                        name="jobAddressInformation.buildingName"
                        rules={{ required: "This field is required" }}
                        render={({ field }) => (
                          <Textbox
                            required
                            onChange={field.onChange}
                            value={field.value}
                            validationMessages={{
                              required:
                                formState.errors?.jobAddressInformation
                                  ?.buildingName?.message || " ",
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="grid grid-cols-3 gap-4 bg-white">
                    <div className="">
                      <label className="text-sm text-slate-300" htmlFor="">
                        Street 1*
                      </label>
                      <Controller
                        control={control}
                        name="jobAddressInformation.address1"
                        rules={{ required: "This field is required" }}
                        render={({ field }) => (
                          <>
                            <Textarea
                              onChange={field.onChange}
                              value={field.value}
                              required
                              validationMessages={{
                                required:
                                  formState.errors?.jobAddressInformation
                                    ?.address1?.message || " ",
                              }}
                            />
                          </>
                        )}
                      />
                    </div>
                    <div className="">
                      <label className="text-sm text-slate-300" htmlFor="">
                        Street 2*
                      </label>
                      <Controller
                        control={control}
                        name="jobAddressInformation.address2"
                        rules={{ required: "This field is required" }}
                        render={({ field }) => (
                          <Textarea
                            onChange={field.onChange}
                            value={field.value}
                            required
                            validationMessages={{
                              required:
                                formState.errors?.jobAddressInformation
                                  ?.address2?.message || " ",
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="grid grid-cols-3 gap-4 bg-white">
                    <div>
                      <label className="text-sm text-slate-300" htmlFor="">
                        Postal Code*
                      </label>
                      <Controller
                        control={control}
                        name="jobAddressInformation.postalCode"
                        rules={{ required: "This field is required" }}
                        render={({ field }) => (
                          <Textbox
                            required
                            onChange={field.onChange}
                            value={field.value}
                            validationMessages={{
                              required:
                                formState.errors?.jobAddressInformation
                                  ?.postalCode?.message || " ",
                            }}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <label className="text-sm text-slate-300" htmlFor="">
                        State/Province*
                      </label>
                      <Controller
                        control={control}
                        name="jobAddressInformation.province"
                        rules={{ required: "This field is required" }}
                        render={({ field }) => (
                          <Textbox
                            required
                            onChange={field.onChange}
                            value={field.value}
                            validationMessages={{
                              required:
                                formState.errors?.jobAddressInformation
                                  ?.province?.message || " ",
                            }}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <label className="text-sm text-slate-300" htmlFor="">
                        Country*
                      </label>
                      <Controller
                        control={control}
                        name="jobAddressInformation.country"
                        rules={{ required: "This field is required" }}
                        render={({ field }) => (
                          <DropdownInput
                            id={"Country_Create_Job_Dropdown"}
                            required
                            value={"name"}
                            selectedOption={field.value}
                            label={"name"}
                            onChange={(selectedValue) => {
                              field.onChange(selectedValue);
                            }}
                            items={dropdownState.countries}
                            validationMessage={
                              formState.errors?.jobAddressInformation?.country
                                ?.message || " "
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-4 bg-white mt-6">
                  <div className="block col-span-2">
                    <div className="w-1/2">
                      <Button
                        children={[
                          <FontAwesomeIcon
                            key={item?.id! + item?.companyName! + item?.status}
                            icon={faLocationDot}
                            className="mr-2"
                          />,
                          "View location",
                        ]}
                        name="viewLocationButton"
                        variant={"underlineborderless"}
                        onClick={handleSubmit(handleViewLocation)}
                      />
                    </div>
                    <p className="text-sm text-red">
                      Important: use this map for your reference.
                    </p>
                    <p className="text-sm text-red">
                      Please find the exact building location based on the
                      provided address
                    </p>
                  </div>
                  {/* 
                  <div className="block"></div> */}
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block self-end">
                    <Button
                      name="saveButton"
                      children={["Save Draft"]}
                      variant={"edit"}
                      onClick={handleSubmit(handleSubmitDraft)}
                    />
                  </div>
                  <div className="block self-end">
                    <Button
                      name="createButton"
                      children={[
                        modalJobMode === "Edit" ? "Edit Job" : `Create Job`,
                      ]}
                      variant={"primaryorange"}
                      onClick={handleSubmit(handleSubmitCreateOrUpdate)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </CustomModal>
        </form>
      )}
    </>
  );
};

export default CreateJobModal;
