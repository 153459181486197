import { useEffect, useState } from "react";
import Button from "../../components/button";
import confirmis_logo from "../../assets/confirmis_logo-design-final_ol.svg";
import "./styles.css";
import { Link } from "react-router-dom";
import Textbox from "../../components/textbox";
import { Controller, useForm } from "react-hook-form";
import Loading from "../../components/loading";
import ModalPopup from "../../components/modalPopUp";
import { useAuth } from "../../hooks/useAuth";

const ForgotPassword = () => {
  const { handleSubmit, control: searchControl } = useForm();
  const {
    resetPasswordDetails,
    // checkIsLoginIn
  } = useAuth();
  const [showErrorModal, setShowErrorModal] = useState<{
    title: string;
    message: string;
  }>({
    title: "",
    message: "",
  });

  const handleForgotPassword = (data: any) => {
    resetPasswordDetails({ email: data.email }).then((res: any) => {
      if (res !== 200) {
        setShowErrorModal({
          title: res?.data?.error || "",
          message: res?.data?.message || "",
        });
      }
    });
  };

  const [alert, setAlert] = useState(true);

  useEffect(() => {
    // when the component is mounted, the alert is displayed for 3 seconds
    setTimeout(() => {
      setAlert(false);
    }, 100);
  }, []);

  return (
    <>
      {alert && <Loading />}

      <ModalPopup
        visible={!!showErrorModal.title || !!showErrorModal.message}
        onClose={() => setShowErrorModal({ title: "", message: "" })}
        title={showErrorModal?.title}
        content={<p>{showErrorModal.message}</p>}
      />

      <form onSubmit={handleSubmit(handleForgotPassword)}>
        <div className="login-wrapper flex items-center justify-center h-screen">
          <div className="flex flex-col bg-white w-1/5 text-black">
            <div className="w-full flex justify-center py-4">
              <div className="">
                <div className="image-wrapper">
                  <img src={confirmis_logo} alt="Confirmis Logo" />
                </div>
              </div>
            </div>
            <div className="basis-2/5 py-8 text-black border-t-2 border-grey-900 text-left">
              <div className="login-details-wrapper">
                <div className="flex flex-col align-start pb-3 ">
                  <span className="text-3xl">FORGOT PASSWORD</span>
                </div>
                <div className="flex flex-start pb-5">
                  <div className="flex flex-col gap-3 w-full">
                    <div>
                      <div className="text-black pb-1">
                        <label htmlFor="email">
                          <span>Email Address</span>
                        </label>
                      </div>
                      <div>
                        <Controller
                          name="email"
                          control={searchControl}
                          render={({ field }) => (
                            <Textbox
                              type="email"
                              value={field.value}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button-container flex justify-center pt-3">
                  <Button variant="login" type="submit">
                    <span className="text-slate-50">Submit</span>
                  </Button>
                </div>
                <div className="pt-5">
                  <Link to="/login">
                    <Button variant="underlineborderless">Back to login</Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ForgotPassword;
