import React, { useEffect, useState } from "react";
import CustomModal from "../modal";
import Button from "../button";
import useJobs from "../../hooks/useJobs";
import { Controller, useForm } from "react-hook-form";
import { IItem } from "../../types/JobType";
import useSiteVerifiers from "../../hooks/useSiteVerifiers";
import CustomDropdownMultiSelect from "../customDropdownMultiSelect";
import CustomDropdownSingleSelect from "../customDropdownSingleSelect";
import { generateUserErrorMessage } from "../../utils/constants/ErrorConstants";
import Loading from "../loading";
import ModalPopup from "../modalPopUp";

const InviteSVModal = ({
  item,
  showInviteSVModal,
  setShowInviteSVModal,
  refreshGrid,
}: {
  item: IItem | undefined;
  showInviteSVModal: boolean;
  setShowInviteSVModal: React.Dispatch<React.SetStateAction<boolean>>;
  refreshGrid: () => void;
}) => {
  const { getJobsDataById, manualJobInvite, autoJobInvite, state } = useJobs();
  const { state: svState, getSiteVerifiersListByCountry } = useSiteVerifiers();

  const { control, reset, getValues } = useForm();
  const [dropdownReset, setDropdownReset] = useState(false);
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [showErrorPopupModal, setShowErrorPopupModal] = useState(false);
  const [showErrorModalMessage, setShowErrorModalMessage] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successModalMessage, setSuccessModalMessage] = useState("");

  const manualRelease = async () => {
    try {
      const itemId = item?.id ?? 0;
      const selectedCandidate = getValues("selectedCandidate");
      if (!selectedCandidate || selectedCandidate.length === 0) {
        setError1(true);
        return;
      }
      if (itemId !== 0 && selectedCandidate && selectedCandidate.length > 0) {
        const res = await manualJobInvite(
          itemId,
          getValues("selectedCandidate")
        );
        if (res.status === 200) {
          setSuccessModalMessage("Selected SV will be invited shortly.");
          setShowSuccessModal(true);
        }
      }
      setShowInviteSVModal(false);
      return;
    } catch (err) {
      setShowErrorModalMessage(
        generateUserErrorMessage("An Error occured. Please try again later.")
      );
      setShowErrorPopupModal(true);
    }
  };

  const autoRelease = async () => {
    try {
      const itemId = item?.id ?? 0;
      const selectedRadius = getValues("selectedRadius");
      if (!selectedRadius) {
        setError2(true);
        return;
      }
      if (itemId !== 0 && selectedRadius) {
        const res = await autoJobInvite(itemId, getValues("selectedRadius"));
        if (res.status === 200) {
          setSuccessModalMessage(
            `SV within ${selectedRadius}km radius will be invited shortly.`
          );
          setShowSuccessModal(true);
        }
      }
      setShowInviteSVModal(false);
      return;
    } catch (err) {
      setShowErrorModalMessage(
        generateUserErrorMessage("An Error occured. Please try again later.")
      );
      setShowErrorPopupModal(true);
    }
  };

  useEffect(() => {
    setError1(false);
    setError2(false);
    if (showInviteSVModal) {
      getSiteVerifiersListByCountry({
        Country: item?.location,
        JobId: item?.id,
      });
      // Set dropdown reset to true when modal opens
      setDropdownReset(true);
      const itemId = item?.id ?? 0;
      if (itemId !== 0) getJobsDataById(itemId);
    }
  }, [item, showInviteSVModal]);

  useEffect(() => {
    // Reset dropdown state when it should be reset
    if (dropdownReset) {
      reset(); // This resets the form
      setDropdownReset(false); // Reset the dropdownReset state
    }
  }, [dropdownReset, reset]);

  return (
    <>
      {state.isLoading && <Loading />}
      <ModalPopup
        visible={showErrorPopupModal}
        onClose={() => setShowErrorPopupModal(false)}
        content={showErrorModalMessage}
      />
      <ModalPopup
        visible={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        content={successModalMessage}
      />
      <CustomModal
        width={"60%"}
        title={""}
        open={showInviteSVModal}
        onOk={() => {}}
        onCancel={() => {
          setShowInviteSVModal(false);
        }}
        footer={null}
      >
        <header>
          <div className="block w-full">
            <div className="text-lg text-slate-300 font-medium">
              JOB ID: {state.job?.jobIdStr ?? "NA"}
            </div>
          </div>
        </header>
        <div className="border-gray p-4">
          <div className="block">
            <div className="grid grid-cols-6 bg-white mb-8">
              {/* content start */}
              <div className="col-span-6">
                <label className="text-base text-slate-300 font-medium">
                  Company Details
                </label>
                <div>
                  <div className="grid grid-cols-3 w-full h-4/6 gap-3">
                    <div className="block">
                      <label className="text-sm text-orange font-medium mr-2">
                        Company Name:
                      </label>
                      <br />
                      <label className="text-sm">
                        {item?.companyName ?? "NA"}
                      </label>
                    </div>
                    <div className="block">
                      <label className="text-sm text-orange font-medium mr-2">
                        Company Email:
                      </label>
                      <br />
                      <label className="text-sm">
                        {state.job?.companyEmail ?? "NA"}
                      </label>
                    </div>
                    <div className="block">
                      <label className="text-sm text-orange font-medium mr-2">
                        Company Office Contact No:
                      </label>
                      <br />
                      <label className="text-sm">
                        {state?.job?.officeNo ?? "NA"}
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="grid grid-cols-3 w-full h-4/6 gap-3">
                    <div className="block">
                      <label className="text-sm text-orange font-medium mr-2">
                        Building Name:
                      </label>
                      <br />
                      <label className="text-sm">
                        {state.job?.jobAddressInformation?.buildingName ?? "NA"}
                      </label>
                    </div>
                    <div className="block">
                      <label className="text-sm text-orange font-medium mr-2">
                        Street 1:
                      </label>
                      <br />
                      <label className="text-sm">
                        {state.job?.jobAddressInformation?.address1 ?? "NA"}
                      </label>
                    </div>
                    <div className="block">
                      <label className="text-sm text-orange font-medium mr-2">
                        Street 2:
                      </label>
                      <br />
                      <label className="text-sm">
                        {state.job?.jobAddressInformation?.address2 ?? "NA"}
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="grid grid-cols-3 w-full h-4/6 gap-3">
                      <div className="block">
                        <label className="text-sm text-orange font-medium mr-2">
                          Postcode:
                        </label>
                        <br />
                        <label className="text-sm">
                          {state.job?.jobAddressInformation?.postalCode ?? "NA"}
                        </label>
                      </div>
                      <div className="block">
                        <label className="text-sm text-orange font-medium mr-2">
                          State/Province:
                        </label>
                        <br />
                        <label className="text-sm">
                          {state.job?.jobAddressInformation?.province ?? "NA"}
                        </label>
                      </div>
                      <div className="block">
                        <label className="text-sm text-orange font-medium mr-2">
                          Country:
                        </label>
                        <br />
                        <label className="text-sm">
                          {state.job?.jobAddressInformation?.country ?? "NA"}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="grid grid-cols-3 w-full h-4/6 gap-3">
                      <div className="block">
                        <label className="text-sm text-orange font-medium mr-2">
                          Timezone:
                        </label>
                        <br />
                        <label className="text-sm">
                          {state.job?.jobAddressInformation?.timeZone ?? "NA"}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="pt-3">
                    <label className="text-base text-slate-300 font-medium">
                      Job Details
                    </label>
                  </div>
                  <div>
                    <div className="grid grid-cols-3 w-full h-4/6 gap-3">
                      <div className="block">
                        <label className="text-sm text-orange font-medium mr-2">
                          Job Status:
                        </label>
                        <br />
                        <label className="text-sm">
                          {item?.status ?? "NA"}
                        </label>
                      </div>
                      <div className="block">
                        <label className="text-sm text-orange font-medium mr-2">
                          Target Completion Date:
                        </label>
                        <br />
                        <label className="text-sm">
                          {state.job?.targetCompletionDate?.toString() ?? "NA"}
                        </label>
                      </div>
                      <div className="block">
                        <label className="text-sm text-orange font-medium mr-2">
                          Estimated Payment:
                        </label>
                        <br />
                        <label className="text-sm">
                          {state.job?.estimatedPayment ?? "NA"}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="grid grid-cols-3 w-full h-4/6 gap-3">
                      <div className="block">
                        <label className="text-sm text-orange font-medium mr-2">
                          Other Details:
                        </label>
                        <br />
                        <label className="text-sm">
                          {state.job?.otherDetails ?? "NA"}
                        </label>
                      </div>
                      <div className="block">
                        <label className="text-sm text-orange font-medium mr-2">
                          On-site Instructions:
                        </label>
                        <br />
                        <label className="text-sm">
                          {state.job?.onSiteInstructions ?? "NA"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pt-3 col-span-6">
                <div className="flex gap-3">
                  <div className="my-auto basis-22">Manual Invite:</div>
                  <div className="my-auto basis-6/12">
                    <Controller
                      control={control}
                      name="selectedCandidate"
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <CustomDropdownMultiSelect
                          onChange={(value) => {
                            field.onChange(value);
                          }}
                          options={svState?.svListByCountry?.map(
                            (item: {
                              id: number;
                              name: string;
                              country: string;
                              lastLogin: string;
                              rating: number;
                              profilePictureUrl: string;
                            }) => {
                              return {
                                label: item.name,
                                value: item.id,
                                disabled: false,
                              };
                            }
                          )}
                          value={field.value}
                        />
                      )}
                    />
                  </div>
                  <div className="my-auto basis-2/12">
                    <Button
                      children={["Invite"]}
                      variant={"primaryorange"}
                      type="button"
                      onClick={manualRelease}
                    />
                  </div>
                </div>
              </div>
              {error1 && (
                <div className="pt-3 col-span-6">
                  <div className="flex gap-3">
                    <div className="my-auto basis-24"></div>
                    <div className="my-auto basis-6/12">
                      <p className="text-red text-xs">
                        Please select at least one SV
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="pt-3 col-span-6">
                <div className="flex gap-3">
                  <div className="my-auto basis-22">Qualified SV Radius:</div>
                  <div className="my-auto basis-6/12">
                    <Controller
                      control={control}
                      name="selectedRadius"
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <CustomDropdownSingleSelect
                          onChange={(value) => {
                            field.onChange(value);
                          }}
                          options={[
                            { label: "10km", value: 10, disabled: false },
                            { label: "20km", value: 20, disabled: false },
                            { label: "30km", value: 30, disabled: false },
                            { label: "40km", value: 40, disabled: false },
                            { label: "50km", value: 50, disabled: false },
                          ]}
                          value={field.value}
                        />
                      )}
                    />
                  </div>
                  <div className="my-auto basis-2/12">
                    <Button
                      children={["Release to Public"]}
                      variant={"primarygreen"}
                      type="button"
                      onClick={autoRelease}
                    />
                  </div>
                </div>
              </div>
              {error2 && (
                <div className="pt-3 col-span-6">
                  <div className="flex gap-3">
                    <div className="my-auto basis-32"></div>
                    <div className="my-auto basis-6/12">
                      <p className="text-red text-xs">
                        Please select the radius range
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default InviteSVModal;
