import React, { useState } from "react";

interface CheckboxProps {
  labelPosition: "left" | "right";
  label?: string;
  value: any;
  checked?: boolean;
  onChange: ({ status, id }: any) => void;
  disabled?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  labelPosition = "right",
  label,
  value,
  onChange,
  checked,
  disabled,
}) => {
  const [interacted, setInteracted] = useState(false);

  let checkboxClassName =
    "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600";
  let labelClassName =
    "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300";

  if (labelPosition === "left") {
    checkboxClassName =
      "ml-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600";
    labelClassName = "text-sm font-medium text-gray-900 dark:text-gray-300";
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInteracted(true); // Set as interacted when the user interacts with the checkbox
    const newValue = event.target.checked;
    const newId = event.target.value;
    onChange({ status: newValue, id: newId });
  };

  return (
    <div className="flex items-center mb-0">
      {labelPosition === "left" && (
        <label className={labelClassName}>{label}</label>
      )}
      <input
        disabled={disabled}
        onChange={handleChange}
        checked={checked}
        type="checkbox"
        value={value || ""}
        className={checkboxClassName}
      />
      {labelPosition === "right" && (
        <label className={labelClassName}>{label}</label>
      )}
    </div>
  );
};

export default Checkbox;
