import clsx from "clsx";
import { ButtonHTMLAttributes, ReactNode } from "react";

/**
 * A customizable button component that can be used for different scenarios.
 * Please add a new variant if there is additional styles that need to be implemented
 *
 * @param children - takes react node as an argument and renders as content
 * @param variant - defines the type of button to render
 * @param props - additional functionality that can be provided to the button
 * @returns
 */
interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  variant:
    | "primary"
    | "outline"
    | "bigprimary"
    | "bigoutline"
    | "smprimary"
    | "add"
    | "deletebtn"
    | "login"
    | "primaryorange"
    | "primarygreen"
    | "outlineborderless"
    | "underlineborderless"
    | "edit"
    | "download"
    | "primaryorange-disabled"
    | "primarycancel"
    | "danger";
}

const CustomButton = ({ children, variant, ...props }: IButtonProps) => {
  return (
    <button
      data-testid="customButton"
      className={clsx(
        variant === "primary"
          ? "px-24 py-3 font-semibold text-sm bg-red text-white rounded-md shadow-sm hover:bg-red-hover cursor-pointer"
          : "",
        variant === "outline"
          ? "px-8 py-2 font-semibold text-sm bg-white text-black rounded-md shadow-sm border hover:bg-white-hover cursor-pointer"
          : "",
        variant === "bigprimary"
          ? "bg-dark-gray text-white py-4 px-8 rounded-md inline-flex items-center hover:bg-dark-gray-hover cursor-pointer"
          : "",
        variant === "deletebtn"
          ? "border-dark-gray border-[1px] text-dark-gray py-4 px-8 rounded-md inline-flex items-center hover:bg-dark-gray-hover hover:text-white cursor-pointer"
          : "",
        variant === "bigoutline"
          ? "bg-white text-dark-gray py-4 px-8 rounded-md inline-flex items-center border shadow font-medium hover:bg-white-hover cursor-pointer"
          : "",
        variant === "smprimary"
          ? "flex items-center px-8 py-2 font-semibold text-sm bg-dark-gray text-white rounded-md shadow-sm border border-dark-gray hover:bg-dark-gray-hover cursor-pointer"
          : "",
        variant === "add"
          ? "px-4 py-2 text-sm bg-dark-gray text-white rounded-md inline-flex items-center hover:bg-dark-gray-hover cursor-pointer"
          : "",
        variant === "login"
          ? "px-8 py-2 font-semibold text-sm text-black rounded-md shadow-sm border hover:bg-white-hover cursor-pointer"
          : "",
        variant === "primaryorange"
          ? "block w-full py-2 font-semibold bg-orange text-sm text-white rounded-md shadow-sm border hover:bg-green-100-hover cursor-pointer"
          : "",
        variant === "primaryorange-disabled"
          ? "block w-full py-2 font-semibold bg-orange opacity-65 text-sm text-white rounded-md shadow-sm border hover:bg-green-100-hover cursor-not-allowed"
          : "",
        variant === "primarygreen"
          ? "block w-full py-2 font-semibold bg-light-green-100 text-sm text-white rounded-md shadow-sm border hover:bg-green-100-hover cursor-pointer"
          : "",
        variant === "primarycancel"
          ? "block w-full py-2 font-semibold text-sm text-black rounded-md shadow-sm border hover:bg-green-100-hover cursor-pointer"
          : "",
        variant === "outlineborderless"
          ? "block w-full py-2 font-semibold bg-red text-sm text-white rounded-md cursor-pointer"
          : "",
        variant === "underlineborderless"
          ? "block w-full py-2 font-semibold bg-white text-sm text-orange rounded-md cursor-pointer underline"
          : "",
        variant === "edit"
          ? "w-full py-2 font-semibold bg-white text-sm text-gray rounded-md cursor-pointer border"
          : "",
        variant === "download"
          ? "w-full text-left bg-white text-sm text-orange cursor-pointer underline"
          : "",
        variant === "danger"
          ? "px-8 py-2 font-semibold text-sm bg-red text-white rounded-md shadow-sm border hover:bg-red-hover cursor-pointer"
          : ""
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default CustomButton;
