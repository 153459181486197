import { IInitialStateHome } from "../types/GenericHTTPType";
import { IHomePageData } from "../types/HomePageType";
import { AxiosClientUtil } from "../utils/httpUtils/AxiosClientUtil";
import { useCallback, useReducer } from "react";

export const initialState: IInitialStateHome = {
  isLoading: false,
  homePageData: [],
  jobListStatus: undefined,
};

const reducer = (
  state: IInitialStateHome,
  action: { type: string; payload?: IHomePageData | boolean | [] | any }
) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, isLoading: action.payload };
    case "GET_HOME_DATA":
      return { ...state, homePageData: action.payload };
    case "GET_JOB_LIST_STATUS":
      return { ...state, jobListStatus: action.payload };
    default:
      return state;
  }
};

export function useHomepage() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const memoizedDispatch = useCallback(dispatch, [dispatch]);

  const memoizedJobActions = {
    getHomePageData: useCallback(
      async ({ filterParams }: any) => {
        const filters = {
          DateFrom: filterParams?.DateFrom,
          DateTo: filterParams?.DateTo,
        };
        memoizedDispatch({ type: "SET_LOADING", payload: true });
        try {
          const response = await AxiosClientUtil.get("/Home", {
            params: {
              ...filters,
            },
          });
          memoizedDispatch({
            type: "GET_HOME_DATA",
            payload: response?.data,
          });
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    getStatusCount: useCallback(async () => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await AxiosClientUtil.get(
            `/Home/jobs/count-by-status`
          );
          memoizedDispatch({
            type: "GET_JOB_LIST_STATUS",
            payload: response?.data,
          });
          resolve(response?.data);
        } catch (err) {
          reject(err);
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      });
    }, [memoizedDispatch]),
  };

  return { state, dispatch, ...memoizedJobActions };
}
