import Cookies from "js-cookie";
import { ICookiePropType as CookiePropType } from "../types/CookiePropType";

export const getJWTCookieUtil = () => {
  return Cookies.get("jwtToken");
};

export const removeCookieUtil = (cookieName: string) => {
  Cookies.remove(cookieName);
};

export const setCookieUtil = ({ name, value, options }: CookiePropType) => {
  Cookies.set(name, value, options);
};
