import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import useOutsideClick from "../../hooks/useOutsideClick";

interface DropdownItem {
  id: number;
  name: string;
  children?: JSX.Element;
  proposedVisitingDate: string;
  proposedVisitingTime: string;
}

interface DropdownProps {
  id: string;
  title?: string;
  data: DropdownItem[];
  position?: "bottom-right" | "bottom-left" | "top-right" | "top-left";
  style?: string;
  selectedId?: number;
  onSelect?: (id: number) => void;
}

const DropdownWithMedia = ({
  id,
  title = "Please Select", //Default value
  data,
  position = "bottom-left", //Default value
  style,
  selectedId,
  onSelect,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [selectedItem, setSelectedItem] = useState<DropdownItem | undefined>(
    selectedId ? data?.find((item) => item.id === selectedId) : undefined
  );

  const handleChange = (item: DropdownItem) => {
    setSelectedItem(item);
    onSelect && onSelect(item.id);
    setIsOpen(false);
  };

  useEffect(() => {
    if (selectedId && data) {
      const newSelectedItem = data.find((item) => item.id === selectedId);
      newSelectedItem && setSelectedItem(newSelectedItem);
    } else {
      setSelectedItem(undefined);
    }
  }, [selectedId, data]);

  useEffect(() => {
    setSelectedItem(undefined);
  }, [id]);

  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false),
  });

  const dropdownClass = clsx(
    "absolute bg-gray-100 max-h-52 overflow-y-auto py-3 rounded shadow-md z-10 w-full ",

    position === "bottom-right" && " top-full right-0 mt-2",
    position === "bottom-left" && " top-full left-0 mt-2",
    position === "top-right" && " bottom-full right-0 mb-2",
    position === "top-left" && " bottom-full left-0 mb-2"
  );

  return (
    <div
      ref={dropdownRef}
      className="relative border-2 border-light-gray-100 p-1 w-full text-dark-gray shadow-sm ring-1 ring-inset ring-gray placeholder:text-gray-400"
    >
      <button
        id={id}
        aria-label="Toggle dropdown"
        aria-haspopup="true"
        aria-expanded={isOpen}
        value={selectedId}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className={`flex justify-between items-center gap-5 rounded w-full py-2 px-4 bg-blue-500 text-black ${style}`}
      >
        <FontAwesomeIcon
          icon={faChevronDown}
          className={
            "transform duration-500 ease-in-out absolute right-0 top-0 bottom-0 m-auto mr-2 w-5 z-20 " +
            (isOpen ? "rotate-180" : "rotate-0")
          }
        />
        <span className="text-black">{selectedItem?.name ?? title}</span>
      </button>
      {/* Open */}
      {isOpen && (
        <div aria-label="Dropdown menu" className={dropdownClass}>
          <ul
            role="menu"
            aria-labelledby={id}
            aria-orientation="vertical"
            className="leading-10  w-100"
          >
            {data?.map((item) => {
              return (
                <li
                  key={item.id}
                  onClick={() => handleChange(item)}
                  className={
                    "flex flex-wrap items-center cursor-pointer hover:bg-light-gray-300 cursor-pointer px-3 w-100 " +
                    (selectedItem?.id === item.id ? "bg-gray-300" : "")
                  }
                >
                  <div className="flex flex-col">
                    <span>
                      {"SV Name: "}
                      <span className="font-semibold">{item.name}</span>
                    </span>
                    <span>
                      {"Proposed Date Time: "}
                      <span className="font-semibold">
                        {item?.proposedVisitingDate !== null &&
                        item?.proposedVisitingTime !== null ? (
                          `${item?.proposedVisitingDate} ${item?.proposedVisitingTime}`
                        ) : (
                          <span className="text-light-red-100">
                            {"No Data Was Found"}
                          </span>
                        )}
                      </span>
                    </span>
                  </div>

                  {item.children}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownWithMedia;
