const Top10HighestWithdrawHomePageFeature = (data: any) => {
  const topHighestWithdrawByCountry =
    data?.data?.topHighestWithdrawByCountry || [];

  return (
    <div className="top-countries-wrapper px-6 pt-3">
      <div className="top-countries-content-wrapper">
        <div className="top-countries-title-wrapper mb-5 flex justify-start">
          <span className="shrink text-base font-black">
            Top 10 Highest Withdraw by Countries
          </span>
        </div>
        <div className="top-countries-list-wrapper flex flex-col gap-3 text-sm">
          {topHighestWithdrawByCountry.map((country: any, index: number) => (
            <div
              key={index}
              className="country border-b-2 border-grey-900 flex pb-1 shrink"
            >
              <span className="basis-5/6 font-semibold shrink">
                {country?.name || "Country Name"}
              </span>
              <span className="basis-1/6 font-light shrink">
                {country?.count || "Country Count"}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Top10HighestWithdrawHomePageFeature;
