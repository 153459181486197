import React, { useEffect, useState } from "react";

interface TextAreaInputProps {
  onChange: (value: string) => void;
  value: string | undefined;
  required?: boolean;
  validationMessages?: { [key in any]: any };
  disabled?: boolean;
  placeholder?: string;
}

const Textarea: React.FC<TextAreaInputProps> = ({
  onChange,
  value,
  required,
  validationMessages = { required: "This field is required" },
  disabled,
  placeholder = "the value is required",
}) => {
  const [inputValue, setInputValue] = useState(value || "");
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    if (validateInput(newValue)) {
      onChange(event.target.value);
    }
    if (newValue === "") {
      // this is to prevent a single character from being left behind when the user manually deletes the values
      onChange("");
    }
  };

  const [currentValidationMessage, setCurrentValidationMessage] = useState(
    required
      ? validationMessages.required
        ? validationMessages.required
        : required
        ? required
        : ""
      : ""
  );

  const validateInput = (newValue: string) => {
    let isValid = true;
    let message = "";
    if (required && newValue === "") {
      isValid = false;
      message = validationMessages.required;
    }
    setCurrentValidationMessage(message);

    return isValid;
  };

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  return (
    <>
      <textarea
        disabled={disabled}
        id="message"
        className="block w-full border border-light-gray-100 p-1.5 text-dark-gray shadow-sm ring-1 ring-inset ring-gray placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 disabled:bg-light-gray-100 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
        placeholder={placeholder}
        onChange={handleChange}
        value={value || ""}
        data-testid="text-area"
      ></textarea>
      {currentValidationMessage
        ? required &&
          !inputValue && (
            <p className="mt-2 text-red text-xs">
              {validationMessages?.required || "Field is required"}
            </p>
          )
        : currentValidationMessage &&
          !inputValue && (
            <p className="mt-2 text-red text-xs">
              {currentValidationMessage || "Field is Required"}
            </p>
          )}
    </>
  );
};

export default Textarea;
