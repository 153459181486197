import React from "react";
import { Radio } from "antd";
import type { RadioChangeEvent } from "antd";

interface RadioButton {
  displayText: string;
  fieldValue: boolean;
}

interface RadioButtonProps {
  value: boolean;
  radioButtonValues: RadioButton[];
  disabled: boolean;
  onChange: (e: boolean) => void;
}

const RadioButtonComponent: React.FC<RadioButtonProps> = ({
  value = true,
  radioButtonValues,
  disabled,
  onChange,
}) => {
  const handleChange = (e: RadioChangeEvent) => {
    onChange(e.target.value);
    if (value === undefined) {
      onChange(true);
    }
  };
  return (
    <Radio.Group
      onChange={handleChange}
      defaultValue={radioButtonValues.at(0)?.fieldValue}
      disabled={disabled}
      value={value}
    >
      {radioButtonValues.map((rbv: RadioButton, index: number) => {
        return (
          <Radio.Button value={rbv.fieldValue}>{rbv.displayText}</Radio.Button>
        );
      })}
    </Radio.Group>
  );
};

export default RadioButtonComponent;
