import { IBuildingNotFoundQuestionnaire } from "../../../types/JobType";

type IBuildingNotFoundQuestionnaireDataRow = {
  no: number;
  question: string;
  answer: string;
};

const buildingNotFoundDataGenerator = ({
  buildingNotFoundData,
}: {
  buildingNotFoundData: IBuildingNotFoundQuestionnaire;
}) => {
  const formattedBuildingNotFoundQuestionnnaireData: IBuildingNotFoundQuestionnaireDataRow[] =
    [
      {
        no: 1,
        question: "Interviewee Name And Other Information",
        answer:
          buildingNotFoundData?.intervieweeNameAndOtherInformation === null ||
          buildingNotFoundData?.intervieweeNameAndOtherInformation === "" ||
          buildingNotFoundData?.intervieweeNameAndOtherInformation === undefined
            ? "NA"
            : buildingNotFoundData?.intervieweeNameAndOtherInformation,
      },
      {
        no: 2,
        question: "Did Neighborhood Know The Company Name",
        answer:
          buildingNotFoundData?.didNeighborhoodKnowTheCompanyName === null ||
          buildingNotFoundData?.didNeighborhoodKnowTheCompanyName === "" ||
          buildingNotFoundData?.didNeighborhoodKnowTheCompanyName === undefined
            ? "NA"
            : buildingNotFoundData?.didNeighborhoodKnowTheCompanyName === "true"
            ? "Yes"
            : "No",
      },
      {
        no: 3,
        question: "Did Neighborhood Know The Company Contact Person",
        answer:
          buildingNotFoundData?.didNeighborhoodKnowTheCompanyContactPerson ===
            null ||
          buildingNotFoundData?.didNeighborhoodKnowTheCompanyContactPerson ===
            "" ||
          buildingNotFoundData?.didNeighborhoodKnowTheCompanyContactPerson ===
            undefined
            ? "NA"
            : buildingNotFoundData?.didNeighborhoodKnowTheCompanyContactPerson ===
              "true"
            ? "Yes"
            : "No",
      },
      {
        no: 4,
        question: "Did Neighborhood Direct Sv To The Target Address",
        answer:
          buildingNotFoundData?.didNeighborhoodDirectSvToTheTargetAddress ===
            null ||
          buildingNotFoundData?.didNeighborhoodDirectSvToTheTargetAddress ===
            "" ||
          buildingNotFoundData?.didNeighborhoodDirectSvToTheTargetAddress ===
            undefined
            ? "NA"
            : buildingNotFoundData?.didNeighborhoodDirectSvToTheTargetAddress ===
              "true"
            ? "Yes"
            : "No",
      },
    ];
  return formattedBuildingNotFoundQuestionnnaireData;
};

export default buildingNotFoundDataGenerator;
