/**
 * A helper method that Converts a generic object into an array of strings.
 * The object can be a single string, a JSON object or an array of strings
 *
 * @param attachment - The generic input object
 * @returns An array of strings extracted from the input object.
 */
export const convertGenericObjectIntoArray = (attachment: any) => {
  const attachmentArray: string[] = [];
  if (attachment !== null && typeof attachment === "object") {
    const retrievedAttachmentValues = Object.values(attachment);
    retrievedAttachmentValues.forEach((attachmentValue: any) => {
      if (typeof attachmentValue === "string") {
        // since the attachment string can have multiple urls appended with a "," separating them
        // therefore, we need to split the strings and add all the urls into the array
        const parts = attachmentValue?.split(",");
        attachmentArray.push(...parts);
      }
      // IMPORTANT: this ignores the output if it is an object
    });
  } else if (typeof attachment === "string") {
    attachmentArray.push(attachment);
  } else if (Array.isArray(attachment)) {
    attachmentArray.push(...attachment);
  }

  return attachmentArray;
};

export const retrieveNameOfFileFromJSONStrategy = (jsonObject: Object) => {
  let namesFromJson: string[] = [];
  namesFromJson = Object.entries(jsonObject)
    .filter(([k, v]) => v !== null)
    .map(([k, v]) => {
      return k;
    })
    .filter((value) => value.includes("Url"));
  return namesFromJson;
};

export const isStringAValidFileType = (stringValue: string) => {
  return (
    stringValue.includes(".jpg") ||
    stringValue.includes(".pdf") ||
    stringValue.includes(".jpeg") ||
    stringValue.includes(".mp4") ||
    stringValue.includes(".mp3") ||
    stringValue.includes(".hiec") ||
    stringValue.includes(".png") ||
    stringValue.includes(".gif") ||
    stringValue.includes(".mov")
  );
};
