const ServerErrorConstants = [
  "Cannot read properties of undefined",
  "INVALID VIEW MODEL",
  "GLOBAL EXCEPTION",
  "DUPLICATE NAME",
  // "INVALID PASSWORD"
  //"NOT FOUND"
  "UNPROCESSABLE ENTITY",
  "ALREADY EXISTS",
  // "NOT MODIFIED"
  "CONFLICT",
  "BAD REQUEST",
  // "FORBIDDEN"
  "FAILED",
  "ACCESS DENIED",
  "UNAUTHORIZED",
];

/**
 * Generates a user-friendly error message based on the given server error message.
 * If the server error message matches any of the predefined constants,
 * the function returns a generic error message.
 * Otherwise, it returns the original server message.
 *
 * @param message - The server error message to be processed.
 * @returns A user-friendly error message, or not it just returns the original message
 */
export const generateUserErrorMessage = (message: string) => {
  if (
    ServerErrorConstants.find((em) =>
      message.toLowerCase().includes(em.toLowerCase())
    ) !== undefined
  ) {
    const userFriendlyMessage = "Something went wrong. Please try again later.";
    return userFriendlyMessage;
  } else {
    return message;
  }
};
