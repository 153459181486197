import React, { useEffect, useState } from "react";
import { IInitialStatePerformanceDetails } from "../../types/GenericHTTPType";
import {
  IPerformanceYearly,
  IPerformanceMonthly,
  Rating,
} from "../../types/PerformanceType";
import PerformanceDetailsMonthlyRatingTable from "../performanceDetailsMonthlyRatingTable";

const PerformanceDetailsMonthlyTable = React.forwardRef(
  ({
    id,
    retrievedPerformanceData,
  }: {
    id: any;
    retrievedPerformanceData: any;
  }) => {
    // const { ref } = props;
    const [performanceMonthlyResult, setPerformanceMonthlyResults] =
      useState<IInitialStatePerformanceDetails>();
    const [performanceData, setPerformanceData] =
      useState<IPerformanceYearly>();

    useEffect(() => {
      // const { performanceData, ref } = props;
      setPerformanceData(retrievedPerformanceData);
      const performanceMonthly = retrievedPerformanceData?.months;
      const monthlyDataState: IInitialStatePerformanceDetails = {
        createdCount: [],
        approvedAbove48HoursCount: [],
        notCompletedAbove48HoursCount: [],
        assignedWithin24HoursCount: [],
        assignedBetween24And48HoursCount: [],
        assignedAbove48HoursCount: [],
        submittedWithin24HoursCount: [],
        submittedBetween24And48HoursCount: [],
        submittedAbove48HoursCount: [],
        rating: [],
      };
      performanceMonthly?.forEach((p: IPerformanceMonthly) => {
        monthlyDataState.createdCount.push(p.createdCount ?? 0);
        monthlyDataState.approvedAbove48HoursCount.push(
          p.approvedAbove48HoursCount ?? 0
        );
        monthlyDataState.notCompletedAbove48HoursCount.push(
          p.notCompletedAbove48HoursCount ?? 0
        );
        monthlyDataState.assignedWithin24HoursCount.push(
          p.assignedWithin24HoursCount ?? 0
        );
        monthlyDataState.assignedBetween24And48HoursCount.push(
          p.assignedBetween24And48HoursCount ?? 0
        );
        monthlyDataState.assignedAbove48HoursCount.push(
          p.assignedAbove48HoursCount ?? 0
        );
        monthlyDataState.submittedWithin24HoursCount.push(
          p.submittedWithin24HoursCount ?? 0
        );
        monthlyDataState.submittedBetween24And48HoursCount.push(
          p.submittedBetween24And48HoursCount ?? 0
        );
        monthlyDataState.submittedAbove48HoursCount.push(
          p.submittedAbove48HoursCount ?? 0
        );
        monthlyDataState.rating.push(p.rating ?? 0);
      });

      setPerformanceMonthlyResults(monthlyDataState);
    }, [retrievedPerformanceData]);

    return (
      <div>
        <table className="w-full text-sm text-left text-black z-0" id={id}>
          <thead className="text-xs border border-light-gray-100 bg-light-gray-100">
            <tr className="border border-light-gray-100">
              <th className="px-6 py-3 w-80">{performanceData?.year}</th>
              <th className="px-6 py-3">Jan</th>
              <th className="px-6 py-3">Feb</th>
              <th className="px-6 py-3">Mar</th>
              <th className="px-6 py-3">April</th>
              <th className="px-6 py-3">May</th>
              <th className="px-6 py-3">June</th>
              <th className="px-6 py-3">July</th>
              <th className="px-6 py-3">Aug</th>
              <th className="px-6 py-3">Sept</th>
              <th className="px-6 py-3">Oct</th>
              <th className="px-6 py-3">Nov</th>
              <th className="px-6 py-3">Dec</th>
            </tr>
          </thead>
          <tbody className="border border-light-gray-100">
            <tr className="bg-white border-b border-light-gray-100">
              <td className="px-6 py-4 pr-0 w-1 font-bold border border-light-gray-100">
                No. of jobs posted/created:
              </td>
              {performanceMonthlyResult?.createdCount?.map((value, index) => {
                return (
                  <td
                    scope="row"
                    className="border border-light-gray-100 px-6 py-4 whitespace-nowrap"
                    key={"created" + index}
                  >
                    {value}
                  </td>
                );
              })}
            </tr>

            <tr className="bg-white border-b border-b-light-gray-100">
              <td className="px-6 py-4 pr-0 w-1 font-bold border border-light-gray-100">
                No. of jobs assigned within 24 hours:
              </td>
              {performanceMonthlyResult?.assignedWithin24HoursCount?.map(
                (value, index) => {
                  return (
                    <td
                      scope="row"
                      className="border border-light-gray-100 px-6 py-4 whitespace-nowrap"
                      key={"assignedWithin24hours" + index}
                    >
                      {value}
                    </td>
                  );
                }
              )}
            </tr>

            <tr className="bg-white border-b border-b-light-gray-100">
              <td className="px-6 py-4 pr-0 w-1 font-bold border border-light-gray-100">
                No. of jobs assigned 24-48 hours:
              </td>
              {performanceMonthlyResult?.assignedBetween24And48HoursCount?.map(
                (value, index) => {
                  return (
                    <td
                      scope="row"
                      className="border border-light-gray-100 px-6 py-4 whitespace-nowrap"
                      key={"assignedBetween24and48hours" + index}
                    >
                      {value}
                    </td>
                  );
                }
              )}
            </tr>

            <tr className="bg-white border-b border-b-light-gray-100">
              <td className="px-6 py-4 pr-0 w-1 font-bold border border-light-gray-100">
                No. of jobs assigned {">"} 48 Hours
              </td>
              {performanceMonthlyResult?.assignedAbove48HoursCount?.map(
                (value: any, index: number) => {
                  return (
                    <td
                      scope="row"
                      className=" border border-light-gray-100 px-6 py-4 whitespace-nowrap"
                      key={"assignedAbove48hours" + index}
                    >
                      {value}
                    </td>
                  );
                }
              )}
            </tr>

            <tr className="bg-white border-b border-b-light-gray-100">
              <td className="px-6 py-4 pr-0 w-1 font-bold border border-light-gray-100">
                {"No. of jobs outstanding (unassigned) > 48 hours"}
              </td>
              {performanceMonthlyResult?.assignedBetween24And48HoursCount?.map(
                (value, index) => {
                  return (
                    <td
                      scope="row"
                      className="border border-light-gray-100 px-6 py-4 whitespace-nowrap"
                      key={"submitted" + index}
                    >
                      {value}
                    </td>
                  );
                }
              )}
            </tr>

            <tr className="bg-white border-b border-b-light-gray-100">
              <td className="px-6 py-4 pr-0 w-1 font-bold border border-light-gray-100">
                No. of jobs completed within 24 hours:
              </td>
              {performanceMonthlyResult?.submittedWithin24HoursCount?.map(
                (value, index) => {
                  return (
                    <td
                      scope="row"
                      className="border border-light-gray-100 px-6 py-4 whitespace-nowrap"
                      key={"submittedWithin24hours" + index}
                    >
                      {value}
                    </td>
                  );
                }
              )}
            </tr>

            {/* <tr className="bg-white border-b border-b-light-gray-100">
              <td className="px-6 py-4 pr-0 w-1 font-bold border border-light-gray-100">
                No. of jobs completed within 36 hours:
              </td>
              {performanceMonthlyResult?.submittedBetween24And48HoursCount?.map(
                (value, index) => {
                  return (
                    <td
                      scope="row"
                      className="px-6 py-4 whitespace-nowrap"
                      key={"completed" + index}
                    >
                      {value}
                    </td>
                  );
                }
              )}
            </tr> */}

            <tr className="bg-white border-b border-b-light-gray-100">
              <td className="px-6 py-4 pr-0 w-1 font-bold border border-light-gray-100">
                No. of jobs completed within 24-48 hours:{" "}
              </td>
              {performanceMonthlyResult?.submittedBetween24And48HoursCount?.map(
                (value, index) => {
                  return (
                    <td
                      scope="row"
                      className="border border-light-gray-100 px-6 py-4 whitespace-nowrap"
                      key={"completed" + index}
                    >
                      {value}
                    </td>
                  );
                }
              )}
            </tr>

            <tr className="bg-white border-b border-b-light-gray-100">
              <td className="px-6 py-4 pr-0 w-1 font-bold border border-light-gray-100">
                {"No. of job pending delivery by SV > 48 hours"}
              </td>
              {performanceMonthlyResult?.submittedAbove48HoursCount?.map(
                (value, index) => {
                  return (
                    <td
                      scope="row"
                      className="border border-light-gray-100 px-6 py-4 whitespace-nowrap"
                      key={"totaljob" + index}
                    >
                      {value}
                    </td>
                  );
                }
              )}
            </tr>

            <tr className="bg-white border-b border-b-light-gray-100 p-0 m-0 border-collapse">
              <td className="w-1 font-bold p-0 m-0">
                <div className="grid grid-cols-2 p-0 m-0">
                  <div className="p-0 m-0 content-center  border border-light-gray-100 text-center">
                    Average Job Quality
                  </div>
                  <div className="p-0 m-0">
                    <div className="border-collapse">
                      <div className=" px-6 py-4 border border-light-gray-100">
                        Delivery
                      </div>
                      <div className=" px-6 py-4 border border-light-gray-100">
                        Quality
                      </div>
                      <div className=" px-6 py-4 border border-light-gray-100">
                        Responsiveness
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              {performanceMonthlyResult?.rating?.map((value: Rating, index) => {
                return (
                  <td className="p-0">
                    <PerformanceDetailsMonthlyRatingTable value={value} />
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
);

export default PerformanceDetailsMonthlyTable;
