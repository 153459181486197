import { IJobObservation, IQuestionaire } from "../../../types/JobType";

type IQuestionaireDataRow = {
  no: number;
  question: string;
  answer: string;
};

export const questionaireDataGenerator = ({
  questionaireData,
  observationData,
}: {
  questionaireData: IQuestionaire;
  observationData: IJobObservation;
}) => {
  const formattedQuestionaireData: IQuestionaireDataRow[] = [
    {
      no: 1,
      question: "What is the interviewee's full name & position?",
      answer:
        questionaireData?.whatAreTheIntervieweesFullNameAndPosition ?? "NA",
    },
    {
      no: 2,
      question:
        "What is the company's line of business and main products (e.g manufacturing tissue paper)?",
      answer: questionaireData?.whatAreTheMainProducts ?? "NA",
    },
    {
      no: 3,
      question:
        "What are the type of customers they serve (e.g Wholesalers, Manufacturers, Indivituals)? ",
      answer: questionaireData?.whatTypeOfCustomersTheyServe ?? "NA",
    },
    {
      no: 4,
      question:
        "What are type of suppliers they source (e.g Wholesalers, Manufacturers, Indivituals)? ",
      answer: questionaireData?.whatTypeOfSuppliersTheyHave ?? "NA",
    },
    {
      no: 5,
      question: "How many employees do they currently have?",
      answer: questionaireData?.howManyEmployessTheyCurrentlyHave ?? "NA",
    },
    {
      no: 6,
      question: "Operational hours (e.g. monday-friday, 9am-5pm)",
      answer: questionaireData?.operationalHour ?? "NA",
    },
    {
      no: 7,
      question: "Confirm the estimated property area (eg. 100sqm)",
      answer: questionaireData?.confirmTheEstimatedPropertyArea ?? "NA",
    },
    {
      no: 8,
      question: "Status of the property: owned/leased",
      answer: questionaireData?.statusProperty ?? "NA",
    },
    {
      no: 9,
      question: "Property Occupation Duration",
      answer: questionaireData?.propertyOccupationDuration ?? "NA",
    },
    {
      no: 10,
      question:
        "What is expectation in terms of sales (revenue) in the next quarter (Increased/unchanged/decreased)?",
      answer: questionaireData?.expectionOfSales ?? "NA",
    },
    {
      no: 11,
      question:
        "What is expectation in terms of employee size in the next quarter (Increased/unchanged/decreased)?",
      answer: questionaireData?.expectionOfEmployeeSize ?? "NA",
    },
    {
      no: 12,
      question:
        "On the scale of 1 to 5, with 5 being Most Optimistic, how do you read the business sentiment of your country’s economy in the next quarter?",
      answer: questionaireData?.businessSentimentNextQuarter ?? "NA",
    },
    {
      no: 13,
      question:
        "On the scale of 1 to 5, with 5 being Most Optimistic, how do you read the business sentiment of your industry next year?",
      answer: questionaireData?.businessSentimentNextYear ?? "NA",
    },
  ];

  const formattedObservationData: IQuestionaireDataRow[] = [
    {
      no: 14,
      question: "Does SC own the building?",
      answer: observationData?.doesScOwnsTheBuilding?.toString() ?? "NA",
    },
    {
      no: 15,
      question: "Is SC a tenant in a commercial building?",
      answer:
        observationData?.isScTenentInCommercialBuilding?.toString() ?? "NA",
    },
    {
      no: 16,
      question: "Where is the target location in?",
      answer: observationData?.isTheTargetLocation ?? "NA",
    },
    {
      no: 17,
      question: "Do you see SC's signage?",
      answer: observationData?.doSeeScSignage?.toString() ?? "NA",
    },
    {
      no: 18,
      question: "How many employees were working during the site visit?",
      answer:
        observationData?.howManyEmployeeWereWorkingDuringSiteVisit?.toString() ??
        "NA",
    },
    {
      no: 19,
      question: "Were there many people/vehicles accessing the SC's premise?",
      answer:
        observationData?.wereThereManyPeopleOrVehiclesAccessingScPremise?.toString() ??
        "NA",
    },
    {
      no: 20,
      question: "Is there any receptionist and security guard?",
      answer:
        observationData?.isThereAnyReceptionistAndSecurityGuard?.toString() ??
        "NA",
    },
    {
      no: 21,
      question:
        "Is there any sign of goods/cargos/stocks to be delivered/loaded/unloaded in front of SC's premise?",
      answer:
        observationData?.isThereAnySignOfGoodsOrCargosOrStocksToBeDeliveredOrLoadedOrUnloadedInFrontScPremise?.toString() ??
        "NA",
    },
    {
      no: 22,
      question:
        "Were there any boxes of goods/products displayed in SC's premise?",
      answer:
        observationData?.wereThereAnyBoxesOfGoodsOrProductsDisplayedInScPremise?.toString() ??
        "NA",
    },
    {
      no: 23,
      question: "Did you see any light and sound from SC's premise?",
      answer:
        observationData?.didYouSeeAnyLightAndSoundFromScPremise?.toString() ??
        "NA",
    },
    {
      no: 24,
      question: "Additional Questions:",
      answer: observationData?.others?.toString() ?? "NA",
    },
  ];

  return [...formattedQuestionaireData, ...formattedObservationData];
};
