import { Suspense, lazy, useEffect, useState } from "react";
import { Skeleton } from "antd";
import { totalSVRegisteredDummyData as state } from "../../../data/HomePageData";
const DonutChart = lazy(() => import("../../../components/chart"));

const TotalSVRegisteredHomePageFeature = (data: any) => {
  const [chartOptions, setChartOptions] = useState({
    ...state.options,
    labels: data?.data?.totalSvRegistered?.labels || [],
  });

  useEffect(() => {
    if (data?.data?.totalSvRegistered) {
      setChartOptions({
        ...state.options,
        labels: data?.data?.totalSvRegistered?.labels || [],
      });
    }
  }, [data]);

  // Add null checks to prevent accessing undefined properties
  const activeSeries = data?.data?.totalSvRegistered?.series[0] || 0;
  const inactiveSeries = data?.data?.totalSvRegistered?.series[1] || 0;

  return (
    <div className="px-4 flex shrink">
      <div className="flex flex-col pt-3">
        <div className="text-center basis-1/6">
          <span className="text-lg font-black">Total SV Registered</span>
        </div>
        <div className="basis-1/2">
          <Suspense fallback={<Skeleton active />}>
            <DonutChart
              type="donut"
              options={chartOptions}
              series={data?.data?.totalSvRegistered?.series || []}
              labels={data?.data?.totalSvRegistered?.labels || []}
            />
          </Suspense>
        </div>
        <div className="pb-5 pt-5 basis-2/6">
          <div className="w-full text-start mb-2">
            <div className="text-3xl">{activeSeries}</div>
            <div>Active Site Verifiers</div>
          </div>

          <div className="w-full text-start mb-2">
            <div className="text-3xl">{inactiveSeries}</div>
            <div>Inactive Site Verifiers</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalSVRegisteredHomePageFeature;
