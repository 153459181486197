import axios from "axios";
import HTTPErrorHandlingUtil from "./HTTPErrorHandlingUtil";
import { getJWTCookieUtil } from "../CookiesUtil";

const URL =
  process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_PROD_URL
    : process.env.REACT_APP_ENV === "staging"
    ? process.env.REACT_APP_STAGING_URL
    : process.env.REACT_APP_DEV_URL;

export const AxiosClientUtil = axios.create({
  baseURL: URL,
  // timeout: 5000,
  headers: {
    "Content-Type": "application/json, application/json-patch+json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
    "Access-Control-Allow-Headers":
      "X-Requested-With, content-type, Authorization",
    "Access-Control-Allow-Credentials": "true",
    Accept: "*/*",
  },
});

AxiosClientUtil.interceptors.request.use(
  async function (request) {
    const authorization = getJWTCookieUtil();

    if (authorization) {
      request.headers.Authorization = `Bearer ${authorization}`;
    }
    return request;
  },
  (error) => {
    HTTPErrorHandlingUtil(error);
    return Promise.reject(error);
  }
);

AxiosClientUtil.interceptors.response?.use(
  async function (response) {
    return response;
  },
  async function (error) {
    HTTPErrorHandlingUtil(error);
    return error.response;
  }
);
