import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

//this route guard will stop ppl from navigating to homepage if not logged in,
//similarly will stop ppl from navigating to login page if logged in
const RouteGuard = () => {
  const { checkIsLogin } = useAuth();

  return checkIsLogin() ? <Outlet /> : <Navigate to="/login" />;
};

export default RouteGuard;
