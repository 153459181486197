import { Suspense, lazy, useEffect, useState } from "react";
import { totalSVRegisteredDummyData as state } from "../../../data/HomePageData";
import { Skeleton } from "antd";
import DateRange from "../../../components/dateRange";
import React from "react";
const DonutChart = lazy(() => import("../../../components/chart"));

const ProgressHomePageFeature = ({
  data,
  setHomePageDataParams,
}: {
  data: any;
  setHomePageDataParams: React.Dispatch<any>;
}) => {
  const [chartOptions, setChartOptions] = useState({
    ...state.options,
    labels: data?.totalSvRegistered?.labels || [],
  });

  useEffect(() => {
    if (data?.progress) {
      setChartOptions({
        ...state.options,
        labels: data?.progress?.labels || [],
      });
    }
  }, [data]);

  const retrieveDateFromProgress = (e: any) => {
    setHomePageDataParams({
      DateFrom: e[0].$d,
      DateTo: e[1].$d,
    });
  };

  // Add null checks to prevent accessing undefined properties
  const availableSeries = data?.progress?.series[0] || 0;
  const onGoingSeries = data?.progress?.series[1] || 0;
  const completedSeries = data?.progress?.series[2] || 0;

  return (
    <div className="px-6 pt-3 pb-5 flex flex-col">
      <div className="flex flex-col shrink flex-wrap pb-5">
        <div className="pb-4">
          <span className="text-lg font-black">Progress</span>
        </div>
        <div className="flex">
          <div className="basis-1/2 flex flex-col gap-3">
            <div className="shrink">
              <span className="text-5xl">{data?.progress?.total}</span>
              <br />
              <span className="text-lg">Total</span>
            </div>
            <div className="border-t-2 pt-2 border-grey-900 shrink">
              <span className="text-5xl">{availableSeries}</span>
              <br />
              <span className="text-sm">Available</span>
            </div>
            <div className="shrink">
              <span className="text-5xl">{onGoingSeries}</span>
              <br />
              <span className="text-sm">On-Going</span>
            </div>
            <div className="shrink">
              <span className="text-5xl">{completedSeries}</span>
              <br />
              <span className="text-sm">Completed</span>
            </div>
          </div>
          <div className="w-5/12">
            <Suspense fallback={<Skeleton active />}>
              <DonutChart
                type="donut"
                options={chartOptions}
                series={data?.progress?.series || []}
                labels={data?.progress?.labels || []}
              />
            </Suspense>
          </div>
        </div>
      </div>
      <div className="text-center grid grid-cols-4">
        <div className="col-start-2 col-span-2">
          <DateRange onChange={retrieveDateFromProgress} />
        </div>
      </div>
    </div>
  );
};

export default ProgressHomePageFeature;
