export function removeWhiteSpaceAndReplaceWithDash(
  stringValue: string,
  fallbackValue: string
) {
  return stringValue?.trim()?.replace(/\s/g, "-") ?? fallbackValue;
}

export function replaceWordsWithRegularExpression(
  stringValue: string,
  stringToBeReplaced: string,
  replacementString: string
) {
  let regExp = new RegExp(stringToBeReplaced, "gi");
  return stringValue?.trim()?.replace(regExp, replacementString);
}

export function capitalizeFirstletter(stringValue: string) {
  return stringValue.charAt(0).toUpperCase() + stringValue.slice(1);
}

/**
 * Convert string to camelCase text.
 */
function camelCase(stringValue: string) {
  stringValue = replaceAccents(stringValue);
  stringValue = removeNonWord(stringValue)
    .replace(/\-/g, " ") //convert all hyphens to spaces
    .replace(/\s[a-z]/g, upperCase) //convert first char of each word to UPPERCASE
    .replace(/\s+/g, "") //remove spaces
    .replace(/^[A-Z]/g, lowerCase); //convert first char to lowercase
  return stringValue;
}

/**
 * "Safer" String.toLowerCase()
 */
function lowerCase(stringValue: string) {
  return stringValue.toLowerCase();
}

/**
 * "Safer" String.toUpperCase()
 */
function upperCase(stringValue: string) {
  return stringValue.toUpperCase();
}

/**
 * Remove non-word chars.
 */
function removeNonWord(stringValue: string) {
  return stringValue.replace(/[^0-9a-zA-Z\xC0-\xFF \-]/g, "");
}

/**
 * Replaces all accented chars with regular ones
 */
function replaceAccents(stringValue: string) {
  // verifies if the String has accents and replace them
  if (stringValue.search(/[\xC0-\xFF]/g) > -1) {
    stringValue = stringValue
      .replace(/[\xC0-\xC5]/g, "A")
      .replace(/[\xC6]/g, "AE")
      .replace(/[\xC7]/g, "C")
      .replace(/[\xC8-\xCB]/g, "E")
      .replace(/[\xCC-\xCF]/g, "I")
      .replace(/[\xD0]/g, "D")
      .replace(/[\xD1]/g, "N")
      .replace(/[\xD2-\xD6\xD8]/g, "O")
      .replace(/[\xD9-\xDC]/g, "U")
      .replace(/[\xDD]/g, "Y")
      .replace(/[\xDE]/g, "P")
      .replace(/[\xE0-\xE5]/g, "a")
      .replace(/[\xE6]/g, "ae")
      .replace(/[\xE7]/g, "c")
      .replace(/[\xE8-\xEB]/g, "e")
      .replace(/[\xEC-\xEF]/g, "i")
      .replace(/[\xF1]/g, "n")
      .replace(/[\xF2-\xF6\xF8]/g, "o")
      .replace(/[\xF9-\xFC]/g, "u")
      .replace(/[\xFE]/g, "p")
      .replace(/[\xFD\xFF]/g, "y");
  }

  return stringValue;
}

export function splitStringByDelimiter(stringValue: string, delimiter: string) {
  return stringValue.split(delimiter);
}
