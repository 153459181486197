import React, { Dispatch, useEffect } from "react";
import CustomModal from "../modal";
import Button from "../button";
import DropdownInput from "../dropdown";
import Textbox from "../textbox";
import { Controller, useForm } from "react-hook-form";
import useAdministration from "../../hooks/useAdministration";
import TextboxWithViewButton from "../textboxWithViewButton";

const AdministrationUserModal = ({
  userId,
  showModal,
  setShowModal,
  refreshGrid,
  setShowAlertModal,
  updateAccess,
}: {
  userId: number;
  updateAccess: boolean;
  showModal: boolean;
  setShowModal: Dispatch<React.SetStateAction<boolean>>;
  refreshGrid: () => void;
  setShowAlertModal: ({
    title,
    message,
  }: {
    title: string;
    message: string;
  }) => void;
}) => {
  const {
    getRolesDropDownList,
    getCountriesDropDownList,
    getUserDetailsById,
    updateExistingUser,
    state,
  } = useAdministration();

  useEffect(() => {
    getCountriesDropDownList();
    getRolesDropDownList();
  }, []);

  useEffect(() => {
    getUserDetailsById(userId).then((user: any) => {
      reset({
        name: user?.name,
        email: user?.email,
        phoneNumber: user?.phoneNumber,
        companyCountry: user?.userCompanyInformation?.companyCountry,
        password: "",
        roleId: user?.role?.id,
        department: user?.userCompanyInformation?.department,
        companyName: user?.userCompanyInformation?.companyName,
      });
    });
  }, [userId]);

  const { handleSubmit, control, formState, reset, getValues } = useForm();

  const onSubmit = async (data: any) => {
    const requiredFields = ["name", "password", "email", "roleId"];
    const values = getValues();
    const isAnyFieldEmpty = requiredFields.some((field) => !values[field]);

    if (isAnyFieldEmpty) {
      return;
    }

    const res: any = await updateExistingUser(userId, { ...data });
    if (res.status === 200) {
      setShowModal(false);
      refreshGrid();
      setShowAlertModal({
        title: "Notification",
        message: "User updated successfully",
      });
    } else {
      setShowAlertModal({
        title: "Notification",
        message: res.data.message,
      });
    }
  };

  return (
    <CustomModal
      width={"60%"}
      title={""}
      open={showModal}
      onOk={() => {}}
      onCancel={() => {
        setShowModal(false);
      }}
      footer={null}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="border-gray p-4">
          <div>
            <div className="grid grid-cols-2 gap-4 bg-white">
              <div>
                <label className="text-sm text-slate-300" htmlFor="name">
                  Name
                </label>
                <Controller
                  rules={{ required: "This field is required" }}
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Textbox
                      required
                      validationMessages={{
                        required: formState.errors?.name?.message || " ",
                      }}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </div>

              <div>
                <label className="text-sm text-slate-300" htmlFor="password">
                  Password
                </label>
                <Controller
                  name="password"
                  rules={{ required: "This field is required" }}
                  control={control}
                  render={({ field }) => (
                    <TextboxWithViewButton
                      required
                      validationMessages={{
                        required: formState.errors?.password?.message || " ",
                      }}
                      value={field.value}
                      onChange={field.onChange}
                      name={"newPassword"}
                    />
                  )}
                />
              </div>

              <div>
                <label className="text-sm text-slate-300" htmlFor="email">
                  Email
                </label>
                <Controller
                  name="email"
                  rules={{ required: "This field is required" }}
                  control={control}
                  render={({ field }) => (
                    <Textbox
                      required
                      validationMessages={{
                        required: formState.errors?.email?.message || " ",
                      }}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </div>

              <div>
                <label className="text-sm text-slate-300" htmlFor="roleId">
                  Role
                </label>
                <Controller
                  name="roleId"
                  rules={{ required: "This field is required" }}
                  control={control}
                  render={({ field }) => (
                    <DropdownInput
                      id={"RoleId_Administration_Dropdown"}
                      required
                      validationMessage={
                        formState.errors?.roleId?.message || " "
                      }
                      value={"id"}
                      selectedOption={field.value}
                      label={"name"}
                      onChange={(selectedValue) => {
                        field.onChange(selectedValue);
                      }}
                      items={state.roles}
                    />
                  )}
                />
              </div>

              <div>
                <label className="text-sm text-slate-300" htmlFor="phoneNumber">
                  Mobile Number
                </label>
                <Controller
                  name="phoneNumber"
                  rules={{ required: "This field is required" }}
                  control={control}
                  render={({ field }) => (
                    <Textbox
                      validationMessages={{
                        required: formState.errors?.phoneNumber?.message || " ",
                      }}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </div>

              <div>
                <label className="text-sm text-slate-300" htmlFor="department">
                  Department
                </label>
                <Controller
                  name="department"
                  control={control}
                  render={({ field }) => (
                    <Textbox value={field.value} onChange={field.onChange} />
                  )}
                />
              </div>

              <div>
                <label
                  className="text-sm text-slate-300"
                  htmlFor="companyCountry"
                >
                  Company Country
                </label>
                <Controller
                  name="companyCountry"
                  control={control}
                  render={({ field }) => (
                    <DropdownInput
                      id={"CompanyCountry_Administration_Dropdown"}
                      value={"name"}
                      selectedOption={field.value}
                      label={"name"}
                      onChange={(selectedValue) => {
                        field.onChange(selectedValue);
                      }}
                      items={state.countries}
                    />
                  )}
                />
              </div>
            </div>

            <div className="grid grid-cols-4 gap-4 mt-6">
              <div className="block"></div>
              <div className="block"></div>
              <div className="block"></div>

              {updateAccess && (
                <div className="block">
                  <Button type="submit" variant="primaryorange">
                    <span className="text-white">Update</span>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </CustomModal>
  );
};

export default AdministrationUserModal;
