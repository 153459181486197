import React, { lazy, useEffect, useState } from "react";
import CustomModal from "../modal";
import useJobs from "../../hooks/useJobs";
import { IMapType } from "../../types/MapDataType";
import { generateUserErrorMessage } from "../../utils/constants/ErrorConstants";
import ModalPopup from "../modalPopUp";
const MapComponent = lazy(() => import("../mapComponent"));

const MapModal = ({
  showModal,
  setShowModal,
  locationData,
  setCoordinateData,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  locationData: IMapType | undefined;
  setCoordinateData: React.Dispatch<
    React.SetStateAction<{
      latitude: number;
      longitude: number;
    }>
  >;
}) => {
  const { createMapPointData, state: locationMarkerState } = useJobs();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorModalMessage, setShowErrorModalMessage] = useState("");

  useEffect(() => {
    if (locationData !== undefined && locationData !== null) {
      createMapPointData(locationData).then((data: any) => {
        if (data.statusCode >= 400 || data?.locationMarker?.statusCode >= 400) {
          setShowErrorModalMessage(generateUserErrorMessage(data.error));
          setShowErrorModal(true);
        } else {
          setCoordinateData({
            latitude: data?.geometry?.location?.lat ?? 0,
            longitude: data?.geometry?.location?.lng ?? 0,
          });
        }
      });
    }
  }, []);

  const sanitizeAndReformatData = () => {
    return locationMarkerState.locationMarker === undefined ||
      locationMarkerState.locationMarker?.statusCode === 422
      ? [
          {
            name: "generic_location_fallback",
            latitude: 3.157764,
            longitude: 101.711861,
          },
        ]
      : [
          {
            name: "user_location",
            latitude:
              locationMarkerState?.locationMarker?.geometry?.location?.lat ?? 0,
            longitude:
              locationMarkerState?.locationMarker?.geometry?.location?.lng ?? 0,
          },
        ];
  };

  return (
    <>
      <ModalPopup
        visible={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        content={showErrorModalMessage}
      />

      <CustomModal
        width={"85%"}
        title={""}
        open={showModal}
        onOk={() => {}}
        onCancel={() => {
          setShowModal(false);
        }}
        footer={null}
      >
        {locationMarkerState?.locationMarker === undefined ? (
          <></>
        ) : (
          // we add a high zoom rate here for more specificity on the location
          <MapComponent
            wrapperDivClassName={"h-96"}
            locationMarkers={sanitizeAndReformatData()}
            zoom={14}
          />
        )}
      </CustomModal>
    </>
  );
};

export default MapModal;
