import React from "react";
import { IPaymentInformation } from "../../types/SVUserTypes";
import Textbox from "../textbox";

const SiteVerifiersUsersModalFunctions = (
  paymentInformation: IPaymentInformation | undefined
) => {
  if (paymentInformation !== undefined && paymentInformation !== null) {
    switch (paymentInformation!.paymentMethod) {
      case "Local E-Wallet": {
        return (
          <>
            <div>
              <label className="text-sm text-slate-300" htmlFor="">
                Account Number
              </label>

              <Textbox
                disabled
                value={paymentInformation?.paymentDetail?.accountNumber}
                onChange={() => {}}
              />
            </div>
            <div>
              <label className="text-sm text-slate-300" htmlFor="">
                Account Holder Name
              </label>

              <Textbox
                disabled
                value={paymentInformation?.paymentDetail?.accountHolderName}
                onChange={() => {}}
              />
            </div>
          </>
        );
      }
      case "PayPal": {
        return (
          <div>
            <div>
              <label className="text-sm text-slate-300" htmlFor="">
                PayPal Email
              </label>

              <Textbox
                disabled
                value={paymentInformation?.paymentDetail?.emailID}
                onChange={() => {}}
              />
            </div>
          </div>
        );
      }
      case "Bank Transfer": {
        return (
          <>
            <div>
              <div>
                <label className="text-sm text-slate-300" htmlFor="">
                  Account Owner Name
                </label>

                <Textbox
                  disabled
                  value={
                    paymentInformation?.paymentDetail?.siteVerifierFullName
                  }
                  onChange={() => {}}
                />
              </div>
            </div>
            <div>
              <div>
                <label className="text-sm text-slate-300" htmlFor="">
                  Bank Name
                </label>

                <Textbox
                  disabled
                  value={paymentInformation?.paymentDetail?.bankFullName}
                  onChange={() => {}}
                />
              </div>
            </div>
            <div>
              <div>
                <label className="text-sm text-slate-300" htmlFor="">
                  Bank Address
                </label>

                <Textbox
                  disabled
                  value={paymentInformation?.paymentDetail?.bankAddress}
                  onChange={() => {}}
                />
              </div>
            </div>
            <div>
              <div>
                <label className="text-sm text-slate-300" htmlFor="">
                  Account Number
                </label>

                <Textbox
                  disabled
                  value={paymentInformation?.paymentDetail?.accountNumber}
                  onChange={() => {}}
                />
              </div>
            </div>
            <div>
              <div>
                <label className="text-sm text-slate-300" htmlFor="">
                  SwiftCode
                </label>

                <Textbox
                  disabled
                  value={paymentInformation?.paymentDetail?.swiftCode}
                  onChange={() => {}}
                />
              </div>
            </div>
            <div>
              <div>
                <label className="text-sm text-slate-300" htmlFor="">
                  Email
                </label>

                <Textbox
                  disabled
                  value={paymentInformation?.paymentDetail?.emailID}
                  onChange={() => {}}
                />
              </div>
            </div>
          </>
        );
      }
      case "Up Work Platform": {
        return (
          <>
            <div>
              <div>
                <label className="text-sm text-slate-300" htmlFor="">
                  Username
                </label>

                <Textbox
                  disabled
                  value={paymentInformation?.paymentDetail?.username}
                  onChange={() => {}}
                />
              </div>
            </div>
          </>
        );
      }
      case "Freelancer Platform": {
        return (
          <>
            <div>
              <div>
                <label className="text-sm text-slate-300" htmlFor="">
                  Username
                </label>

                <Textbox
                  disabled
                  value={paymentInformation?.paymentDetail?.username}
                  onChange={() => {}}
                />
              </div>
            </div>
            <div>
              <div>
                <label className="text-sm text-slate-300" htmlFor="">
                  Profile Link
                </label>

                <Textbox
                  disabled
                  value={paymentInformation?.paymentDetail?.profileLink}
                  onChange={() => {}}
                />
              </div>
            </div>
          </>
        );
      }
      case "NA": {
        return <div></div>;
      }
      default: {
        <div></div>;
      }
    }
  }

  //   return <div>siteVerifiersUsersModalFunctions</div>;
};

export default SiteVerifiersUsersModalFunctions;
