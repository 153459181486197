import { Rating } from "../../types/PerformanceType";

const PerformanceDetailsMonthlyRatingTable = ({ value }: { value: Rating }) => {
  return (
    <>
      <div className="p-0 m-0">
        <div className=" px-6 py-4 h-2/6 border border-light-gray-100">
          {value.delivery ?? 0}
        </div>
        <div className=" px-6 py-4 h-2/6 border border-light-gray-100">
          {value.quality ?? 0}
        </div>
        <div className=" px-6 py-4 h-2/6 border border-light-gray-100">
          {value.responsiveness ?? 0}
        </div>
      </div>
    </>
  );
};

export default PerformanceDetailsMonthlyRatingTable;
