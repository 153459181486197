import { useEffect } from "react";
import confirmis_logo from "../../../assets/confirmis_logo-design-final_ol.svg";
import "./styles.css";
import { Link, useLocation } from "react-router-dom";
import { faGreaterThan, faLessThan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SideNav = ({ isCollapsed, toggleNav, state }: any) => {
  const location = useLocation();
  useEffect(() => {}, [state]);

  return (
    <div
      id="mySideNav"
      className={`side-nav-container h-svh bg-white text-black shadow-md border border-light-gray-100 fixed z-50 transition-all duration-500 ${
        isCollapsed ? "collapsed" : ""
      }`}
    >
      <div className="block">
        <Link to="/">
          <img
            className={`mx-auto w-auto transition-opacity duration-500 z-50 ${
              isCollapsed ? "opacity-0" : "opacity-100"
            }`}
            src={confirmis_logo}
            alt="Confirmis Logo"
          />
        </Link>
      </div>

      <div className="grid col-span-1 justify-end">
        <button
          className="controlBtn px-2 absolute right-0 transform translate-x-full bg-white border border-light-gray-100 shadow-md"
          onClick={toggleNav}
        >
          <FontAwesomeIcon icon={isCollapsed ? faGreaterThan : faLessThan} />
        </button>
      </div>

      <div className="overflow-y-auto" style={{ height: "calc(100vh - 80px)" }}>
        <nav className="flex-col flex-1 flex px-6 py-6 text-left">
          <ul
            className={`flex-col flex-1 flex transition-opacity duration-500 ${
              isCollapsed ? "opacity-0" : "opacity-100"
            }`}
          >
            <div className="mb-4">
              <li>
                <span className="side-nav-header-text text-xl font-bold">
                  Site Verifiers
                </span>
                <ul>
                  <li
                    className={`pt-0.5 hover:bg-orange-100 hover:shadow-xl hover:rounded ${
                      location.pathname.includes("/users-sv/active-sv") &&
                      "bg-orange-200 rounded"
                    }`}
                  >
                    <Link to={"/users-sv/active-sv"}>
                      <div className="rounded flex justify-between align-middle transition-all py-1 pl-2">
                        <span className="text text-base font-semibold text-zinc-500">
                          Active SVs
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    className={`pt-0.5 hover:bg-orange-100 hover:shadow-xl hover:rounded ${
                      location.pathname.includes("/users-sv/inactive-sv") &&
                      "bg-orange-200 rounded"
                    }`}
                  >
                    <Link to={"/users-sv/inactive-sv"}>
                      <div className="rounded flex justify-between align-middle transition-all py-1 pl-2">
                        <span className="text text-base font-semibold text-zinc-500">
                          Inactive SVs
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    className={`pt-0.5 hover:bg-orange-100 hover:shadow-xl hover:rounded ${
                      location.pathname.includes("/users-new-sv") &&
                      "bg-orange-200 rounded"
                    }`}
                  >
                    <Link to={"/users-new-sv"}>
                      <div className="rounded flex justify-between align-middle transition-all py-1 pl-2">
                        <span className="text text-base font-semibold text-zinc-500">
                          Newly Registered SVs
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    className={`pt-0.5 hover:bg-orange-100 hover:shadow-xl hover:rounded ${
                      location.pathname.includes("/users-sv/suspended-sv") &&
                      "bg-orange-200 rounded"
                    }`}
                  >
                    <Link to={"/users-sv/suspended-sv"}>
                      <div className="rounded flex justify-between align-middle transition-all py-1 pl-2">
                        <span className="text text-base font-semibold text-zinc-500">
                          Suspended SVs
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="pt-4">
                <span className="side-nav-header-text text text-xl font-bold">
                  Jobs
                </span>
                <ul>
                  <li
                    className={`pt-0.5 hover:bg-orange-100 hover:shadow-xl hover:rounded ${
                      location.pathname.includes("/jobs/all") &&
                      "bg-orange-200 rounded"
                    }`}
                  >
                    <Link to={"/jobs/all"}>
                      <div className="rounded flex justify-between align-middle transition-all py-1 pl-2">
                        <span className="text text-base font-semibold text-zinc-500">
                          Job List
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="pt-0.5">
                    <div className="rounded flex justify-between align-middle transition-all py-1">
                      <span className="text text-base font-extrabold text-zinc-500">
                        Job Status:
                      </span>
                    </div>
                  </li>
                  <li
                    className={`py-1 hover:bg-orange-100 hover:shadow-xl hover:rounded ${
                      location.pathname.includes("/jobs/draft") &&
                      "bg-orange-200 rounded"
                    }`}
                  >
                    <Link to={"/jobs/draft"}>
                      <div className="rounded flex justify-between align-middle transition-all pr-2">
                        <span className="text text-base font-semibold text-zinc-500 ml-9">
                          Drafts
                        </span>
                        <span className="basis-1/6 text-center bg-light-gray-100 border-1 rounded">
                          {state?.jobListStatus?.draftCount ?? 0}
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    className={`py-1 hover:bg-orange-100 hover:shadow-xl hover:rounded ${
                      location.pathname.includes("/jobs/open") &&
                      "bg-orange-200 rounded"
                    }`}
                  >
                    <Link to={"/jobs/open"}>
                      <div className="rounded flex justify-between align-middle transition-all pr-2">
                        <span className="text text-base font-semibold text-zinc-500 ml-9">
                          Open
                        </span>
                        <span className="basis-1/6 text-center bg-light-gray-100 border-1 rounded">
                          {state?.jobListStatus?.openCount ?? 0}
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    className={`py-1 hover:bg-orange-100 hover:shadow-xl hover:rounded ${
                      location.pathname.includes("/jobs/pending") &&
                      "bg-orange-200 rounded"
                    }`}
                  >
                    <Link to={"/jobs/pending"}>
                      <div className="rounded flex justify-between align-middle transition-all pr-2">
                        <span className="text text-base font-semibold text-zinc-500 ml-9">
                          Pending
                        </span>
                        <span className="basis-1/6 text-center bg-light-gray-100 border-1 rounded">
                          {state?.jobListStatus?.pendingCount ?? 0}
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    className={`py-1 hover:bg-orange-100 hover:shadow-xl hover:rounded ${
                      location.pathname.includes("/jobs/approved") &&
                      "bg-orange-200 rounded"
                    }`}
                  >
                    <Link to={"/jobs/approved"}>
                      <div className="rounded flex justify-between align-middle transition-all pr-2">
                        <span className="text text-base font-semibold text-zinc-500 ml-9">
                          Approved
                        </span>
                        <span className="basis-1/6 text-center bg-light-gray-100 border-1 rounded">
                          {state?.jobListStatus?.approvedCount ?? 0}
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    className={`py-1 hover:bg-orange-100 hover:shadow-xl hover:rounded ${
                      location.pathname.includes("/jobs/accepted") &&
                      "bg-orange-200 rounded"
                    }`}
                  >
                    <Link to={"/jobs/accepted"}>
                      <div className="rounded flex justify-between align-middle transition-all pr-2">
                        <span className="text text-base font-semibold text-zinc-500 ml-9">
                          Accepted
                        </span>
                        <span className="basis-1/6 text-center bg-light-gray-100 border-1 rounded">
                          {state?.jobListStatus?.acceptedCount ?? 0}
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    className={`py-1 hover:bg-orange-100 hover:shadow-xl hover:rounded ${
                      location.pathname.includes("/jobs/withdraw") &&
                      "bg-orange-200 rounded"
                    }`}
                  >
                    <Link to={"/jobs/withdraw"}>
                      <div className="rounded flex justify-between align-middle transition-all pr-2">
                        <span className="text text-base font-semibold text-zinc-500 ml-9">
                          Withdraw
                        </span>
                        <span className="basis-1/6 text-center bg-light-gray-100 border-1 rounded">
                          {state?.jobListStatus?.withdrawCount ?? 0}
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    className={`py-1 hover:bg-orange-100 hover:shadow-xl hover:rounded ${
                      location.pathname.includes("/jobs/in-progress") &&
                      "bg-orange-200 rounded"
                    }`}
                  >
                    <Link to={"/jobs/in-progress"}>
                      <div className="rounded flex justify-between align-middle transition-all pr-2">
                        <span className="text text-base font-semibold text-zinc-500 ml-9">
                          In Progress
                        </span>
                        <span className="basis-1/6 text-center bg-light-gray-100 border-1 rounded">
                          {state?.jobListStatus?.inProgressCount ?? 0}
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    className={`py-1 hover:bg-orange-100 hover:shadow-xl hover:rounded ${
                      location.pathname.includes("/jobs/submitted") &&
                      "bg-orange-200 rounded"
                    }`}
                  >
                    <Link to={"/jobs/submitted"}>
                      <div className="rounded flex justify-between align-middle transition-all pr-2">
                        <span className="text text-base font-semibold text-zinc-500 ml-9">
                          Submitted
                        </span>
                        <span className="basis-1/6 text-center bg-light-gray-100 border-1 rounded">
                          {state?.jobListStatus?.submittedCount ?? 0}
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    className={`py-1 hover:bg-orange-100 hover:shadow-xl hover:rounded ${
                      location.pathname.includes("/jobs/completed") &&
                      "bg-orange-200 rounded"
                    }`}
                  >
                    <Link to={"/jobs/completed"}>
                      <div className="rounded flex justify-between align-middle transition-all pr-2">
                        <span className="text text-base font-semibold text-zinc-500 ml-9">
                          Completed
                        </span>
                        <span className="basis-1/6 text-center bg-light-gray-100 border-1 rounded">
                          {state?.jobListStatus?.completedCount ?? 0}
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    className={`py-1 hover:bg-orange-100 hover:shadow-xl hover:rounded ${
                      location.pathname.includes("/jobs/cancelled") &&
                      "bg-orange-200 rounded"
                    }`}
                  >
                    <Link to={"/jobs/cancelled"}>
                      <div className="rounded flex justify-between align-middle transition-all pr-2">
                        <span className="text text-base font-semibold text-zinc-500 ml-9">
                          Cancelled
                        </span>
                        <span className="basis-1/6 text-center bg-light-gray-100 border-1 rounded">
                          {state?.jobListStatus?.cancelledCount ?? 0}
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="pt-4">
                <span className="side-nav-header-text text text-xl font-bold">
                  Performance
                </span>
                <ul>
                  <li
                    className={`py-1 hover:bg-orange-100 hover:shadow-xl hover:rounded ${
                      location.pathname.endsWith("/performance") &&
                      "bg-orange-200 rounded"
                    }`}
                  >
                    <Link to={"/performance"}>
                      <div className="rounded flex justify-between align-middle transition-all pl-2">
                        <span className="text text-base font-semibold text-zinc-500">
                          Performance List
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="pt-4">
                <span className="side-nav-header-text text text-xl font-bold">
                  Administration
                </span>
                <ul>
                  <li
                    className={`py-1 hover:bg-orange-100 hover:shadow-xl hover:rounded ${
                      location.pathname.endsWith("/administration-user") &&
                      "bg-orange-200 rounded"
                    }`}
                  >
                    <Link to={"/administration-user"}>
                      <div className="rounded flex justify-between align-middle transition-all pl-2">
                        <span className="text text-base font-semibold text-zinc-500">
                          User Management
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li
                    className={`py-1 hover:bg-orange-100 hover:shadow-xl hover:rounded ${
                      location.pathname.endsWith("/administration-role") &&
                      "bg-orange-200 rounded"
                    }`}
                  >
                    <Link to={"/administration-role"}>
                      <div className="rounded flex justify-between align-middle transition-all pl-2">
                        <span className="text text-base font-semibold text-zinc-500">
                          Roles Management
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </li>
            </div>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default SideNav;
