import { FieldValues, UseFormSetValue } from "react-hook-form";

type SVListingPageType = {
  id: string;
  svType: string;
};

const svPageTypeMap: SVListingPageType[] = [
  {
    id: "/active-sv",
    svType: "Active",
  },
  {
    id: "/inactive-sv",
    svType: "Inactive",
  },
  {
    id: "/suspended-sv",
    svType: "Suspended",
  },
];

/**
 * changeUIDisplayByURL changes the SV status search filter based on the current pathname
 *
 * @param setValueOfFilter - takes a referene to the SV status search filter for further manipulation
 * @param {string} svPagePathname - takes the pathname of the SVs
 * @returns
 */
export const changeUIDisplayByURL = ({
  setValueOfFilter,
  svPagePathname,
}: {
  setValueOfFilter: UseFormSetValue<FieldValues>;
  svPagePathname: string;
}) => {
  let pathname = svPagePathname;
  let setValue: UseFormSetValue<FieldValues> = setValueOfFilter;
  let svPageType: SVListingPageType | undefined;

  return {
    /**
     * findSVPageType finds the corresponding sv status in the svPageTypeMap based on the pathname
     * @returns changeUIDisplayByURL object
     */
    findSVPageType: function () {
      svPageType = svPageTypeMap.find((jpt) => pathname.includes(jpt.id));
      return this;
    },

    /**
     * setValueOfSVStatus changes the value of the sv status search filter
     * @returns changeUIDisplayByURL object
     */
    setValueOfSVStatus: function () {
      setValue("Filter", svPageType?.svType);
      return this;
    },
  };
};

/**
 * This function is to determine which SV status should be passed to the search filter function for SVs
 * @param {string} svPagePathname
 * @param {any[]} svPageStatusList
 * @returns
 */
export const createPropsForRetrievingSVs = ({
  svPagePathname,
  svPageStatusList,
}: {
  svPagePathname: string;
  svPageStatusList: any[];
}) => {
  let pathname: string = svPagePathname;
  let svStatusList: any[] = svPageStatusList;
  let filteredSVStatus: any;
  return {
    /**
     * retrieveSVStatusForURL finds the corresponding SV status based on the pathname
     * and retrieves the specific SV status record which contains the name and id of the SV status
     * @returns createPropsForRetrievingSVs object
     */
    retrieveSVStatusForURL: function () {
      filteredSVStatus = svStatusList?.find((svStatus: any) => {
        if (pathname.includes("inactive")) {
          return svStatus.name.toLowerCase().includes("inactive".toString());
        }
        return pathname.includes(svStatus.name.toString().toLowerCase());
      });
      return this;
    },
    /**
     * returnSVStatusId returns the SV status id that has been retrieved
     * @returns the SV status ID
     */
    returnSVStatus: function () {
      return filteredSVStatus?.name;
    },
  };
};
