import DropdownInput from "../../components/dropdown";
import Pagination from "../../components/pagination";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Textbox from "../../components/textbox";
import CustomButton from "../../components/button";
import useSiteVerifiers from "../../hooks/useSiteVerifiers";
import Loading from "../../components/loading";
import DropdownKebab from "../../components/dropdownKebab";
import account_circle from "../../assets/account_circle.svg";
import ModalPopup from "../../components/modalPopUp";
import SiteVerifiersNewAccountModal from "../../components/siteVerifiersNewAccountModal";
import { RoleAccessContext } from "../../context/RoleAccessContext";

const SiteVerifiersNewAccountPage = () => {
  const { roleAccessObj } = useContext(RoleAccessContext);

  const roleAccess = roleAccessObj.find(
    (x) => x.module.id === 1 && x.page.id === 2
  );

  const [readAccess, setReadAccess] = useState(false);
  const [writeAccess, setWriteAccess] = useState(false);
  const [updateAccess, setUpdateAccess] = useState(false);

  let dropdownKebabItem: { value: string; label: string }[] = [];

  useEffect(() => {
    if (roleAccess) {
      setReadAccess(roleAccess?.read);
      setWriteAccess(roleAccess?.write);
      setUpdateAccess(roleAccess?.update);
    }

    if (roleAccess?.read) {
      dropdownKebabItem.push({ value: "view", label: "View" });
    }
  }, [roleAccessObj]);

  const {
    state,
    getSVSortByDropDownList,
    getCountriesDropDownList,
    getNewSiteVerifiersList,
  } = useSiteVerifiers();

  useEffect(() => {
    getSVSortByDropDownList();
    getCountriesDropDownList();
    getNewSiteVerifiersList({ pageSize: 10, pageIndex: 1, sort: "Descending" });
  }, [getNewSiteVerifiersList]);

  const [accountData, setAccountData] = useState<{ items: [] }>({ items: [] });

  const { handleSubmit: handleSubmitSearch, control: searchControl } =
    useForm();

  const [currentGridFilters, setCurrentGridFilters] = useState<any>({});

  const [accountId, setAccountId] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const refreshGrid = () => {
    getNewSiteVerifiersList({ pageSize: 10, pageIndex: 1, sort: "Descending" });
  };

  const handlePageClick = (pageIndex: number) => {
    if (readAccess) {
      getNewSiteVerifiersList({
        pageSize: 10,
        pageIndex,
        sort: "Descending",
        filterParams: currentGridFilters,
      });
    } else {
      setShowAlertModal({
        title: "Access Denied",
        message: "You do not have read access to this.",
      });
    }
  };

  const onSubmit = (data: any) => {
    if (readAccess) {
      setCurrentGridFilters({
        Country: data?.Country,
        Province: data?.Province,
        SortBy: data?.SortBy,
      });
      getNewSiteVerifiersList({
        pageSize: 10,
        pageIndex: 1,
        sort: "Descending",
        filterParams: {
          Country: data?.Country,
          Province: data?.Province,
          SortBy: data?.SortBy,
        },
      }).then((res: any) => {
        const items = res?.items;
        setAccountData({ items });
      });
    } else {
      setShowAlertModal({
        title: "Access Denied",
        message: "You do not have read access to this.",
      });
    }
  };

  const [showAlertModal, setShowAlertModal] = useState<{
    title: string;
    message: string;
  }>({
    title: "",
    message: "",
  });

  return (
    <>
      {state.isLoading && <Loading />}

      <ModalPopup
        visible={!!showAlertModal.title || !!showAlertModal.message}
        onClose={() => setShowAlertModal({ title: "", message: "" })}
        title={showAlertModal?.title}
        content={<p>{showAlertModal.message}</p>}
      />

      <SiteVerifiersNewAccountModal
        refreshGrid={refreshGrid}
        showModal={isModalOpen}
        setShowModal={setIsModalOpen}
        userId={accountId}
        setShowAlertModal={setShowAlertModal}
        writeAccess={writeAccess}
      />

      <>
        <div className="p-5 border border-gray rounded-lg bg-white">
          <div className="block px-5 mb-5">
            <span className="text-xl font-bold">New Account</span>
          </div>

          {readAccess && (
            <form onSubmit={handleSubmitSearch(onSubmit)}>
              <div className="grid grid-cols-7 grid-rows-1 gap-5 p-5">
                <div className="block mb-4">
                  <label
                    className="block text-sm font-medium leading-6 text-dark-gray mb-1"
                    htmlFor=""
                  >
                    COUNTRY
                  </label>
                  <Controller
                    name="Country"
                    control={searchControl}
                    render={({ field }) => {
                      return (
                        <DropdownInput
                          id={"Country_New_Account_Dropdown"}
                          value={"name"}
                          selectedOption={field.value}
                          label={"name"}
                          onChange={(selectedValue) => {
                            field.onChange(selectedValue);
                          }}
                          items={state.countries}
                        />
                      );
                    }}
                  />
                </div>
                <div className="block mb-4">
                  <label
                    className="block text-sm font-medium leading-6 text-dark-gray mb-1"
                    htmlFor=""
                  >
                    STATE
                  </label>
                  <Controller
                    name="Province"
                    control={searchControl}
                    defaultValue=""
                    render={({ field }) => (
                      <Textbox value={field.value} onChange={field.onChange} />
                    )}
                  />
                </div>
                <div className="block mb-4">
                  <label
                    className="block text-sm font-medium leading-6 text-dark-gray mb-1"
                    htmlFor=""
                  >
                    SORT BY
                  </label>
                  <Controller
                    name="SortBy"
                    control={searchControl}
                    defaultValue=""
                    render={({ field }) => (
                      <DropdownInput
                        id={"SortByDropdown"}
                        value={"name"}
                        selectedOption={field.value}
                        label={"name"}
                        onChange={(selectedValue) => {
                          field.onChange(selectedValue);
                        }}
                        items={state.svSortBy}
                      />
                    )}
                  />
                </div>

                <div className="block mt-6">
                  <div className="block">
                    <CustomButton
                      children={"Search"}
                      variant={"primarygreen"}
                      type="submit"
                    />
                  </div>
                </div>
              </div>
            </form>
          )}

          <div className="block px-5 mb-3 h-4/6">
            <div className="relative  mb-10">
              <table className="w-full text-sm text-left text-black h-5/6">
                <thead className="text-xs border-b border-b-light-gray-100">
                  <tr>
                    <th scope="col" className="px-6 py-3 w-32"></th>
                    <th scope="col" className="px-6 py-3">
                      NAME
                    </th>
                    <th scope="col" className="px-6 py-3">
                      COUNTRY
                    </th>
                    <th scope="col" className="px-6 py-3">
                      REGISTERED
                    </th>
                    <th scope="col" className="px-6 py-3 justify-start"></th>
                  </tr>
                </thead>
                <tbody>
                  {state?.newSvList?.items?.length > 0 &&
                    state.newSvList.items.map((item: any) => {
                      return (
                        <tr className="bg-white border-b border-b-light-gray-100">
                          <td className="px-6 py-41">
                            <img
                              src={item?.profilePictureUrl || account_circle}
                              alt="profile picture of the user"
                              className=""
                            />
                          </td>
                          <td className="px-6 py-41">{item?.name || "N/A"}</td>
                          <td className="px-6 py-4">
                            {item?.country || "N/A"}
                          </td>
                          <td className="px-6 py-4">
                            {item?.lastLogin || "N/A"}
                          </td>
                          <td className="px-6 py-4">
                            <DropdownKebab
                              onChange={(value: any) => {
                                if (value === "view" && readAccess) {
                                  setIsModalOpen(true);
                                  setAccountId(item.id);
                                } else {
                                  setShowAlertModal({
                                    title: "Access Denied",
                                    message:
                                      "You do not have read access to this.",
                                  });
                                }
                              }}
                              items={[{ value: "view", label: "View" }]}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex justify-start h-8">
            <div>
              <Pagination
                currentPage={state.newSvList?.pageIndex}
                totalPages={state.newSvList?.totalPages}
                onClickPage={handlePageClick}
              />
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default SiteVerifiersNewAccountPage;
