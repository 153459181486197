import React, { Dispatch, useEffect, useState } from "react";
import StatusIndicator from "../../../components/statusIndicator";
import DropdownKebab from "../../../components/dropdownKebab";
import { IItem } from "../../../types/JobType";
import useJobs from "../../../hooks/useJobs";
import { createPropsForModalURL } from "./jobsFeatureService";
import ConfirmationPopupModal from "../../../components/confirmationPopupModal";
import { Controller, useForm } from "react-hook-form";
import Textbox from "../../../components/textbox";

const JobListingRowFeature = ({
  item,
  setShowViewJobModel,
  setItemData,
  setModalJobMode,
  setShowCreateJobModal,
  setShowSubmitJobModal,
  setShowApprovedJobModal,
  setShowUpdateJobModal,
  setShowInviteSVModal,
  jobPagePathname,
  refreshGrid,
  readAccess,
  updateAccess,
  deleteAccess,
  writeAccess,
}: {
  item: IItem;
  setShowViewJobModel: Dispatch<React.SetStateAction<boolean>>;
  setItemData: React.Dispatch<React.SetStateAction<IItem | undefined>>;
  setModalJobMode: React.Dispatch<
    React.SetStateAction<"Create" | "Edit" | "Draft">
  >;
  setShowCreateJobModal: Dispatch<React.SetStateAction<boolean>>;
  setShowSubmitJobModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowApprovedJobModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowUpdateJobModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowInviteSVModal: React.Dispatch<React.SetStateAction<boolean>>;
  jobPagePathname: string;
  refreshGrid: () => void;
  readAccess: boolean;
  updateAccess: boolean;
  deleteAccess: boolean;
  writeAccess: boolean;
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showReopenModal, setShowReopenModal] = useState(false);
  const [buttonProps, setButtonProps] = useState<any>();

  const {
    createCancelledJobsDataById,
    deleteJobsDataById,
    createReopenJobsDataById,
  } = useJobs();

  const { control, getValues, formState, trigger } = useForm();

  const cancelJob = async () => {
    const reasonValid = await trigger("reason", { shouldFocus: true });
    if (reasonValid) {
      createCancelledJobsDataById(item.id, getValues("reason")).then(() => {
        refreshGrid();
        setShowCancelModal(false);
      });
      refreshGrid();
    }
    return;
  };

  const deleteJob = () => {
    deleteJobsDataById(item.id).then(() => {
      setShowDeleteModal(false);
      refreshGrid();
    });
  };

  const reopenJob = () => {
    createReopenJobsDataById(item.id).then(() => {
      setShowReopenModal(false);
      refreshGrid();
    });
  };

  /**
   * setStatusIndicator is used to set the status based on how long the job has been in a certain job status
   * ie. Job has been open for 24hrs or Job has been approved for 36 hours
   * @param {"yellow" | "red" | "green"} statusIndicator
   * @returns a string of the status indicator values
   */
  const setStatusIndicator = (statusIndicator: "yellow" | "red" | "green") => {
    if (statusIndicator === "yellow") {
      return "24 - 48 Hours";
    } else if (statusIndicator === "red") {
      return "> 48 Hours";
    } else if (statusIndicator === "green") {
      return "< 24 Hours";
    } else {
      return "< 24 Hours";
    }
  };

  useEffect(() => {
    let retrievedButtonProperties = createPropsForModalURL({
      jobStatus: item.status,
    })
      .findJobPageType()
      .returnButtonProps({
        isReopenJob: item.isAbleToReopen,
      });

    retrievedButtonProperties = retrievedButtonProperties.filter((x) => {
      if (!deleteAccess && x.label === "Remove") {
        return false;
      }
      if (!updateAccess && x.label === "Edit") {
        return false;
      }
      if (!writeAccess && x.label === "Reopen") {
        return false;
      }
      if (!writeAccess && x.label === "Cancel") {
        return false;
      }
      if (!writeAccess && x.label === "Invite SV") {
        return false;
      }
      return x;
    });

    setButtonProps(retrievedButtonProperties);
  }, [item]);

  return (
    <>
      {showCancelModal && (
        <>
          <ConfirmationPopupModal
            showModal={showCancelModal}
            setShowModal={setShowCancelModal}
            displayHeader={"Cancel the job?"}
            displayText1={
              "This action cannot be undone and may result in loss of data."
            }
            textField={
              <>
                <p>Reason of cancellation:</p>
                <Controller
                  name="reason"
                  control={control}
                  rules={{ required: "This field is required" }}
                  render={({ field }) => (
                    <Textbox
                      required
                      value={field.value}
                      onChange={field.onChange}
                      validationMessages={{
                        required: formState.errors?.reason?.message || " ",
                      }}
                    />
                  )}
                />
              </>
            }
            displayText2={"Confirm cancellation?"}
            handleAction={cancelJob}
          />
        </>
      )}

      {showDeleteModal && (
        <>
          <ConfirmationPopupModal
            showModal={showDeleteModal}
            setShowModal={setShowDeleteModal}
            displayHeader={"Remove the job?"}
            displayText1={
              "This action cannot be undone and may result in loss of data."
            }
            displayText2={"Confirm removal?"}
            handleAction={deleteJob}
          />
        </>
      )}

      {showReopenModal && (
        <>
          <ConfirmationPopupModal
            showModal={showReopenModal}
            setShowModal={setShowReopenModal}
            displayHeader={"Reopen the job?"}
            displayText1={
              "This action cannot be undone and may result in loss of data."
            }
            displayText2={"Reopen the job?"}
            handleAction={reopenJob}
          />
        </>
      )}

      <tr
        className="bg-white border-b border-b-light-gray-100"
        id={item.id.toString()}
      >
        <td className="px-6 py-4">{item.status}</td>
        <td className="px-6 py-4">{item.targetCompletionDate.toString()}</td>
        <td className="px-6 py-4">{item.companyName}</td>
        <td className="px-6 py-4">{item.location}</td>
        <td className="px-6 py-4">
          {!(
            jobPagePathname.includes("/completed") ||
            jobPagePathname.includes("/cancelled")
          ) && (
            <div className="w-full flex flex-nowrap">
              <div className="basis-5/12">
                <StatusIndicator
                  children={setStatusIndicator(item.statusIndicator)}
                  variant={item.statusIndicator}
                />
              </div>
            </div>
          )}
        </td>
        <td className="px-6 py-4">
          <DropdownKebab
            onChange={function (value: any): void {
              setItemData(item);
              if (value === "view") {
                setShowViewJobModel(true);
              } else if (value === "remove") {
                setShowDeleteModal(true);
              } else if (value === "edit") {
                setModalJobMode("Edit");
                setShowCreateJobModal(true);
              } else if (value === "reopen") {
                setShowReopenModal(true);
              } else if (value === "approve") {
                setShowApprovedJobModal(true);
              } else if (value === "submit") {
                setShowSubmitJobModal(true);
              } else if (value === "cancel") {
                setShowCancelModal(true);
              } else if (value === "draft") {
                setModalJobMode("Draft");
                setShowCreateJobModal(true);
              } else if (value === "editExisting") {
                setShowUpdateJobModal(true);
              } else if (value === "invite") {
                setShowInviteSVModal(true);
              }
            }}
            items={buttonProps}
          />
        </td>
      </tr>
    </>
  );
};

export default JobListingRowFeature;
