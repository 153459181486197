import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function Pagination({ currentPage, totalPages, onClickPage }: any) {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  const getLinkClassName = (page: number) => {
    const baseClassName =
      "flex items-center justify-center p-3 h-6 leading-tight border rounded-full mr-1";
    if (page === currentPage) {
      return `${baseClassName} bg-slate-400 text-black`;
    }
    return `${baseClassName} bg-white text-dark-gray`;
  };

  const handlePreviousClick = () => {
    if (!isFirstPage) {
      onClickPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (!isLastPage) {
      onClickPage(currentPage + 1);
    }
  };

  const generatePagination = () => {
    const pagination = [];

    // first page
    pagination.push(
      <li key={1}>
        <button onClick={() => onClickPage(1)} className={getLinkClassName(1)}>
          1
        </button>
      </li>
    );

    pagination.push(currentPage > 3 ? <li key="ellipsis-start">...</li> : null);

    // current page plus 2 sibling pages
    for (
      let i = Math.max(currentPage - 2, 2);
      i <= Math.min(currentPage + 2, totalPages - 1);
      i++
    ) {
      pagination.push(
        <li key={i}>
          <button
            onClick={() => onClickPage(i)}
            className={getLinkClassName(i)}
          >
            {i}
          </button>
        </li>
      );
    }

    pagination.push(
      totalPages - currentPage > 3 ? <li key="ellipsis-end">...</li> : null
    );

    // last page
    pagination.push(
      totalPages > 1 ? (
        <li key={totalPages}>
          <button
            onClick={() => onClickPage(totalPages)}
            className={getLinkClassName(totalPages)}
          >
            {totalPages}
          </button>
        </li>
      ) : null
    );

    return <>{pagination}</>;
  };

  return (
    <div>
      <nav>
        <ul className="flex items-center space-x-1 h-8 text-sm flex-wrap">
          <li>
            <button
              onClick={handlePreviousClick}
              className={`flex items-center justify-center px-2 h-6 leading-tight text-dark-gray rounded mr-1 ${
                isFirstPage ? "bg-white" : "bg-slate-400"
              }`}
              disabled={isFirstPage}
            >
              <span className="sr-only">Previous</span>
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
          </li>
          {generatePagination()}
          <li>
            <button
              onClick={handleNextClick}
              className={`flex items-center justify-center px-2 h-6 leading-tight text-dark-gray rounded mr-1 ${
                isLastPage ? "bg-white" : "bg-slate-400"
              }`}
              disabled={isLastPage}
            >
              <span className="sr-only">Next</span>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Pagination;
