import React, { useEffect, useState } from "react";
import CustomModal from "../modal";
import Button from "../button";
import StarComponent from "../star";
import useJobs from "../../hooks/useJobs";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { IItem, IJobCandidate } from "../../types/JobType";
import DropdownWithMedia from "../dropdownWithMedia";
import dayjs from "dayjs";

const ApprovedJobModal = ({
  item,
  showApprovedJobModal,
  setShowApprovedJobModal,
  refreshGrid,
  writeAccess,
}: {
  item: IItem | undefined;
  showApprovedJobModal: boolean;
  setShowApprovedJobModal: React.Dispatch<React.SetStateAction<boolean>>;
  refreshGrid: () => void;
  writeAccess: boolean;
}) => {
  const { getJobsDataById, createApprovedJobsDataById, state } = useJobs();
  const { handleSubmit, control } = useForm();
  const [dummyState, setDummyState] = useState(0);

  useEffect(() => {
    const itemId = item?.id ?? 0;
    if (itemId !== 0 && showApprovedJobModal) getJobsDataById(itemId);
  }, [item, showApprovedJobModal]);

  const handleApprovedJob = (data: FieldValues) => {
    createApprovedJobsDataById({
      id: item?.id!,
      svId: Number.parseInt(data?.selectedCandidate),
    }).then((data: any) => refreshGrid());
    setShowApprovedJobModal(false);
  };

  return (
    <>
      <CustomModal
        width={"60%"}
        title={""}
        open={showApprovedJobModal}
        onOk={() => {}}
        onCancel={() => {
          setShowApprovedJobModal(false);
        }}
        footer={null}
      >
        <form>
          <div className="border-gray p-4">
            <div className="block">
              <div className="block w-full">
                <label className="text-sm text-slate-300 font-medium">
                  JOB ID: {state.job?.jobIdStr ?? "NA"}
                </label>
              </div>
              <div className="grid grid-cols-6 bg-white mb-8">
                <div className="col-span-4">
                  <div className="grid grid-cols-2 w-full h-4/6 gap-3">
                    <div className="block">
                      <label className="text-sm text-orange font-medium mr-2">
                        Company Name:
                      </label>
                      <br />
                      <label className="text-sm">
                        {state?.job?.companyName ?? "NA"}
                      </label>
                    </div>
                    <div className="block">
                      <label className="text-sm text-orange font-medium mr-2">
                        Office No:
                      </label>
                      <br />
                      <label className="text-sm">
                        {state?.job?.officeNo ?? "NA"}
                      </label>
                    </div>
                    <div className="block">
                      <label className="text-sm text-orange font-medium mr-2">
                        Timezone:
                      </label>
                      <br />
                      <label className="text-sm">
                        {state.job?.jobAddressInformation?.timeZone ?? "NA"}
                      </label>
                    </div>
                    <div className="block">
                      <label className="text-sm text-orange font-medium mr-2">
                        Country:
                      </label>
                      <br />
                      <label className="text-sm">
                        {state.job?.jobAddressInformation?.country ?? "NA"}
                      </label>
                    </div>
                    <div className="block">
                      <label className="text-sm text-orange font-medium mr-2">
                        State/Province:
                      </label>
                      <br />
                      <label className="text-sm">
                        {state.job?.jobAddressInformation?.province ?? "NA"}
                      </label>
                    </div>
                    <div className="block">
                      <label className="text-sm text-orange font-medium mr-2">
                        Status:
                      </label>
                      <br />
                      <label className="text-sm">
                        {state?.job?.status.name ?? "NA"}
                      </label>
                    </div>
                    <div className="block">
                      <label className="text-sm text-orange font-medium mr-2">
                        Target Completion Date:
                      </label>
                      <br />
                      <label className="text-sm">
                        {state.job?.targetCompletionDate?.toString() ?? "NA"}
                      </label>
                    </div>

                    {/* <div className="block">
                      <label className="text-sm text-orange font-medium mr-2">
                        Proposed Visiting Date:
                      </label>
                      <br />
                      <label className="text-sm">{"NA"}</label>
                    </div> */}

                    <div className="block">
                      <label className="text-sm text-orange font-medium mr-2">
                        Estimated Payment:
                      </label>
                      <br />
                      <label className="text-sm">
                        {state.job?.estimatedPayment ?? "NA"}
                      </label>
                    </div>
                    <div className="block">
                      <label className="text-sm text-orange font-medium mr-2">
                        Other Details:
                      </label>
                      <br />
                      <label className="text-sm">
                        {state.job?.otherDetails ?? "NA"}
                      </label>
                    </div>
                    <div className="block">
                      <label className="text-sm text-orange font-medium mr-2">
                        Company Email:
                      </label>
                      <br />
                      <label className="text-sm">
                        {state.job?.companyEmail ?? "NA"}
                      </label>
                    </div>
                    <div className="block">
                      <label className="text-sm text-orange font-medium mr-2">
                        Building Name:
                      </label>
                      <br />
                      <label className="text-sm">
                        {state.job?.jobAddressInformation?.buildingName ?? "NA"}
                      </label>
                    </div>

                    <div className="block">
                      <label className="text-sm text-orange font-medium mr-2">
                        Street 1:
                      </label>
                      <br />
                      <label className="text-sm">
                        {state.job?.jobAddressInformation?.address1 ?? "NA"}
                      </label>
                    </div>

                    <div className="block">
                      <label className="text-sm text-orange font-medium mr-2">
                        Street 2:
                      </label>
                      <br />
                      <label className="text-sm">
                        {state.job?.jobAddressInformation?.address2 ?? "NA"}
                      </label>
                    </div>

                    <div className="block">
                      <label className="text-sm text-orange font-medium mr-2">
                        Postcode:
                      </label>
                      <br />
                      <label className="text-sm">
                        {state.job?.jobAddressInformation?.postalCode ?? "NA"}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-span-2 items-center">
                  <Controller
                    control={control}
                    name="selectedCandidate"
                    render={({ field }) => (
                      <>
                        <DropdownWithMedia
                          id={"selectedCandidateDropDown" + state.job?.id}
                          selectedId={field.value}
                          onSelect={(selectedValue: number) => {
                            field.onChange(selectedValue);
                          }}
                          data={state.job?.jobCandidates?.map(
                            (candidate: IJobCandidate) => {
                              return {
                                name: candidate?.user?.name,
                                id: candidate?.user?.id ?? 0,
                                // dayjs assigns the latest date if the key-value pair is not found
                                proposedVisitingDate: dayjs(
                                  candidate?.proposedAppointmentDate
                                ).format("DD/MM/YYYY"),
                                proposedVisitingTime:
                                  candidate?.proposedAppointmentTime,
                                children: (
                                  <StarComponent
                                    disabled
                                    labelText={"Rating:"}
                                    setStarRating={setDummyState}
                                    ratingValue={
                                      candidate?.user?.siteVerifierInformation
                                        ?.rating ?? 0
                                    }
                                  />
                                ),
                              };
                            }
                          )}
                        />
                        {/* <DropdownInput
                          value={"id"}
                          selectedOption={field.value}
                          label={"name"}
                          onChange={(selectedValue) => {
                            field.onChange(selectedValue);
                          }}
                          items={state.roles}
                        /> */}
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="flex justify-end w-full bg-white">
                <div className="flex justify-end basis-3/6">
                  {writeAccess && (
                    <div className=" w-3/6">
                      <Button
                        children={["Approve"]}
                        variant={"primaryorange"}
                        type="button"
                        onClick={handleSubmit(handleApprovedJob)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </CustomModal>
    </>
  );
};

export default ApprovedJobModal;
