import React, { useEffect, useState } from "react";

const TextBox: React.FC<{
  value?: any;
  placeholder?: string;
  onChange: (value: string) => void;
  type?:
    | "number"
    | "text"
    | "alphabet"
    | "between"
    | "email"
    | "phone"
    | "custom"
    | "password"
    | "alphanumeric";
  min?: number;
  max?: number;
  pattern?: string;
  required?: boolean;
  validationMessages?: { [key in any]: any };
  disabled?: boolean;
  confirmPassword?: string;
  name?: string;
}> = ({
  value,
  onChange,
  type,
  min,
  max,
  pattern,
  required,
  validationMessages = { required: "This field is required" },
  disabled,
  confirmPassword,
  placeholder,
}) => {
  const [inputValue, setInputValue] = useState(value || "");
  const [currentValidationMessage, setCurrentValidationMessage] = useState(
    required
      ? validationMessages.required
        ? validationMessages.required
        : required
        ? required
        : ""
      : ""
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);

    if (validateInput(newValue)) {
      onChange(newValue);
    }
    if (newValue === "") {
      onChange("");
    }
  };

  const validateInput = (newValue: string) => {
    let isValid = true;
    let message = "";

    if (required && newValue === "") {
      isValid = false;
      message = validationMessages.required;
    } else if (type === "number") {
      if (!/^\d+$/.test(newValue)) {
        isValid = false;
        message = validationMessages.number || "Please enter a valid number";
      }
    } else if (type === "text") {
      if (!/^[A-Za-z\s]+$/.test(newValue)) {
        isValid = false;
        message = validationMessages.text || "Please enter a valid text";
      }
    } else if (type === "alphabet") {
      if (/[^A-Za-z]/g.test(newValue)) {
        isValid = false;
        message =
          validationMessages.alphabet ||
          "Please enter only alphabetic characters";
      }
    } else if (type === "email") {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(newValue)) {
        message =
          validationMessages.email || "Please enter a valid email address";
      }
    } else if (type === "phone") {
      if (!/^[\+]?[0-9]{0,20}$/.test(newValue)) {
        message =
          validationMessages.phone || "Please enter a valid phone number";
        isValid = false;
      }
    } else if (type === "custom" && pattern) {
      const customRegex = new RegExp(pattern);
      if (!customRegex.test(newValue)) {
        isValid = false;
        message = validationMessages.custom || "Please enter a valid value";
      }
    } else if (type === "alphanumeric") {
      if (!/^[A-Za-z0-9_-]{0,15}$/.test(newValue)) {
        message =
          validationMessages.alphanumeric ||
          "Please enter a valid alphanumeric value";
        isValid = false;
      }
    }
    if (type === "between" && (min !== undefined || max !== undefined)) {
      const numericValue = parseFloat(newValue);
      const isNegativeNumber = /^-?\d*\.?\d*$/.test(newValue);

      if (
        !isNegativeNumber ||
        (min !== undefined && numericValue < min) ||
        (max !== undefined && numericValue > max)
      ) {
        isValid = false;
        message =
          validationMessages.between ||
          `Please enter a number between ${min} and ${max}`;
      }
    } else if (type === "password" && confirmPassword) {
      if (newValue !== confirmPassword) {
        message =
          validationMessages.passwordMismatch || "Passwords do not match";
      }
    }

    setCurrentValidationMessage(message);

    return isValid;
  };

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);
  return (
    <>
      <input
        placeholder={placeholder}
        type={
          type === "number"
            ? "number"
            : type === "password"
            ? "password"
            : "text"
        }
        value={inputValue}
        onChange={handleChange}
        min={type === "number" ? min : undefined}
        max={type === "number" ? max : undefined}
        onKeyPress={(e) => {
          if (!validateInput(inputValue + e.key)) {
            e.preventDefault();
          }
        }}
        disabled={disabled}
        className="block w-full border border-light-gray-100 p-2 text-dark-gray shadow-sm ring-1 ring-inset ring-gray placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-5 disabled:bg-light-gray-100 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
      />
      {currentValidationMessage
        ? required &&
          !inputValue && (
            <p className="mt-2 text-red text-xs">
              {validationMessages?.required || "Field is required"}
            </p>
          )
        : currentValidationMessage &&
          !inputValue && (
            <p className="mt-2 text-red text-xs">
              {currentValidationMessage || "Field is Required"}
            </p>
          )}
      {currentValidationMessage && inputValue && (
        <p className="mt-2 text-red text-xs">{currentValidationMessage}</p>
      )}
    </>
  );
};

export default TextBox;
