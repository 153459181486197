import React, { useEffect, useState } from "react";
import { Input } from "antd";

const TextboxWithStyle: React.FC<{
  required?: boolean;
  validationMessages?: { [key in any]: any };
  disabled?: boolean;
  value?: any;
  placeholder?: string;
  onChange: (value: string) => void;
  name: string;
}> = ({
  value,
  onChange,
  placeholder,
  required,
  validationMessages = { required: "This field is required" },
  disabled,
  name,
}) => {
  const [inputValue, setInputValue] = useState(value || "");
  const [currentValidationMessage, setCurrentValidationMessage] = useState(
    required
      ? validationMessages.required
        ? validationMessages.required
        : required
        ? required
        : ""
      : ""
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const newValue = event.target.value;
    setInputValue(newValue);

    if (validateInput(newValue)) {
      onChange(newValue);
    }
    if (newValue === "") {
      onChange("");
    }
  };

  const validateInput = (newValue: string) => {
    let isValid = true;
    let message = "";

    if (required && newValue === "") {
      isValid = false;
      message = validationMessages.required;
    }

    setCurrentValidationMessage(message);

    return isValid;
  };

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  return (
    <>
      <Input
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        className="p-2 rounded-none focus:bg-blue border-light-gray-100 text-dark-gray shadow-sm ring-1 ring-inset ring-gray"
        name={name}
        required={required}
      />
      {currentValidationMessage
        ? required &&
          !inputValue && (
            <p className="mt-2 text-red text-xs">
              {validationMessages?.required || "Field is required"}
            </p>
          )
        : currentValidationMessage &&
          !inputValue && (
            <p className="mt-2 text-red text-xs">
              {currentValidationMessage || "Field is Required"}
            </p>
          )}
    </>
  );
};

export default TextboxWithStyle;
