import { AxiosClientUtil } from "../utils/httpUtils/AxiosClientUtil";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useReducer, useState } from "react";
import { useGlobalStates } from "./useGlobalStates";
import { setCookieUtil } from "../utils/CookiesUtil";
import { jwtDecode } from "jwt-decode";

export const initialState = {
  username: null,
  password: null,
  isLoading: false,
};

const reducer = (state: any, action: { type: string; payload?: any }): any => {
  switch (action.type) {
    case "SET_EMAIL":
      return { ...state, email: action.payload };
    case "SET_PASSWORD":
      return { ...state, password: action.payload };
    case "START_LOADING":
      return { ...state, isLoading: true };
    case "END_LOADING":
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export function useAuth() {
  const router = useNavigate();

  const { setCurrentUserValues } = useGlobalStates();

  const [state, dispatch] = useReducer(reducer, initialState);

  const [showErrorModal, setShowErrorModal] = useState<{
    title: string;
    message: string;
  }>({
    title: "",
    message: "",
  });

  const handleLogin = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!state.email || !state.password) {
      setShowErrorModal({
        title: "Error",
        message: "Please Enter a Valid Email And Password",
      });
      return;
    }
    try {
      dispatch({ type: "START_LOADING", payload: true });
      const response = await AxiosClientUtil.post("/Auth/login", {
        email: state.email,
        password: state.password,
      });
      if (response?.status !== 200) {
        setShowErrorModal({
          title: "Error",
          // @ts-ignore
          message: response?.data.error || "",
        });
        return;
      }
      const { token, expiration } = await response?.data;
      setCookieUtil({
        name: "jwtToken",
        value: token,
        options: {
          expires: new Date(expiration),
          secure: true,
          sameSite: "strict",
        },
      });

      // eslint-disable-next-line react-hooks/rules-of-hooks
      getCurrrentUserValues();
      router("/");
      dispatch({ type: "LOGIN_SUCCESS" });
    } catch (error: any) {
      setShowErrorModal({
        title: "Error",
        message: "An unexpected error was encountered..",
      });
    } finally {
      dispatch({ type: "END_LOADING", payload: false });
    }
  };

  const checkIsLogin = () => {
    const token = Cookies.get("jwtToken");
    if (token) {
      try {
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const expirationTime = decodedToken.exp * 1000;
        if (expirationTime <= Date.now()) {
          return false;
        } else {
          return true;
        }
      } catch (error) {
        return false;
      }
    }
    return false;
  };

  const getCurrrentUserValues = async () => {
    const jwtToken = Cookies.get("jwtToken") as string;
    if (jwtToken) {
      try {
        const decodeCookie: any = await jwtDecode(jwtToken);
        const expiration = decodeCookie?.exp * 1000;
        const username =
          decodeCookie[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
          ] || "Please Log In";
        const userId =
          decodeCookie[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
          ];
        setCurrentUserValues({
          expiration: expiration as any,
          jwtToken: jwtToken,
          username: username,
          userId: userId,
        });
      } catch (error) {}
    }
  };

  const resetPasswordDetails = async ({ email }: { email: string }) => {
    const response = await AxiosClientUtil.post(
      "/Auth/forgot-password",
      {},
      {
        params: { Email: email },
      }
    ).catch((err: any) => {});
    return response;
  };

  return {
    state,
    dispatch,
    handleLogin,
    checkIsLogin,
    showErrorModal,
    setShowErrorModal,
    resetPasswordDetails,
  };
}
