import React, { useEffect, useState } from "react";

interface TextAreaInputProps {
  onChange: (value: string) => void;
  value: string | undefined;
  required?: boolean;
  validationMessages?: { [key in any]: any };
  disabled?: boolean;
  placeholder?: string;
  watch?: any;
}
const StyledTextArea: React.FC<TextAreaInputProps> = ({
  onChange,
  value,
  required,
  validationMessages = { required: "This field is required" },
  disabled,
  placeholder = "the value is required",
  watch = true,
}) => {
  const [inputValue, setInputValue] = useState(value || "");
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    if (validateInput(newValue)) {
      onChange(event.target.value);
    }
    if (newValue === "") {
      // this is to prevent a single character from being left behind when the user manually deletes the values
      onChange("");
    }
  };
  const [style, setStyle] = useState(
    "block w-full border border-rose-500 p-1.5 text-dark-gray shadow-sm ring-2 ring-inset ring-light-red-100 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
  );

  const [currentValidationMessage, setCurrentValidationMessage] = useState(
    required
      ? validationMessages.required
        ? validationMessages.required
        : required
        ? required //returns true
        : ""
      : ""
  );

  const validateInput = (newValue: string) => {
    let isValid = true;
    let message = "";
    if (required && newValue === "") {
      isValid = false;
      message = validationMessages.required;
    } else if (watch && required && newValue === "") {
      isValid = false;
      message = validationMessages.required;
    }
    setCurrentValidationMessage(message);

    return isValid;
  };

  //this use effect is used to change the styling of the text area upon changing the "watch" value, which is a boolean
  // the style changes from a normal text area to a text area with a red border
  useEffect(() => {
    if (watch) {
      setStyle(
        "block w-full border border-light-red-100 p-1.5 text-dark-gray shadow-sm ring-1 ring-inset ring-light-red-100 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 font-bold placeholder:font-normal"
      );
    } else {
      setStyle(
        "block w-full border border-light-gray-100 p-1.5 text-dark-gray shadow-sm ring-1 ring-inset ring-gray placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
      );
    }
    // these variables will be used to reset the text area's value on change
    // setInputValue("");
    // onChange("");
  }, [watch]);

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  return (
    <>
      <textarea
        disabled={disabled}
        id="message"
        className={style}
        placeholder={watch ? placeholder : ""}
        onChange={handleChange}
        value={value || ""}
        data-testid="text-area"
      ></textarea>
      {currentValidationMessage
        ? required &&
          !inputValue && (
            <p className="mt-2 text-red text-xs">
              {validationMessages?.required || "Field is required"}
            </p>
          )
        : currentValidationMessage &&
          !inputValue && (
            <p className="mt-2 text-red text-xs">
              {currentValidationMessage || "Field is Required"}
            </p>
          )}
    </>
  );
};

export default StyledTextArea;
