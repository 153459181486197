import { lazy, Suspense, useEffect, useState } from "react";
import ProgressHomePageFeature from "./homePageFeatures/progressHomePageFeature";
import Top10CountriesHomePageFeature from "./homePageFeatures/top10CountriesHomePageFeature";
import TotalSVRegisteredHomePageFeature from "./homePageFeatures/totalSVRegisteredHomePageFeature";
import { useHomepage } from "../../hooks/useHomePage";
import Loading from "../../components/loading";
import Top10HighestWithdrawHomePageFeature from "./homePageFeatures/top10HighestWithdrawHomePageFeature";
import { Skeleton } from "antd";
const MapComponent = lazy(() => import("../../components/mapComponent"));

const HomePage = () => {
  const { state, getHomePageData } = useHomepage();
  const [homePageDataParams, setHomePageDataParams] = useState(null);

  useEffect(() => {
    getHomePageData({ filterParams: homePageDataParams });
  }, [homePageDataParams]);

  return (
    <>
      {state.isLoading && <Loading />}
      <>
        <div className="flex bg-light-gray-100 h-screen">
          <div className="flex flex-col w-full gap-3 h-full shrink ">
            <div className="flex text-black gap-3 h-fit">
              <div className="bg-white basis-2/12 rounded-lg shrink flex border border-gray">
                <TotalSVRegisteredHomePageFeature data={state.homePageData} />
              </div>
              <div className="bg-white basis-5/12 rounded-lg shrink border border-gray">
                <ProgressHomePageFeature
                  data={state.homePageData}
                  setHomePageDataParams={setHomePageDataParams}
                />
              </div>
              <div className="bg-white basis-2/12 rounded-lg shrink border border-gray">
                <Top10CountriesHomePageFeature data={state.homePageData} />
              </div>
              <div className="bg-white basis-3/12 rounded-lg shrink border border-gray">
                <Top10HighestWithdrawHomePageFeature
                  data={state.homePageData}
                />
              </div>
            </div>
            <div className="w-full bg-white h-3/6 rounded-lg shrink border border-gray">
              <div className="w-full h-full">
                <Suspense fallback={<Skeleton active />}>
                  <MapComponent
                    wrapperDivClassName={"w-full h-full"}
                    locationMarkers={state.homePageData.svLocations}
                    zoom={3}
                  />
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default HomePage;
