import { IJustificationData } from "../../../types/JobType";

type IJustificationDataRow = {
  no: number;
  justification: string;
  answer: string;
};

const justificationDataGenerator = ({
  justificationData,
}: {
  justificationData: IJustificationData;
}) => {
  const formattedJustificationData: IJustificationDataRow[] = [
    {
      no: 1,
      justification: "Building Lobby Area",
      answer: justificationData?.buildingLobbyArea ?? "NA",
    },
    {
      no: 2,
      justification: "Building Directory List",
      answer: justificationData?.buildingDirectoryList ?? "NA",
    },
    {
      no: 3,
      justification: "Target Unit Front Door Entrance",
      answer: justificationData?.targetUnitFrontDoorEntrance ?? "NA",
    },
    {
      no: 4,
      justification: "Subject Company Signage",
      answer: justificationData?.subjectCompanySignage ?? "NA",
    },
    {
      no: 5,
      justification: "Subject Company Inside the Office",
      answer: justificationData?.subjectCompanyInsideOffice ?? "NA",
    },
    {
      no: 6,
      justification: "Business Card",
      answer: justificationData?.businessCard ?? "NA",
    },
    {
      no: 7,
      justification: "Key Executive Photo",
      answer: justificationData?.keyExecutivePhoto ?? "NA",
    },
  ];
  return formattedJustificationData;
};

export default justificationDataGenerator;
