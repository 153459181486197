import Pagination from "../../components/pagination";
import DropdownKebab from "../../components/dropdownKebab";
import useRoles from "../../hooks/useRoles";
import { useContext, useEffect, useState } from "react";
import CustomButton from "../../components/button";
import CustomModal from "../../components/modal";
import { Controller, useForm } from "react-hook-form";
import Textbox from "../../components/textbox";
import Button from "../../components/button";
import ModalPopup from "../../components/modalPopUp";
import ConfirmationPopupModal from "../../components/confirmationPopupModal";
import { Checkbox, Table } from "antd";
import CustomDropdownSingleSelect from "../../components/customDropdownSingleSelect";
import useDropDown from "../../hooks/useDropDown";
import { Access, IRoles } from "../../types/RoleType";
import { RoleAccessContext } from "../../context/RoleAccessContext";

const RoleListing = () => {
  const { roleAccessObj } = useContext(RoleAccessContext);

  const roleAccess = roleAccessObj.find(
    (x) => x.module.id === 4 && x.page.id === 6
  );

  const [readAccess, setReadAccess] = useState(false);
  const [writeAccess, setWriteAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [updateAccess, setUpdateAccess] = useState(false);

  const [dropdownKebabItem, setDropdownKebabItem] = useState<
    { value: string; label: string }[]
  >([]);

  useEffect(() => {
    if (roleAccess) {
      setReadAccess(roleAccess?.read);
      setDeleteAccess(roleAccess?.delete);
      setWriteAccess(roleAccess?.write);
      setUpdateAccess(roleAccess?.update);
    }

    if (roleAccess?.update) {
      setDropdownKebabItem((prev) => [
        ...prev,
        { value: "editRole", label: "Edit Role" },
      ]);
    }
    if (roleAccess?.delete) {
      setDropdownKebabItem((prev) => [
        ...prev,
        { value: "delete", label: "Delete" },
      ]);
    }
  }, [roleAccessObj]);

  const {
    state,
    getRolesList,
    createNewRole,
    deleteRole,
    updateRole,
    deleteRoleAccess,
  } = useRoles();

  const { state: dropdownState, getModulesList, getPagesList } = useDropDown();

  const [currentGridFilters, setCurrentGridFilters] = useState<any>({
    pageSize: 10,
    pageIndex: 1,
    SortColumn: "Id",
    Sort: 0,
  });

  const [showCreateRoleModal, setShowCreateRoleModal] =
    useState<boolean>(false);

  const [showAddRoleAccessModal, setShowAddRoleAccessModal] =
    useState<boolean>(false);

  const [showPopUpModal, setShowPopUpModal] = useState<boolean>(false);
  const [popupModalContent, setPopupModalContent] = useState<string>("");

  const [selectedRoleId, setSelectedRoleId] = useState<{
    id: number;
    name: string;
  }>();

  const [showDeleteRoleWarning, setShowDeleteRoleWarning] =
    useState<boolean>(false);

  const handlePageClick = (pageIndex: number) => {
    if (readAccess) {
      getRolesList({ ...currentGridFilters, pageIndex: pageIndex });
      setCurrentGridFilters({
        ...currentGridFilters,
        pageIndex: pageIndex,
      });
    } else {
      setShowAlertModal({
        title: "Access Denied",
        message: "You do not have read access to this.",
      });
    }
  };

  const { control, formState, handleSubmit, reset } = useForm();

  const [showAlertModal, setShowAlertModal] = useState<{
    title: string;
    message: string;
  }>({
    title: "",
    message: "",
  });

  const submitNewRoleForm = async (formData: any) => {
    const data = {
      name: formData.roleName,
    };
    const res = await createNewRole(data);
    if (res.status === 200) {
      setShowCreateRoleModal(false);
      setPopupModalContent("New role created successfully");
      setShowPopUpModal(true);
      refreshGrid();
    }
  };

  const submitRoleUpdate = async (formData: any) => {
    if (selectedRoleId) {
      const res = await updateRole(selectedRoleId.id, formData);
      if (res.status === 200) {
        setShowAddRoleAccessModal(false);
        setShowPopUpModal(true);
        setPopupModalContent("Role name updated successfully");
        refreshGrid();
      }
    }
    return;
  };

  const refreshGrid = async () => {
    getRolesList({ ...currentGridFilters, pageIndex: 1 });
  };

  const handleDropdownChange = async (
    value: string,
    role: { id: number; name: string }
  ) => {
    if (value === "delete") {
      setSelectedRoleId(role);
      setShowDeleteRoleWarning(true);
    } else if (value === "editRole") {
      setSelectedRoleId(role);
      setShowAddRoleAccessModal(true);
    }
  };

  const handleDeleteRole = async () => {
    if (selectedRoleId?.id) {
      const res = await deleteRole(selectedRoleId.id);
      if (res.status === 200) {
        setShowDeleteRoleWarning(false);
        setPopupModalContent("Role deleted successfully");
        setShowPopUpModal(true);
        refreshGrid();
      }
    }
  };

  //role access functions
  const [allowRoleNameUpdateSubmitButton, setAllowRoleNameUpdateSubmitButton] =
    useState(false);

  const [isNewRecord, setIsNewRecord] = useState<boolean>(false);
  const [formType, setFormType] = useState<string>("edit");
  const [editableRowKeys, setEditableRowKeys] = useState<number[]>([]);
  const [currentKey, setCurrentKey] = useState(0);
  const [dataSource, setDataSource] = useState<Access[]>([]);
  const [originalData, setOriginalData] = useState<Access[]>([]);

  const [showDeleteRoleAccessWarning, setShowDeleteRoleAccessWarning] =
    useState<boolean>(false);

  const [selectedRoleAccessId, setSelectedRoleAccessId] = useState<number>();

  const startEdit = (key: number, type: string) => {
    setCurrentKey(key);
    if (type === "new") {
      setFormType("new");
      setIsNewRecord(true);
    } else {
      setFormType("edit");
      setIsNewRecord(false);
    }
    setEditableRowKeys((prev) => [...prev, key]);
  };

  const saveEdit = async (record: Access, type: string) => {
    setCurrentKey(record?.key);
    const editedRow = dataSource.find((item) => item?.key === record?.key);
    let roleAccess = [];
    if (editedRow && selectedRoleId) {
      if (type === "new") {
        if (!editedRow.pageId) {
          setPopupModalContent(
            "Please fill in both Module and Page before saving."
          );
          setShowPopUpModal(true);
          return;
        }
        roleAccess.push({
          id: 0,
          pageId: editedRow.pageId,
          read: editedRow.read,
          write: editedRow.write,
          update: editedRow.update,
          delete: editedRow.delete,
        });
      } else {
        roleAccess.push({
          id: editedRow.key,
          pageId: editedRow.pageId,
          read: editedRow.read,
          write: editedRow.write,
          update: editedRow.update,
          delete: editedRow.delete,
        });
      }
      const data = {
        name: selectedRoleId?.name,
        accessRights: roleAccess,
      };
      const res = await updateRole(selectedRoleId.id, data);
      if (res.status === 200) {
        setEditableRowKeys([]);
        setCurrentKey(0);
        setShowCreateRoleModal(false);
        setPopupModalContent("Role Access updated successfully");
        setShowPopUpModal(true);
        refreshGrid();
      }
    }
  };

  const cancelRoleAccessAction = (record: Access, type: string) => {
    setCurrentKey(0);
    if (type === "edit") {
      const originalRow = originalData.find((item) => item.key === record.key);
      if (originalRow) {
        setDataSource((prev) =>
          prev?.map((item) => {
            return item.key === record.key ? originalRow : item;
          })
        );
      }
      setEditableRowKeys((prev) => prev.filter((k) => k !== record.key));
    } else {
      setDataSource((prev) => prev.slice(0, -1));
    }
  };

  const handleCheckboxChange = (key: number, accessType: keyof Access) => {
    setDataSource((prev) =>
      prev?.map((item: Access) =>
        item.key === key ? { ...item, [accessType]: !item[accessType] } : item
      )
    );
  };

  const handleSelectPage = (key: number, pageId: number) => {
    setDataSource((prev) =>
      prev?.map((item: Access) => {
        return item.key === key ? { ...item, pageId: pageId } : item;
      })
    );
  };

  const addNewRow = () => {
    const newKey = dataSource.length + 1; // Simple key generation
    const newRow: Access = {
      key: newKey,
      moduleId: 0,
      module: "",
      pageId: 0,
      page: "",
      read: false,
      write: false,
      update: false,
      delete: false,
    };

    setFormType("new");
    setDataSource((prev) => [...prev, newRow]);
    setIsNewRecord(true);
    startEdit(newKey, "new"); // Make the new row editable immediately
  };

  const handleDeleteRoleAccess = async () => {
    if (selectedRoleAccessId) {
      const res = await deleteRoleAccess(selectedRoleAccessId);
      if (res.status === 200) {
        setShowDeleteRoleAccessWarning(false);
        setPopupModalContent("Role Access deleted successfully");
        setShowPopUpModal(true);
        refreshGrid();
      }
    }
  };

  const columns = [
    {
      title: "Module",
      dataIndex: "module",
      key: "module",
      width: "25%",
      render: (text: string, record: Access) =>
        editableRowKeys.includes(record?.key) && isNewRecord ? (
          <CustomDropdownSingleSelect
            onChange={(value) => {
              getPagesList(value);
            }}
            options={dropdownState.modules?.map(
              (item: { id: number; name: string }) => {
                return { label: item.name, value: item.id };
              }
            )}
            value={undefined}
          />
        ) : (
          text
        ),
    },
    {
      title: "Page",
      dataIndex: "page",
      key: "page",
      width: "25%",
      render: (text: string, record: Access) =>
        editableRowKeys.includes(record?.key) && isNewRecord ? (
          <CustomDropdownSingleSelect
            onChange={(value) => handleSelectPage(record.key, value)}
            options={dropdownState.pages?.map(
              (item: { id: number; name: string }) => {
                return { label: item.name, value: item.id };
              }
            )}
            value={undefined}
          />
        ) : (
          text
        ),
    },
    {
      title: () => <div className="flex justify-center">Read Access</div>,
      dataIndex: "read",
      key: "read",
      render: (text: string, record: Access) => (
        <div className="flex justify-center">
          <Checkbox
            checked={record?.read}
            disabled={!editableRowKeys.includes(record?.key)}
            onChange={() => handleCheckboxChange(record?.key, "read")}
          />
        </div>
      ),
    },
    {
      title: () => <div className="flex justify-center">Write Access</div>,
      dataIndex: "write",
      key: "write",
      render: (text: string, record: Access) => (
        <div className="flex justify-center">
          <Checkbox
            checked={record?.write}
            disabled={!editableRowKeys.includes(record?.key)}
            onChange={() => handleCheckboxChange(record?.key, "write")}
          />
        </div>
      ),
    },
    {
      title: () => <div className="flex justify-center">Update Access</div>,
      dataIndex: "update",
      key: "update",
      render: (text: string, record: Access) => (
        <div className="flex justify-center">
          <Checkbox
            checked={record?.update}
            disabled={!editableRowKeys.includes(record?.key)}
            onChange={() => handleCheckboxChange(record?.key, "update")}
          />
        </div>
      ),
    },
    {
      title: () => <div className="flex justify-center">Delete Access</div>,
      dataIndex: "delete",
      key: "delete",
      render: (text: string, record: Access) => (
        <div className="flex justify-center">
          <Checkbox
            checked={record?.delete}
            disabled={!editableRowKeys.includes(record?.key)}
            onChange={() => handleCheckboxChange(record?.key, "delete")}
          />
        </div>
      ),
    },
    {
      title: () => <div className="flex justify-center">Action</div>,
      key: "action",
      render: (text: string, record: Access) => {
        const isEditing = editableRowKeys.includes(record?.key);
        const isAnotherRowEditing =
          currentKey !== 0 && currentKey !== record?.key;

        return isEditing ? (
          <div className="flex justify-between gap-1">
            <Button
              onClick={() => saveEdit(record, formType)}
              variant="primarygreen"
            >
              Save
            </Button>
            <Button
              onClick={() => cancelRoleAccessAction(record, formType)}
              variant="primarycancel"
            >
              Cancel
            </Button>
          </div>
        ) : (
          <div className="flex justify-center gap-1">
            <Button
              onClick={() => startEdit(record?.key, "edit")}
              variant="outline"
              disabled={isAnotherRowEditing}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                setSelectedRoleAccessId(record?.key);
                setShowDeleteRoleAccessWarning(true);
              }}
              variant="danger"
              disabled={isAnotherRowEditing}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  const getRoleAccessList = () => {
    setDataSource(
      state?.rolesList?.items
        ?.filter((roleAccess: IRoles) => roleAccess.id === selectedRoleId?.id)
        ?.map((roleAccess: IRoles) => {
          // Assuming roleAccess.accessRights is an array
          return roleAccess.accessRights?.map((item) => ({
            key: item.id,
            moduleId: item.module?.id,
            module: item.module?.name,
            pageId: item.page?.id,
            page: item.page?.name,
            read: item.read,
            write: item.write,
            update: item.update,
            delete: item.delete,
          }));
        })
        .flat()
    );
    setOriginalData(
      state?.rolesList?.items
        ?.filter((roleAccess: IRoles) => roleAccess.id === selectedRoleId?.id)
        ?.map((roleAccess: IRoles) => {
          // Assuming roleAccess.accessRights is an array
          return roleAccess.accessRights?.map((item) => ({
            module: item.module?.name,
            page: item.page?.name,
            read: item.read,
            write: item.write,
            update: item.update,
            delete: item.delete,
          }));
        })
        .flat()
    );
  };

  useEffect(() => {
    refreshGrid();
    getModulesList();
  }, []);

  useEffect(() => {
    if (showAddRoleAccessModal) {
      getRoleAccessList();
    }
  }, [showAddRoleAccessModal]);

  useEffect(() => {
    getRoleAccessList();
  }, [state.rolesList]);

  return (
    <>
      <div className="p-5 border border-gray rounded-lg bg-white">
        <div className="block px-5 mb-5">
          <label className="mb-0 block font-bold">Roles</label>
        </div>

        <ModalPopup
          visible={showPopUpModal}
          onClose={() => setShowPopUpModal(false)}
          content={popupModalContent}
        />

        <ModalPopup
          visible={!!showAlertModal.title || !!showAlertModal.message}
          onClose={() => setShowAlertModal({ title: "", message: "" })}
          title={showAlertModal?.title}
          content={<p>{showAlertModal?.message}</p>}
        />

        <ConfirmationPopupModal
          showModal={showDeleteRoleWarning}
          setShowModal={setShowDeleteRoleWarning}
          displayHeader={"Delete Role"}
          displayText1={
            "This action cannot be undone and may result in loss of data."
          }
          displayText2={"Confirm delete role?"}
          handleAction={handleDeleteRole}
        />

        <ConfirmationPopupModal
          showModal={showDeleteRoleAccessWarning}
          setShowModal={setShowDeleteRoleAccessWarning}
          displayHeader={"Delete Role Access"}
          displayText1={
            "This action cannot be undone and may result in loss of data."
          }
          displayText2={"Confirm delete role access?"}
          handleAction={handleDeleteRoleAccess}
        />

        <CustomModal
          width={"30%"}
          title={"Create Role"}
          open={showCreateRoleModal}
          onOk={() => {}}
          onCancel={() => {
            setShowCreateRoleModal(false);
          }}
          footer={null}
        >
          <div className="border-gray p-4">
            <form onSubmit={handleSubmit(submitNewRoleForm)}>
              <label>Role Name:</label>
              <Controller
                control={control}
                name="roleName"
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <Textbox
                    required
                    type="text"
                    onChange={field.onChange}
                    value={field.value}
                    validationMessages={{
                      required: formState.errors?.roleName?.message || " ",
                    }}
                  />
                )}
              />
              <div className="pt-3 flex justify-end">
                <Button type="submit" variant="primaryorange">
                  <span className="text-white">Submit</span>
                </Button>
              </div>
            </form>
          </div>
        </CustomModal>

        <CustomModal
          width={"90%"}
          title={"Edit Role: " + selectedRoleId?.name}
          open={showAddRoleAccessModal}
          onOk={() => {}}
          onCancel={() => {
            reset();
            setShowAddRoleAccessModal(false);
          }}
          footer={null}
        >
          <div className="border-gray p-4">
            {readAccess && (
              <form onSubmit={handleSubmit(submitRoleUpdate)}>
                <div className="flex justify-start items-center gap-3">
                  <div className="basis-1/6">
                    <label>Role Name:</label>
                  </div>
                  <div className="basis-3/6">
                    <Controller
                      control={control}
                      name="name"
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <Textbox
                          value={selectedRoleId?.name}
                          onChange={(value) => {
                            field.onChange(value);
                            setAllowRoleNameUpdateSubmitButton(true);
                          }}
                          validationMessages={{
                            required: formState.errors?.name?.message || " ",
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="basis-1/6">
                    <Button
                      type="submit"
                      variant={
                        allowRoleNameUpdateSubmitButton
                          ? "primaryorange"
                          : "primaryorange-disabled"
                      }
                      disabled={!allowRoleNameUpdateSubmitButton}
                    >
                      <span className="text-white">Submit</span>
                    </Button>
                  </div>
                </div>
              </form>
            )}
            <div className="w-1/6 pt-3">
              <Button
                onClick={addNewRow}
                variant="primarygreen"
                style={{
                  marginBottom: 16,
                }}
              >
                Add Role Access
              </Button>
            </div>
            <div className="pt-3">
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
              />
            </div>
          </div>
        </CustomModal>

        {writeAccess && (
          <div className="grid grid-cols-4 grid-rows-1 p-5">
            <div className="block">
              <CustomButton
                type="button"
                children={"Create New Role"}
                variant={"primaryorange"}
                onClick={() => {
                  setShowCreateRoleModal(true);
                }}
              />
            </div>
          </div>
        )}

        <div className="block px-5 mb-10">
          <div className="relative  mb-10 min-h-96">
            <table className="w-full text-sm text-left text-black">
              <thead className="text-xs border-b border-b-light-gray-100">
                <tr>
                  <th className="px-6 py-3">Role ID</th>
                  <th className="px-6 py-3">Role Name</th>
                </tr>
              </thead>
              <tbody>
                {state.rolesList?.items?.map(
                  (role: { id: number; name: string }) => {
                    return (
                      <tr className="bg-white border-b border-b-light-gray-100">
                        <td className="px-6 py-41">{role.id}</td>
                        <td scope="row" className="px-6 py-4">
                          {role.name}
                        </td>
                        {updateAccess && (
                          <td className="px-6 py-4 text-center">
                            <DropdownKebab
                              onChange={(value) =>
                                handleDropdownChange(value, role)
                              }
                              items={dropdownKebabItem}
                            />
                          </td>
                        )}
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
          <div className="flex justify-start">
            <div>
              <Pagination
                currentPage={state.rolesList?.pageIndex}
                totalPages={state.rolesList?.totalPages}
                onClickPage={handlePageClick}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoleListing;
