import { IMenuItem } from "../types/MenuItemTypes";
import home from "../assets/home.svg";
import account_circle from "../assets/account_circle.svg";
import notifications from "../assets/notifications.svg";
import settings from "../assets/settings.svg";

export const SubMenuData: IMenuItem[] = [
  {
    text: "Home",
    icon: home,
    link: "/",
    id: 0,
    name: "home",
    children: null,
  },
  {
    text: "Log out",
    icon: "",
    link: "/login",
    id: 2,
    name: "log-out",
    children: null,
  },
  {
    text: "Profile",
    icon: account_circle,
    link: "/administration-user",
    id: 3,
    name: "profile",
    children: null,
  },
];
