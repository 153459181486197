import { UseFormSetValue, FieldValues } from "react-hook-form";

type JobListingPageType = {
  id: string;
  jobType: string;
  buttonNameProps: {
    value: any;
    label: any;
  }[];
};

const jobPageTypeMap: JobListingPageType[] = [
  {
    id: "/create",
    jobType: "Create",
    buttonNameProps: [],
  },
  {
    id: "/draft",
    jobType: "Draft",
    buttonNameProps: [
      { value: "view", label: "View" },
      { value: "draft", label: "Edit" },
      { value: "remove", label: "Remove" },
    ],
  },
  {
    id: "/open",
    jobType: "Open",
    buttonNameProps: [
      { value: "view", label: "View" },
      { value: "edit", label: "Edit" },
      { value: "invite", label: "Invite SV" },
      { value: "remove", label: "Remove" },
    ],
  },
  {
    id: "/pending",
    jobType: "Pending Approval",
    buttonNameProps: [
      { value: "approve", label: "View" },
      { value: "editExisting", label: "Edit" },
      { value: "invite", label: "Invite SV" },
      { value: "cancel", label: "Cancel" },
    ],
  },
  {
    id: "/approved",
    jobType: "Approved",
    buttonNameProps: [
      { value: "view", label: "View" },
      { value: "editExisting", label: "Edit" },
      { value: "cancel", label: "Cancel" },
    ],
  },
  {
    id: "/accepted",
    jobType: "Accepted",
    buttonNameProps: [
      { value: "view", label: "View" },
      { value: "editExisting", label: "Edit" },
      { value: "cancel", label: "Cancel" },
    ],
  },
  {
    id: "/withdraw",
    jobType: "Withdraw",
    buttonNameProps: [
      { value: "view", label: "View" },
      { value: "cancel", label: "Cancel" },
    ],
  },
  {
    id: "/in-progress",
    jobType: "In Progress",
    buttonNameProps: [
      { value: "view", label: "View" },
      { value: "editExisting", label: "Edit" },
      { value: "cancel", label: "Cancel" },
    ],
  },
  {
    id: "/submitted",
    jobType: "Submitted",
    buttonNameProps: [
      { value: "submit", label: "View" },
      { value: "cancel", label: "Cancel" },
    ],
  },
  {
    id: "/completed",
    jobType: "Completed",
    buttonNameProps: [{ value: "view", label: "View" }],
  },

  {
    id: "/cancelled",
    jobType: "Cancelled",
    buttonNameProps: [{ value: "view", label: "View" }],
  },
];

/**
 * changeUIDisplayByURL changes the job status search filter based on the current pathname
 *
 * @param setValueOfFilter - takes a referene to the job status search filter for further manipulation
 * @param {string} jobPagePathname - takes the pathname of the jobs
 * @returns
 */
export const changeUIDisplayByURL = ({
  setValueOfFilter,
  jobPagePathname,
}: {
  setValueOfFilter: UseFormSetValue<FieldValues>;
  jobPagePathname: string;
}) => {
  let pathname = jobPagePathname;
  let setValue: UseFormSetValue<FieldValues> = setValueOfFilter;
  let jobPageType: JobListingPageType | undefined;

  return {
    /**
     * findJobPageType finds the corresponding job status in the jobPageTypeMap based on the pathname
     * @returns changeUIDisplayByURL object
     */
    findJobPageType: function () {
      jobPageType = jobPageTypeMap.find((jpt) => pathname.includes(jpt.id));
      return this;
    },

    /**
     * setValueOfJobStatus changes the value of the job status search filter
     * @returns changeUIDisplayByURL object
     */
    setValueOfJobStatus: function () {
      setValue("jobStatus", jobPageType?.jobType);
      return this;
    },
  };
};

/**
 * A function to create button properties for the each job row based on the job status
 *
 */
export const createPropsForModalURL = ({
  jobStatus,
}: {
  jobStatus: string;
}) => {
  let jobPageType: JobListingPageType | undefined;
  return {
    /**
     * findJobPageType finds the corresponding job status in the jobPageTypeMap for each job
     * @returns createPropsForModalURL object
     */
    findJobPageType: function () {
      jobPageType = jobPageTypeMap.find((jobPageType) => {
        return jobStatus.includes(jobPageType.jobType);
      });
      return this;
    },
    /**
     * returnButtonProps retrieves the buttons for the kebab menu based on the job status of each job,
     * - If the reopen flag is provided for the job, then the function inserts the button for reopen
     *
     * @param {boolean} isReopenJob - to indicate if job should be reopened
     * @returns the button properties
     */
    returnButtonProps: function ({ isReopenJob }: { isReopenJob: boolean }) {
      if (isReopenJob) {
        const existingButtonProps: any = [
          ...(jobPageType?.buttonNameProps as any),
        ];
        if (
          isReopenJob &&
          (jobPageType?.jobType === "Approved" ||
            jobPageType?.jobType === "Withdraw")
        ) {
          existingButtonProps.push({ value: "reopen", label: "Reopen" });
        }
        return [...existingButtonProps];
      }

      return jobPageType?.buttonNameProps ?? [];
    },
  };
};

/**
 * This function is to determine which job status should be passed to the search filter function for jobs
 * @param {string} jobPagePathname
 * @param {any[]} jobPageStatusList
 * @returns
 */
export const createPropsForRetrievingJobs = ({
  jobPagePathname,
  jobPageStatusList,
}: {
  jobPagePathname: string;
  jobPageStatusList: any[];
}) => {
  let pathname: string = jobPagePathname;
  let jobStatusList: any[] = jobPageStatusList;
  let filteredJobStatus: any;
  return {
    /**
     * retrieveJobStatusForURL finds the corresponding job status based on the pathname
     * and retrieves the specific job status record which contains the name and id of the job status
     * @returns createPropsForRetrievingJobs object
     */
    retrieveJobStatusForURL: function () {
      filteredJobStatus = jobStatusList?.find((jobStatus: any) => {
        if (jobStatus.name === "In Progress") {
          return pathname.includes("in-progress");
        }
        if (jobStatus.name === "Pending Approval") {
          return pathname.includes("pending");
        }
        return pathname.includes(jobStatus.name.toString().toLowerCase());
      });
      return this;
    },
    /**
     * returnJobStatusId returns the job status id that has been retrieved
     * @returns the Job status ID
     */
    returnJobStatusId: function () {
      return filteredJobStatus?.id;
    },
  };
};
