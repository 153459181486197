import { useCallback, useReducer } from "react";
import { AxiosClientUtil } from "../utils/httpUtils/AxiosClientUtil";
import { IUserDetailsType } from "../types/UserType";

type stateType = {
  isLoading: boolean;
  userList: [];
  userDetails: [];
  roles: [];
  sortBy: [];
  countries: [];
};

const initialState: stateType = {
  isLoading: false,
  userList: [],
  userDetails: [],
  roles: [],
  sortBy: [],
  countries: [],
};

const reducer = (state: any, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, isLoading: action.payload };
    case "GET_USER_LIST":
      return { ...state, userList: action.payload };
    case "GET_USER_DETAILS":
      return { ...state, userDetails: action.payload };
    case "SET_ROLES":
      return { ...state, roles: action.payload };
    case "SET_SORT_BY":
      return { ...state, sortBy: action.payload };
    case "SET_COUNTRIES":
      return { ...state, countries: action.payload };
  }
};

const useAdministration = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const memoizedDispatch = useCallback(dispatch, [dispatch]);

  const memoizedJobActions = {
    createNewUser: useCallback(
      async (payload: IUserDetailsType) => {
        try {
          const response = await AxiosClientUtil.post(
            `/User/register`,
            payload
          );
          return { status: response?.status, data: response?.data };
        } catch (err) {
          throw err;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    updateExistingUser: useCallback(
      async (id: number, payload: IUserDetailsType) => {
        try {
          const response = await AxiosClientUtil.put(`/User/${id}`, payload);
          return { status: response?.status, data: response?.data };
        } catch (err) {
          throw err;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    getUserList: useCallback(
      async ({ pageSize, pageIndex, sort, filterParams }: any) => {
        const filters = {
          Email: filterParams?.Email,
          Name: filterParams?.Name,
          RoleId: filterParams?.RoleId,
        };
        const populateFilters = Object.fromEntries(
          Object.entries(filters).filter(([_, value]) => value !== "")
        );
        memoizedDispatch({ type: "SET_LOADING", payload: true });
        try {
          const response = await AxiosClientUtil.get("/User/list", {
            params: {
              PageSize: pageSize,
              PageIndex: pageIndex,
              Sort: sort,
              ...populateFilters,
            },
          });
          memoizedDispatch({
            type: "GET_USER_LIST",
            payload: response?.data,
          });
          return response?.data;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    getUserDetailsById: useCallback(
      async (id: number) => {
        try {
          const response = await AxiosClientUtil.get(`/User/${id}`);
          memoizedDispatch({
            type: "GET_USER_DETAILS",
            payload: response?.data,
          });
          return response?.data;
        } catch (err) {
          throw err;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    getSortByDropDownList: useCallback(async () => {
      try {
        const response = await AxiosClientUtil.get(`/DropdownList/sortby`);
        memoizedDispatch({
          type: "SET_SORT_BY",
          payload: response?.data,
        });
        return response?.data;
      } catch (err) {
        throw err;
      } finally {
        memoizedDispatch({ type: "SET_LOADING", payload: false });
      }
    }, [memoizedDispatch]),

    getRolesDropDownList: useCallback(async () => {
      try {
        const response = await AxiosClientUtil.get(`/DropdownList/roles`);
        memoizedDispatch({
          type: "SET_ROLES",
          payload: response?.data,
        });
        return response?.data;
      } catch (err) {
        throw err;
      } finally {
        memoizedDispatch({ type: "SET_LOADING", payload: false });
      }
    }, [memoizedDispatch]),

    getCountriesDropDownList: useCallback(async () => {
      try {
        const response = await AxiosClientUtil.get(`/DropdownList/countries`);
        memoizedDispatch({
          type: "SET_COUNTRIES",
          payload: response?.data,
        });
        return response?.data;
      } catch (err) {
        throw err;
      } finally {
        memoizedDispatch({ type: "SET_LOADING", payload: false });
      }
    }, [memoizedDispatch]),

    deleteUserById: useCallback(
      async (id: number) => {
        try {
          const response = await AxiosClientUtil.delete(`/User/${id}`);
          // resolve(response?.data);
          return response?.data;
        } catch (err) {
          throw err;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    getRoleAccess: useCallback(async () => {
      try {
        const response = await AxiosClientUtil.get(`/User/access-rights`);
        return response?.data;
      } catch (err) {
        throw err;
      } finally {
        memoizedDispatch({ type: "SET_LOADING", payload: false });
      }
    }, [memoizedDispatch]),
  };

  return { state, dispatch, ...memoizedJobActions };
};

export default useAdministration;
