import { useCallback, useReducer } from "react";
import { AxiosClientUtil } from "../utils/httpUtils/AxiosClientUtil";
import { IInitialStateDropDown } from "../types/GenericHTTPType";

const initialState: IInitialStateDropDown = {
  isLoading: false,
  roles: [],
  countries: [],
  paymentMethods: [],
  jobStatus: [],
  svSortBy: [],
  sortBy: [],
  svFilter: [],
  yearRange: [],
  timezones: [],
  modules: [],
  pages: [],
};

const reducer = (
  state: IInitialStateDropDown,
  action: { type: string; payload?: boolean | string[] | any }
) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, isLoading: action.payload };
    case "SET_ROLES":
      return { ...state, roles: action.payload };
    case "SET_PAYMENT_METHODS":
      return { ...state, paymentMethods: action.payload };
    case "SET_COUNTRIES":
      return { ...state, countries: action.payload };
    case "SET_JOB_STATUS":
      return { ...state, jobStatus: action.payload };
    case "SET_SV_SORT_BY":
      return { ...state, svSortBy: action.payload };
    case "SET_SV_FILTER":
      return { ...state, svFilter: action.payload };
    case "SET_SORTBY":
      return { ...state, sortBy: action.payload };
    case "SET_JOB_YEAR_RANGE":
      return { ...state, yearRange: action.payload };
    case "SET_TIMEZONES":
      return { ...state, timezones: action.payload };
    case "SET_MODULES":
      return { ...state, modules: action.payload };
    case "SET_PAGES":
      return { ...state, pages: action.payload };
    default:
      return { ...state };
  }
};

const useDropDown = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const memoizedDispatch = useCallback(dispatch, [dispatch]);

  const memoizedJobActions = {
    getRolesDropDownList: useCallback(async () => {
      memoizedDispatch({ type: "SET_LOADING", payload: true });
      try {
        const response = await AxiosClientUtil.get(`/DropdownList/roles`);
        memoizedDispatch({
          type: "SET_ROLES",
          payload: response?.data,
        });
        return response?.data;
      } catch (error) {
      } finally {
        memoizedDispatch({ type: "SET_LOADING", payload: false });
      }
    }, [memoizedDispatch]),

    getPaymentMethodsDropDownList: useCallback(async () => {
      memoizedDispatch({ type: "SET_LOADING", payload: true });
      try {
        const response = await AxiosClientUtil.get(
          `/DropdownList/payment-methods`
        );
        memoizedDispatch({
          type: "SET_PAYMENT_METHODS",
          payload: response?.data,
        });
        return response?.data;
      } catch (error) {
      } finally {
        memoizedDispatch({ type: "SET_LOADING", payload: false });
      }
    }, [memoizedDispatch]),

    getCountriesDropDownList: useCallback(() => {
      return getCountriesList();
    }, [memoizedDispatch]),

    getJobStatusDropDownList: useCallback(async () => {
      memoizedDispatch({ type: "SET_LOADING", payload: true });
      try {
        const response = await AxiosClientUtil.get(`/DropdownList/job-status`);
        memoizedDispatch({
          type: "SET_JOB_STATUS",
          payload: response?.data,
        });
        return response?.data;
      } catch (error) {
      } finally {
        memoizedDispatch({ type: "SET_LOADING", payload: false });
      }
    }, [memoizedDispatch]),

    getSVSortByDropDownList: useCallback(async () => {
      memoizedDispatch({ type: "SET_LOADING", payload: true });
      try {
        const response = await AxiosClientUtil.get(`/DropdownList/sv-sortby`);
        memoizedDispatch({
          type: "SET_SV_SORT_BY",
          payload: response?.data,
        });
        return response?.data;
      } catch (error) {
      } finally {
        memoizedDispatch({ type: "SET_LOADING", payload: false });
      }
    }, [memoizedDispatch]),

    getSVFilterDropDownList: useCallback(async () => {
      memoizedDispatch({ type: "SET_LOADING", payload: true });
      try {
        const response = await AxiosClientUtil.get(`/DropdownList/sv-filter`);
        memoizedDispatch({
          type: "SET_SV_FILTER",
          payload: response?.data,
        });
        return response?.data;
      } catch (error) {
      } finally {
        memoizedDispatch({ type: "SET_LOADING", payload: false });
      }
    }, [memoizedDispatch]),

    getSortByDropDownList: useCallback(() => {
      return getSortByList();
    }, [memoizedDispatch]),

    getJobYearRangeDropDownList: useCallback(async () => {
      memoizedDispatch({ type: "SET_LOADING", payload: true });
      try {
        const response = await AxiosClientUtil.get(
          `/DropdownList/job-year-range`
        );
        memoizedDispatch({
          type: "SET_JOB_YEAR_RANGE",
          payload: response?.data,
        });
        return response?.data;
      } catch (error) {
      } finally {
        memoizedDispatch({ type: "SET_LOADING", payload: false });
      }
    }, [memoizedDispatch]),

    getTimezonesDropDownList: useCallback(async () => {
      memoizedDispatch({ type: "SET_LOADING", payload: true });
      try {
        const response = await AxiosClientUtil.get(`/DropdownList/timezones`);
        memoizedDispatch({
          type: "SET_TIMEZONES",
          payload: response?.data,
        });
        return response?.data;
      } catch (error) {
      } finally {
        memoizedDispatch({ type: "SET_LOADING", payload: false });
      }
    }, [memoizedDispatch]),

    //combining multiple api calls into a single function returning a promise
    getCountriesAndSortByList: useCallback(() => {
      const sortByList = getSortByList();
      const countriesList = getCountriesList();

      return Promise.all([countriesList, sortByList]);
    }, []),

    getModulesList: async () => {
      memoizedDispatch({ type: "SET_LOADING", payload: true });
      try {
        const response = await AxiosClientUtil.get(`/DropdownList/modules`);
        memoizedDispatch({
          type: "SET_MODULES",
          payload: response?.data,
        });
        return response?.data;
      } catch (error) {
      } finally {
        memoizedDispatch({ type: "SET_LOADING", payload: false });
      }
    },

    getPagesList: async (moduleId: number) => {
      memoizedDispatch({ type: "SET_LOADING", payload: true });
      try {
        const response = await AxiosClientUtil.get(
          `/DropdownList/pages/${moduleId}`
        );
        memoizedDispatch({
          type: "SET_PAGES",
          payload: response?.data,
        });
        return response?.data;
      } catch (error) {
      } finally {
        memoizedDispatch({ type: "SET_LOADING", payload: false });
      }
    },
  };

  const getCountriesList = async () => {
    memoizedDispatch({ type: "SET_LOADING", payload: true });
    try {
      const response = await AxiosClientUtil.get(`/DropdownList/countries`);
      memoizedDispatch({
        type: "SET_COUNTRIES",
        payload: response?.data,
      });
      return response?.data;
    } catch (error) {
    } finally {
      memoizedDispatch({ type: "SET_LOADING", payload: false });
    }
  };

  const getSortByList = async () => {
    memoizedDispatch({ type: "SET_LOADING", payload: true });
    try {
      const response = await AxiosClientUtil.get(`/DropdownList/sortby`);
      memoizedDispatch({
        type: "SET_SORTBY",
        payload: response?.data,
      });
      return response?.data;
    } catch (error) {
    } finally {
      memoizedDispatch({ type: "SET_LOADING", payload: false });
    }
  };

  return { state, dispatch, ...memoizedJobActions };
};

export default useDropDown;
