import saveAs from "file-saver";
import JSZip from "jszip";
// @ts-ignore
import * as JSZipUtils from "jszip-utils";

export async function generateZipFileAsync(zip: JSZip) {
  const content = await zip.generateAsync({ type: "blob" });
  return content;
}

export function saveZipFile(content: Blob, zipFileName: string) {
  saveAs(content, zipFileName);
}

export function addItemsToBeZipped(
  zip: JSZip,
  fileName: string,
  data: any,
  isBinary: boolean
) {
  zip.file(fileName, data, { binary: isBinary });
}

export function createPromiseForZipItem(
  zip: JSZip,
  url: string,
  filename: string
) {
  return new Promise((resolve, reject) => {
    JSZipUtils.getBinaryContent(url, function (err: any, data: any) {
      if (err) {
        reject(err);
      } else {
        zip.file(filename, data, { binary: true });
        resolve(true);
      }
    });
  });
}
