import { useCallback, useReducer } from "react";
import { AxiosClientUtil } from "../utils/httpUtils/AxiosClientUtil";
import { IInitialStatePerformanceData } from "../types/GenericHTTPType";

const initialState: IInitialStatePerformanceData = {
  isLoading: false,
  performanceData: {},
};

const reducer = (
  state: IInitialStatePerformanceData,
  action: { type: string; payload?: boolean | any }
) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, isLoading: action.payload };
    case "SET_PERFORMANCE_DATA":
      return { ...state, performanceData: action.payload };
    default:
      return { ...state };
  }
};

const usePerformance = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const memoizedDispatch = useCallback(dispatch, [dispatch]);

  const memoizedJobActions = {
    getPerformanceResults: useCallback(
      async (performanceParameters: { year: number }) => {
        memoizedDispatch({ type: "SET_LOADING", payload: true });
        try {
          const response = await AxiosClientUtil.get(`/Performance/yearly`, {
            params: performanceParameters,
          });
          memoizedDispatch({
            type: "SET_PERFORMANCE_DATA",
            payload: response?.data.value,
          });
          return response?.data;
        } catch (err) {
          throw err;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),
  };

  return { state, dispatch, ...memoizedJobActions };
};

export default usePerformance;
