import { useCallback, useReducer } from "react";
import { AxiosClientUtil } from "../utils/httpUtils/AxiosClientUtil";
import {
  CreateOrUpdateJobBodyDataType2,
  GetJobDetailsResponseBody,
  GetJobResponseBody,
  IJobAcceptedBodyData,
} from "../types/JobType";
import { IMapLocationMarkerType, IMapType } from "../types/MapDataType";

type JobInitialState = {
  isLoading: boolean;
  job: GetJobDetailsResponseBody | undefined;
  jobList: GetJobResponseBody[];
  locationMarker: IMapLocationMarkerType | undefined;
};

const initialState: JobInitialState = {
  isLoading: false,
  job: undefined,
  jobList: [],
  locationMarker: undefined,
};

const reducer = (
  state: JobInitialState,
  action: {
    type: string;
    payload?:
      | boolean
      | GetJobDetailsResponseBody
      | GetJobResponseBody[]
      | IMapLocationMarkerType
      | any;
  }
) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, isLoading: action.payload };
    case "SET_JOB":
      return { ...state, job: action.payload };
    case "SET_JOBLIST":
      return { ...state, jobList: action.payload };
    case "SET_LOCATION_MARKER":
      return { ...state, locationMarker: action.payload };
    default:
      return state;
  }
};

const useJobs = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const memoizedDispatch = useCallback(dispatch, [dispatch]);

  const memoizedJobActions = {
    createNewJob: useCallback(
      async ({ payload }: { payload: any }) => {
        memoizedDispatch({ type: "SET_LOADING", payload: true });
        try {
          const response = await AxiosClientUtil.post(`/Job`, payload);
          return { data: response?.data, statusCode: response?.status };
        } catch (err) {
          throw err;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    createDraftJobsDataById: useCallback(
      async ({ id, payload }: { id: number; payload: any }) => {
        memoizedDispatch({ type: "SET_LOADING", payload: true });
        try {
          const response = await AxiosClientUtil.post(
            `/Job/draft/${id}`,
            payload
          );
          return { data: response?.data, statusCode: response?.status };
        } catch (err) {
          throw err;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    createOpenJobsFromDraftJobsById: useCallback(
      async (id: number) => {
        try {
          memoizedDispatch({ type: "SET_LOADING", payload: true });
          const response = await AxiosClientUtil.post(`/Job/${id}/open`);
          return { data: response?.data, statusCode: response?.status };
        } catch (err) {
          throw err;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    createApprovedJobsDataById: useCallback(
      async ({ id, svId }: { id: number; svId: number }) => {
        try {
          memoizedDispatch({ type: "SET_LOADING", payload: true });
          const response = await AxiosClientUtil.post(`/Job/${id}/approved`, {
            svId: svId,
          });
          return { data: response?.data, statusCode: response?.status };
        } catch (err) {
          throw err;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    createCancelledJobsDataById: useCallback(
      async (id: number, reason: string) => {
        try {
          memoizedDispatch({ type: "SET_LOADING", payload: true });
          const response = await AxiosClientUtil.post(`/Job/${id}/cancelled`, {
            reason: reason,
          });
          return { data: response?.data, statusCode: response?.status };
        } catch (err) {
          throw err;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    createCompletedJobsDataById: useCallback(
      async ({
        id,
        payload,
      }: {
        id: number;
        payload: IJobAcceptedBodyData;
      }) => {
        memoizedDispatch({ type: "SET_LOADING", payload: true });
        try {
          memoizedDispatch({ type: "SET_LOADING", payload: true });
          const response = await AxiosClientUtil.post(
            `/Job/${id}/completed`,
            payload
          );
          return { data: response?.data, statusCode: response?.status };
        } catch (err) {
          throw err;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    createResubmittedJobsDataById: useCallback(
      async ({ id, reason }: { id: number; reason: string }) => {
        memoizedDispatch({ type: "SET_LOADING", payload: true });
        try {
          const response = await AxiosClientUtil.post(
            `/Job/${id}/resubmission`,
            { reason }
          );
          return { data: response?.data, statusCode: response?.status };
        } catch (err) {
          throw err;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    createReopenJobsDataById: useCallback(
      async (id: number) => {
        memoizedDispatch({ type: "SET_LOADING", payload: true });
        try {
          const response = await AxiosClientUtil.post(`/Job/${id}/reopen`);
          return { data: response?.data, statusCode: response?.status };
        } catch (err) {
          throw err;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    updateJobById: useCallback(
      async ({
        id,
        payload,
      }: {
        id: number;
        payload: CreateOrUpdateJobBodyDataType2;
      }) => {
        memoizedDispatch({ type: "SET_LOADING", payload: true });
        try {
          const response = await AxiosClientUtil.put(`/Job/${id}`, payload);
          return { data: response?.data, statusCode: response?.status };
        } catch (err) {
          throw err;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    getJobsDataById: useCallback(
      async (id: number) => {
        memoizedDispatch({ type: "SET_LOADING", payload: true });
        try {
          const response = await AxiosClientUtil.get(`/Job/${id}`);
          memoizedDispatch({ type: "SET_JOB", payload: await response?.data });
          return response?.data as GetJobDetailsResponseBody;
        } catch (err) {
          throw err;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    getAllJobsData: useCallback(
      async (payload: any) => {
        memoizedDispatch({ type: "SET_LOADING", payload: true });

        try {
          const response = await AxiosClientUtil.get(`/Job/list`, {
            params: payload,
          });
          memoizedDispatch({
            type: "SET_JOBLIST",
            payload: await response?.data,
          });
          return response?.data as GetJobResponseBody;
        } catch (err) {
          throw err;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    createMapPointData: useCallback(
      async (payload: IMapType) => {
        memoizedDispatch({ type: "SET_LOADING", payload: true });
        try {
          const response = await AxiosClientUtil.post(`Map`, payload as any);
          memoizedDispatch({
            type: "SET_LOCATION_MARKER",
            payload: response?.data,
          });
          return await response?.data;
        } catch (err) {
          throw err;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    deleteJobsDataById: useCallback(
      async (id: number) => {
        try {
          memoizedDispatch({ type: "SET_LOADING", payload: true });
          const response = await AxiosClientUtil.delete(`/Job/${id}`);
          return response?.data;
        } catch (err) {
          throw err;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    autoJobInvite: useCallback(
      async (id: number, range: number) => {
        try {
          memoizedDispatch({ type: "SET_LOADING", payload: true });
          const response = await AxiosClientUtil.post(
            `/Job/${id}/auto-release?range=${range}`
          );
          return response;
        } catch (err) {
          throw err;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    manualJobInvite: useCallback(
      async (id: number, selectedCandidate: number[]) => {
        try {
          memoizedDispatch({ type: "SET_LOADING", payload: true });
          const response = await AxiosClientUtil.post(
            `/Job/${id}/manual-release`,
            selectedCandidate
          );
          return response;
        } catch (err) {
          throw err;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),
  };

  return { state, dispatch, ...memoizedJobActions };
};

export default useJobs;
