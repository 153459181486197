import React from "react";
import ModalPopup from "../modalPopUp";
import Button from "../button";

type ConfirmationPopupModalProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  displayHeader: string;
  displayText1: string;
  displayText2?: string;
  textField?: JSX.Element;
  handleAction: () => void;
};

// We assume that the handleAction will also handle the closing of the modal
// Please include the setting of the show Modal state in the handleAction function
const ConfirmationPopupModal = ({
  showModal,
  setShowModal,
  displayHeader,
  displayText1,
  textField,
  displayText2,
  handleAction,
}: ConfirmationPopupModalProps) => {
  return (
    <ModalPopup
      width={"20%"}
      visible={showModal}
      onClose={() => setShowModal(false)}
      content={
        <>
          <div className="text-center">
            <h2 className="text-xl font-bold">{displayHeader}</h2>
            <p className="p-4 text-left">{displayText1}</p>
            {textField && <div className="p-4 text-left">{textField}</div>}
            <p className="p-4 text-left">{displayText2}</p>
          </div>
          <div className="grid grid-cols-2 gap-4 bg-white">
            <div>
              <Button
                children={"Yes"}
                variant={"primaryorange"}
                onClick={handleAction}
              />
            </div>
            <div>
              <Button
                children={"No"}
                variant={"edit"}
                onClick={() => setShowModal(false)}
              />
            </div>
          </div>
        </>
      }
    />
  );
};

export default ConfirmationPopupModal;
