import { createContext, useEffect, useState } from "react";
import useAdministration from "../hooks/useAdministration";

interface RoleAccessObj {
  id: number;
  read: boolean;
  write: boolean;
  update: boolean;
  delete: boolean;
  module: {
    id: number;
    name: string;
  };
  page: {
    id: number;
    name: string;
  };
}

type contextType = {
  roleAccess?: () => void;
  roleAccessObj: RoleAccessObj[];
};

export const RoleAccessContext = createContext<contextType>({
  roleAccess: () => {},
  roleAccessObj: [],
});

export const RoleAccessProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { getRoleAccess } = useAdministration();
  const [roleAccessObj, setRoleAccessObj] = useState<RoleAccessObj[]>([]);

  const roleAccess = async () => {
    const res = await getRoleAccess();
    setRoleAccessObj(res);
    //for reference
    //module id   Module Name
    // 1	         Site Verifiers
    // 2	         Jobs
    // 3	         Performance
    // 4	         Administration

    // page id   module id   page Name
    // 1	        1	          Site Verifiers List
    // 2	        1	          Newly Registered SVs
    // 3	        2	          Job List
    // 4	        3	          Performance List
    // 5	        4	          Users Management
    // 6	        4	          Roles Management
  };

  useEffect(() => {
    roleAccess();
  }, []);

  return (
    <RoleAccessContext.Provider value={{ roleAccessObj, roleAccess }}>
      {children}
    </RoleAccessContext.Provider>
  );
};
