import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import JSZip from "jszip";

/**
 * Generates an Excel file from the provided formatted data.
 *
 * @param {string} filename: Nullable The name of the file for the generated Excel file.
 * @param {any} formattedDataForExcelFile: The data to be formatted and written to the Excel file.
 * @returns {void} - the function generates and saves the Excel file directly.
 */
const ExcelFileGeneratorUtil = ({
  filename = `questionaire.xlsx`,
  formattedDataForExcelFile,
}: {
  filename?: string;
  formattedDataForExcelFile: any;
}) => {
  const worksheet = XLSX.utils.json_to_sheet(formattedDataForExcelFile);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, filename);
};

export const ExcelFileGeneratorUtilForZip = ({
  zip,
  filename = `questionaire.xlsx`,
  formattedDataForExcelFile,
}: {
  zip: JSZip;
  filename?: string;
  formattedDataForExcelFile: any;
}) => {
  const worksheet = XLSX.utils.json_to_sheet(formattedDataForExcelFile);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

  zip.file(filename, blob, { binary: true });
};

export default ExcelFileGeneratorUtil;
