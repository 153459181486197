import { useCallback, useReducer } from "react";
import { AxiosClientUtil } from "../utils/httpUtils/AxiosClientUtil";

const initialState = {
  isLoading: false,
  rolesList: [],
};

const reducer = (state: any, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, isLoading: action.payload };
    case "GET_ROLES_LIST":
      return { ...state, rolesList: action.payload };
  }
};

const useRoles = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const memoizedDispatch = useCallback(dispatch, [dispatch]);

  const memoizedJobActions = {
    getRolesList: useCallback(
      async (payload: any) => {
        memoizedDispatch({ type: "SET_LOADING", payload: true });
        try {
          const response = await AxiosClientUtil.get("/Role/list", {
            params: payload,
          });
          memoizedDispatch({ type: "GET_ROLES_LIST", payload: response?.data });
          return response;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    createNewRole: useCallback(
      async (data: any) => {
        memoizedDispatch({ type: "SET_LOADING", payload: true });
        try {
          const response = await AxiosClientUtil.post("/Role", data);
          return response;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    updateRole: useCallback(
      async (id: number, data: any) => {
        memoizedDispatch({ type: "SET_LOADING", payload: true });
        try {
          const response = await AxiosClientUtil.put(`/Role/${id}`, data);
          return response;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    deleteRole: useCallback(
      async (id: number) => {
        memoizedDispatch({ type: "SET_LOADING", payload: true });
        try {
          const response = await AxiosClientUtil.delete(`/Role/${id}`);
          return response;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),

    deleteRoleAccess: useCallback(
      async (id: number) => {
        memoizedDispatch({ type: "SET_LOADING", payload: true });
        try {
          const response = await AxiosClientUtil.delete(
            `/Role/access-right/${id}`
          );
          return response;
        } finally {
          memoizedDispatch({ type: "SET_LOADING", payload: false });
        }
      },
      [memoizedDispatch]
    ),
  };

  return { state, dispatch, ...memoizedJobActions };
};

export default useRoles;
